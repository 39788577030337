/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { CSVLink } from 'react-csv';
// import makeAnimated from 'react-select/lib/animated';
import Axios from "axios";
// Styles
import './Withdrawal.scss';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Utils
import {
  stableSort,
  getSorting, getCurryFormattedString, roundingMoney,
} from '../../../../utils/functions';

import Icon from '../../../../global/images/icons/Icon';

import {
  Button, Dialog,
  DialogActions,
  DialogContent, DialogTitle, styled,
  Typography
} from '@material-ui/core';
//import {simpleNotification} from "../../../../utils/notifications";

const paypalStatusKey = [
  { label: 'approve', value: 0 ,color:'white'},
  { label: 'accept', value: 1 ,color:'green'},
  { label: 'reject', value: 2 ,color:'red'},
];

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'500px',
    padding:'10px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px'
  },
  '& .MuiTypography-h6': {
    textTransform:'none'
  },
}));

class Withdrawal extends React.Component {
  constructor(props) {
    super(props);
    const endDate = new Date();
    const startDate = props.admin.rollOutsAll.startDate?new Date(props.admin.rollOutsAll.startDate):new Date();
    if(!props.admin.rollOutsAll.startDate) {
      startDate.setDate(endDate.getDate() - 7)
    }

    this.props = props;
    this.state = {
      orderBy: false,
      order: 'desc',
      numPage: 1,
      questTab: 2,
      filtersModal: false,
      daysFilter: 1,
      questsOptions: [],
      paypalLoaded: false,
      filters: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: '',
        startDate:startDate,
        endDate:endDate,
        approveStatus:"0,",
        irs:1,
      },

      filtersClear: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: '',
        startDate:startDate,
        endDate:endDate,
        approveStatus:"0,",
      },
      search:"",
      dateStart:startDate,
      dateEnd:endDate,
      filtersBackup: {},
      sorting: false,
      approveOrderId:"",
      approveType:0,
      withdrawOrder:{
        orderId:'',
        orderNo:'',
        userName:'',
        paymentEmail:'',
        balance:0,
      },
      dialogConfirmOpen:false,
      dialogRefuseOpen:false,
      dialogResultOpen:false,
      payResult:false,
    };
  }

  handleStartDateChange = (date) =>{
    //const newDateStart = event.target.value
    //console.log(newDateStart,typeof newDateStart);
    if(date){
     // const date = new Date(newDateStart);
     // date.setHours(0,0,0,0);
      //console.log(date,typeof date);
      this.setState(prevState =>({
        filters:{
          ...prevState.filters,
          startDate:date
        },
        numPage:1
      }),()=>{this.doSearch()});
    }

  }

  handleEndDateChange = (event) =>{
    const newDateEnd = event.target.value
    if(newDateEnd){
      let date = new Date(newDateEnd);
      //date.setHours(23,59,59,999);
      //console.log(date,typeof date);
      this.setState(prevState =>({
        filters:{
          ...prevState.filters,
          endDate:date
        },
        numPage:1
      }),()=>{this.doSearch()});
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.admin.rollOuts.isLoading) {
      this.doSearch();
     // this.props.getRollOutRecord(this.state.numPage,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
      this.setState(prevState =>({
        filters:{
          ...prevState.filters,
          irs:0
        }
      }));
    }
    if (!this.props.admin.rollOutsAll.isLoading) {
      //this.props.getRollOutAll(0,',', '{"tip":true,"approveStatus":0}', false, token,0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { orderId:approveOrderId } = this.props.admin.approve.data;
    //const { data:approve,isLoading} = this.props.admin.orderLink;
    //const {Pagination:tipPagination } = this.props.admin.rollOutsAll;
    //console.log(approveOrderId,'---',this.state.approveOrderId);
    if (this.state.filters !== prevState.filters||this.state.search!==prevState.search||this.state.sorting!==prevState.sorting) {
      this.setState({
        numPage: 1,
      });
     // this.props.getRollOutRecord(1,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
     // this.props.getRollOutAll(0,',', '{"tip":true,"approveStatus":0}', false, token,0);
    }else if(approveOrderId&&approveOrderId!==this.state.approveOrderId){
      this.doSearch();
      //this.props.getRollOutRecord(this.state.numPage,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
        //this.props.getRollOutAll(0,',', '{"tip":true,"approveStatus":0}', false, token,0);
      this.setState({
        approveOrderId: approveOrderId,
      });
    }
    //this.props.tip(tipPagination?tipPagination.totalUsers:0);
    if ((prevProps.admin.orderLink.isLoading === true && this.props.admin.orderLink.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.orderLink.errors.hasError) {
      }else{
      }
      this.handleResultOpen();
    }
    if ((prevProps.admin.approve.isLoading === true && this.props.admin.approve.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.approve.errors.hasError) {
      }else{
      }
      this.handleResultOpen();
    }
    if ((prevProps.admin.rollOuts.isLoading === true && this.props.admin.rollOuts.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.rollOuts.errors.hasError) {
      }else{
        if(this.props.admin.rollOuts.startDate){
          this.setState(prevState =>({
            filters:{
              ...prevState.filters,
              startDate:new Date(this.props.admin.rollOuts.startDate),
              //endDate:new Date(this.props.admin.rollOuts.endDate),
            }
          }));
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //const { token } = this.props.auth;

    if (this.props.admin.quests.isLoading === true && nextProps.admin.quests.isLoading === false) {
      const { data: quests } = nextProps.admin.quests;

      if (quests) {
        const questsOptions = [];
        for (let i = 0; i < quests.length; i++) {
          questsOptions.push({ value: quests[i].id, label: quests[i].title });
        }

        this.setState({
          //questsOptions,
        });
      }
    }

    // If paypal just refreshed, get payments again
    if (this.props.admin.rollOuts.isLoading === true && nextProps.admin.rollOuts.isLoading === false) {
      this.setState({
        //paypalLoaded: true,
      });
      //this.props.getAPaymentList(this.state.numPage, JSON.stringify(this.state.filters), this.state.sorting, token);
    }
  }

  doSearch=()=>{
    const { token } = this.props.auth;
    this.props.getRollOutRecord(this.state.numPage,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
  }

  paginationRight = () => {
    const { pagination } = this.props.admin.rollOuts;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }
    if (!this.props.admin.rollOuts.isLoading) {
      //this.props.getRollOutRecord(this.state.numPage + 1,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    },()=>{this.doSearch()});
  }

  paginationLeft = () => {
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.rollOuts.isLoading) {
      //this.props.getRollOutRecord(this.state.numPage - 1,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    },()=>{this.doSearch()});
  }

  handleSearchValueChange(event) {

    this.setState({
      //filters: {
        //...this.state.filters,
       // searchValue: event.target.value,
      //},
      search:event.target.value,
    });
  }

  openFiltersModal = () => {
    this.setState({
      filtersModal: true,
      filtersBackup: { ...this.state.filters },
    });
  }

  closeFiltersModal = () => {
    this.setState({
      filtersModal: false,
      filters: { ...this.state.filtersBackup },
    });
  }

  clearFiltersModal = () => {
    this.setState({
      filters: { ...this.state.filtersClear },
    });
  }

  applyFilters = () => {
    //const { token } = this.props.auth;

    this.setState({
      filtersModal: false,
    });

   // this.props.getAPaymentList(1, JSON.stringify(this.state.filters), this.state.sorting, token);
  }

  changeFilterSearch(name) {
    switch (name) {
      case 'searchAll':
        this.setState({
          filters: {
            ...this.state.filters,
            searchAll: true,
            searchSubject: false,
            searchNoiz: false,
            searchNoizEmail: false,
          },
        });
        break;
      case 'searchSubject':
        this.setState({
          filters: {
            ...this.state.filters,
            searchSubject: !this.state.filters.searchSubject,
            searchAll: false,
          },
        });
        break;
      case 'searchNoizEmail':
        this.setState({
          filters: {
            ...this.state.filters,
            searchNoizEmail: !this.state.filters.searchNoizEmail,
            searchAll: false,
          },
        });
        break;
      case 'searchNoiz':
        this.setState({
          filters: {
            ...this.state.filters,
            searchNoiz: !this.state.filters.searchNoiz,
            searchAll: false,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  // changePayoutFilter(name) {
  //   switch (name) {
  //     case 'paymentStatusAll':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusAll: !this.state.filters.paymentStatusAll,
  //           paymentStatusSent: false,
  //           paymentStatusFailed: false,
  //           paymentStatusNotSent: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusSent':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusSent: !this.state.filters.paymentStatusSent,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusFailed':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusFailed: !this.state.filters.paymentStatusFailed,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusNotSent':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusNotSent: !this.state.filters.paymentStatusNotSent,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     default:
  //       this.setState({});
  //   }
  // }

  changePayPalFilter(name) {
    switch (name) {
      case 'paypalStatusAll':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusAll: !this.state.filters.paypalStatusAll,
            paypalStatusSuccess: false,
            paypalStatusDenied: false,
            paypalStatusPending: false,
            paypalStatusRefunded: false,
            paypalStatusPartiallyRefunded: false,
          },
        });
        break;
      case 'paypalStatusSuccess':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusSuccess: !this.state.filters.paypalStatusSuccess,
            paypalStatusAll: false,
          },
        });
        break;
      case 'paypalStatusDenied':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusDenied: !this.state.filters.paypalStatusDenied,
            paypalStatusAll: false,
          },
        });
        break;
      case 'paypalStatusPending':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusPending: !this.state.filters.paypalStatusPending,
            paypalStatusAll: false,
          },
        });
        break;
      case 'paypalStatusRefunded':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusRefunded: !this.state.filters.paypalStatusRefunded,
            paypalStatusAll: false,
          },
        });
        break;
      case 'paypalStatusPartiallyRefunded':
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusPartiallyRefunded: !this.state.filters.paypalStatusPartiallyRefunded,
            paypalStatusAll: false,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  setPaymentFromDate = (event) => {
    const { target: { value } } = event;
    if (value) {
      const paymentFromDate = new Date(value).toISOString().slice(0, 10);
      this.setState({
        filters: {
          ...this.state.filters,
          paymentFromDate,
        },
      });
    } else if (value === '') {
      this.setState({
        filters: {
          ...this.state.filters,
          paymentFromDate: null,
        },
      });
    }
  }

  setPaymentToDate = (event) => {
    const { target: { value } } = event;
    if (value) {
      const paymentToDate = new Date(value).toISOString().slice(0, 10);
      this.setState({
        filters: {
          ...this.state.filters,
          paymentToDate,
        },
      });
    } else if (value === '') {
      this.setState({
        filters: {
          ...this.state.filters,
          paymentToDate: null,
        },
      });
    }
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
      sorting:{
        order: desc ? 'asc' : 'desc',
        orderBy:property?property:false,
      },
      numPage:1,
    },()=>{this.doSearch()});
  }

  handleQuestFilterChange(selectedOptions) {
    this.setState({
      filters: {
        ...this.state.filters,
        quests: selectedOptions,
      },
    });
  }

  renderFiltersModal() {
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeFiltersModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Filters">
            <div className="Filters__title">
              Select the filters you want to apply
            </div>

            <div className="Filters__title left">
              Report for a specific user:
            </div>

            <div className="Filters__section">
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div className="Filters__field__label" style={{ marginRight: '10px' }}>
                  Search by:
                </div>
                <div className="Filters__fieldGroup" style={{ marginTop: '0px', marginBottom: '0px' }}>
                  <button
                    onClick={this.changeFilterSearch.bind(this, 'searchAll', false)}
                    className={classNames('', { active: this.state.filters.searchAll })}
                  >
                    All
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, 'searchSubject', false)}
                    className={classNames('', { active: this.state.filters.searchSubject })}
                  >
                    Subject
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, 'searchNoizEmail')}
                    className={classNames('', { active: this.state.filters.searchNoizEmail })}
                  >
                    Noiz Email
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, 'searchNoiz')}
                    className={classNames('', { active: this.state.filters.searchNoiz })}
                  >
                    Noiz Username
                  </button>
                </div>
              </div>
              <div className="Filters__fieldGroup" style={{ marginTop: '0px' }}>
                <input
                  type="text"
                  value={this.state.filters.searchValue}
                  onChange={this.handleSearchValueChange.bind(this)}
                  style={{ display: 'flex' }}
                />
              </div>
            </div>

            <div className="Filters__title left">
              Filter options:
            </div>


            <div className="Filters__section">
              {/* <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div className="Filters__field__label" style={{ marginRight: '10px' }}>
                  Payout Status:
                </div>
                <div className="Filters__fieldGroup" style={{ marginTop: '0px', marginBottom: '0px' }}>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusAll')}
                    className={classNames('', { active: this.state.filters.paymentStatusAll })}
                  >
                    All
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusSent')}
                    className={classNames('', { active: this.state.filters.paymentStatusSent })}
                  >
                    Sent
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusFailed')}
                    className={classNames('', { active: this.state.filters.paymentStatusFailed })}
                  >
                    Failed
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusNotSent')}
                    className={classNames('', { active: this.state.filters.paymentStatusNotSent })}
                  >
                    Not Sent
                  </button>
                </div>
              </div> */}
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div className="Filters__field__label" style={{ marginRight: '10px', marginTop: '20px' }}>
                  PayPal Status:
                </div>
                <div className="Filters__fieldGroup" style={{ marginTop: '0px', marginBottom: '0px' }}>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusAll')}
                    className={classNames('', { active: this.state.filters.paypalStatusAll })}
                  >
                    All
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusSuccess')}
                    className={classNames('', { active: this.state.filters.paypalStatusSuccess })}
                  >
                    Success
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusDenied')}
                    className={classNames('', { active: this.state.filters.paypalStatusDenied })}
                  >
                    Denied
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusPending')}
                    className={classNames('', { active: this.state.filters.paypalStatusPending })}
                  >
                    Pending
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusRefunded')}
                    className={classNames('', { active: this.state.filters.paypalStatusRefunded })}
                  >
                    Refunded
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(this, 'paypalStatusPartiallyRefunded')}
                    className={classNames('', { active: this.state.filters.paypalStatusPartiallyRefunded })}
                  >
                    Partially Refunded
                  </button>
                </div>
              </div>
            </div>

            <div className="Filters__section" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
              <div className="Filters__field__label" style={{ marginRight: '10px', marginTop: '20px', float: 'left' }}>
                Transaction Date:
              </div>
              <div className="date__section">
                From Date
                <input
                  type="date"
                  value={this.state.filters.paymentFromDate}
                  onChange={this.setPaymentFromDate.bind(this)}
                  className="date__picker"
                />
              </div>
              <span style={{ margin: '0px 10px' }}> - </span>
              <div className="date__section">
                To Date
                <input
                  type="date"
                  value={this.state.filters.paymentToDate}
                  onChange={this.setPaymentToDate.bind(this)}
                  className="date__picker"
                />
              </div>
            </div>
            <div className="Filters__section" style={{ marginTop: '20px' }}>
              {/* }  <div className="Filters__fieldGroup">
                <div className="Filters__field__label">
                  Quests:
                </div>
                <Select
                  value={this.state.filters.quests}
                  closeMenuOnSelect={false}
                  components={makeAnimated()}
                  isMulti
                  onChange={(...args) => this.handleQuestFilterChange(...args)}
                  options={this.state.questsOptions}
                  styles={colourStyles}
                />
              </div> */}
            </div>

            <div className="Filters__close" style={{ paddingTop: '20px' }}>
              <button
                onClick={this.closeFiltersModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.clearFiltersModal.bind(this)}
              >
                <i className="fa fa-eraser" /> Clear
              </button>
              <button
                onClick={this.applyFilters.bind(this)}
              >
                <i className="fa fa-check" /> Apply
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderFiltersString = (forFileName) => {
    const { filters } = this.state;
    const filterArray = [];

    if (filters.searchAll && filters.searchValue) {
      filterArray.push(`Subject: ${filters.searchValue}`);
      filterArray.push(`Noiz Email: ${filters.searchValue}`);
      filterArray.push(`Noiz Username: ${filters.searchValue}`);
    } else if (filters.searchsearchSubject) {
      filterArray.push(`Subject: ${filters.searchValue}`);
    } else if (filters.searchNoizEmail) {
      filterArray.push(`Noiz Email: ${filters.searchValue}`);
    } else if (filters.searchNoiz) {
      filterArray.push(`Noiz Username: ${filters.searchValue}`);
    }

    // if (filters.paymentStatusAll) {
    //   filterArray.push('Payout Status: All');
    // } else {
    //   let paymentFilters = [];
    //   if (filters.paymentStatusSent) {
    //     paymentFilters.push('Sent');
    //   }
    //   if (filters.paymentStatusFailed) {
    //     paymentFilters.push('Failed');
    //   }
    //   if (filters.paymentStatusNotSent) {
    //     paymentFilters.push('Not Sent');
    //   }
    //   if (paymentFilters.length) {
    //     filterArray.push('Payment Status: ' + paymentFilters.join(", "));
    //   }
    // }

    if (filters.paypalStatusAll) {
      //filterArray.push('Paypal Status: All');
    } else {
      const paypalFilters = [];
      if (filters.paypalStatusPending) {
        paypalFilters.push('Pending');
      }
      if (filters.paypalStatusSuccess) {
        paypalFilters.push('Success');
      }
      if (filters.paypalStatusDenied) {
        paypalFilters.push('Denied');
      }
      if (filters.paypalStatusRefunded) {
        paypalFilters.push('Refunded');
      }
      if (filters.paypalStatusPartiallyRefunded) {
        paypalFilters.push('Partially Refunded');
      }

      if (paypalFilters.length) {
        filterArray.push(`Paypal Status: ${paypalFilters.join(', ')}`);
      }
    }

    // if (filters.quests) {
    //   filterArray.push('Quests: ' + filters.quests.map((quest) => quest.label).join(", "));
    // }

    if (filters.startDate && filters.endDate) {
      filterArray.push(`Transaction Date: ${moment(filters.startDate).format('MMMM DD, YYYY')} - ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    } else if (filters.startDate) {
      filterArray.push(`Transaction Date: After ${moment(filters.startDate).format('MMMM DD, YYYY')}`);
    } else if (filters.endDate) {
      filterArray.push(`Transaction Date: Before ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    }

    if (filterArray.length === 0) {
      return 'None';
    }

    // Determine delimiter
    if (forFileName) {
      const filterStrings = filterArray.map(filter => filter.replace(/[^a-z0-9+]+/gi, '-'));
      return filterStrings.join('_');
    }
    return filterArray.join(', ');
  }


  // Sort & define payment rows; return elements to display
  getRollOutsRows = (payments) => {
    if (payments.length) {
      const sortedPayments = stableSort(payments, getSorting(this.state.order, this.state.orderBy));
      return sortedPayments.map(payment => (
        <TableRow hover tabIndex={-1} key={payment.id}>
          <TableCell align="center">{payment.orderTime ? moment(payment.orderTime).format('MMMM DD, YYYY') : '-'}</TableCell>
          <TableCell align="center">{payment.orderNo ? payment.orderNo : '-'}</TableCell>
          <TableCell align="center">{payment.userName ? payment.userName : '-'}</TableCell>
          <TableCell align="center">{payment.paymentEmail ? payment.paymentEmail : '-'}</TableCell>
          <TableCell align="center">{'$'+getCurryFormattedString(payment.balance)}</TableCell>
          <TableCell align="center">
            {payment.s_status===0&&<><button style={{'backgroundColor':'green','width':'60px'}} onClick={this.handleConfirmOpen.bind(this,payment.orderId,payment.orderNo,payment.userName,payment.paymentEmail,payment.balance)}>accept</button><button style={{'backgroundColor':'red','margin-left':'15px','width':'60px'}}  onClick={this.handleRefuseOpen.bind(this,payment.orderId,payment.orderNo,payment.userName,payment.paymentEmail,payment.balance)}>reject</button></>}
            {payment.s_status===1&&'-'}
          </TableCell>
          <TableCell align="center">{'$'+getCurryFormattedString(payment.accountBalance)}</TableCell>
        </TableRow>
      ));
    }
  }

  handleSearchChange(event) {
    //const { target: { value } } = event;
    this.setState({
      search: event.target.value,
    });
  }

  handleSearch = (event) =>{
    const { search } = this.state
    console.log("method search called!,search string is:",search)
    this.setState({
      numPage: 1,
    },()=>{this.doSearch()});
  }

  // Define table headers; return array of headers
  getTableHeaders = () => {
    const tableHeaders = [
      {
        id: 'Date', numeric: false, disablePadding: true, label: 'Date',
      },
      {
        id: 'OrderNo', numeric: false, disablePadding: true, label: 'OrderNo',
      },
      {
        id: 'userName', numeric: false, disablePadding: true, label: 'UserName',
      },
      {
        id: 'paymentEmail', numeric: false, disablePadding: true, label: 'PayPal Account',
      },
      {
        id: 'balance', numeric: false, disablePadding: true, label: 'Total',
      },
      {
        id: 'amount', numeric: false, disablePadding: true, label: 'Approve',
      },
      {
        id: 'accountBalance', numeric: true, disablePadding: true, label: 'Account balance',
      },

    ];
    return tableHeaders;
  }

  // Define data for csv download; return object with headers and data
  getCSV = (payments) => {
    const data = [];

    for (let i = 0; i < payments.length; i += 1) {
      const payment = payments[i];

      // Standard CSV rows
      const dataRow = {
        date: payment.orderTime ? moment(payment.orderTime).format('MMMM DD, YYYY') : '',
        orderNo: 'withdrawal'+payment.orderId,
        userName: payment.userName,
        userEmail: payment.userEmail,
        paymentEmail: payment.paymentEmail,
        total: typeof payment.balance === 'number'? '$'+getCurryFormattedString(payment.balance): '-',
        status: payment.s_status>=0? paypalStatusKey.find(s => s.value === payment.s_status).label : '-',
        accountBalance: typeof payment.accountBalance === 'number'? '$'+getCurryFormattedString(payment.accountBalance): '-',
      };

      data.push(dataRow);
    }

    // Standard CSV headers
    const headers = [
      { label: 'Date', key: 'date' },
      { label: 'OrderNo', key: 'orderNo' },
      { label: 'UserName', key: 'userName' },
      { label: 'UserEmail', key: 'userEmail' },
      { label: 'PaymentEmail', key: 'paymentEmail' },
      { label: 'Total', key: 'total' },
      { label: 'Approve', key: 'status' },
      { label: 'Account Balance', key: 'accountBalance' },
    ];

    return { headers, data };
  }

  // Call api to pull down paypal payments.
  refreshRollOuts() {
    const { token } = this.props.auth;
    this.props.getPaypalPayments(token);
    this.setState({
      //paypalLoading: true,
    });
  }

  openDownloadModal1 = () => {
    const { token, user } = this.props.auth;
    //const isAdmin = user.role === 'admin';
    //const isHelper = user.role === 'helper';
    const isFinance = user.role === 'finance';
    //console.log('isFinance',isFinance);
    if (isFinance) {
      this.props.getRollOutCsv(0,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), this.state.sorting, token,0);
      this.setState({
        downloadModal: true,
      });
    }
  }

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  }


  renderDownloadModal() {
    const { isLoading, data: payments } = this.props.admin.rollOutsCsv;
    const csv = this.getCSV(payments);
//console.log(isLoading);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            {
              (isLoading) &&
              <div className="Modal__download--spinner">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            }
            {
              (!isLoading) &&
              <div className="Modal__download--content">
                <div className="Modal__download--header">
                  Download
                </div>
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink data={csv.data} headers={csv.headers} filename={`Withdrawal-${this.renderFiltersString(true)}.csv`}>
                      <i className="fa fa-download" />&nbsp;{`Search Results (${payments.length})`}
                    </CSVLink>
                  </div>
                </div>
                <div className="Modal__download--footer">
                  <button
                    onClick={this.closeDownloadModal.bind(this)}
                    className="Modal__download--cta"
                  >
                    <i className="fa fa-times" />&nbsp;Close
                  </button>
                </div>
              </div>
            }
          </section>
        </section>
      </section>
    );
  }

  onDrop = (file) => {

    if (file?.target?.files[0]) {
      /* this.setState({
        uploading: req.id,
      }); */

      this.uploadFile(file?.target?.files[0]);
    }
  }

  uploadFile = (file) => {
    const { token } = this.props.auth;

    const formData = new FormData();
    formData.append("file", file);

    Axios({
      method: 'POST',
      url: '/api/v1/admin/user/update-tickets',
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,

    }).then(res => {

      alert('success');
    })
    .catch(err => {
      console.log(err, "err");
      alert('upload failed');
    });
  };

  approveOrder = (orderId,approveType) => {
    //console.log(orderId,approveType);
    if(!orderId||!approveType){
      return;
    }
    const { token } = this.props.auth;

    if (!this.props.admin.approve.isLoading) {
      this.props.approveRollOut(orderId,approveType, token);
    }
  }

  handleConfirmClose = () => {
    this.setState({ dialogConfirmOpen: false});
  };

  handleConfirmOpen = (orderId,orderNo,userName,paymnentEmail,balance) => {
    this.setState({ dialogConfirmOpen: true,withdrawOrder:{orderId:orderId,orderNo:orderNo,userName:userName,paymentEmail:paymnentEmail,balance:balance}});
  };

  handleRefuseClose = () => {
    this.setState({ dialogRefuseOpen: false});
  };

  handleRefuseOpen = (orderId,orderNo,userName,paymnentEmail,balance) => {
    this.setState({ dialogRefuseOpen: true,withdrawOrder:{orderId:orderId,orderNo:orderNo,userName:userName,paymentEmail:paymnentEmail,balance:balance}});
  };

  handleResultClose = () => {
    this.setState({ dialogResultOpen: false});
    //this.props.getUserWallet();
  };

  handleResultOpen = () => {
    this.setState({ dialogResultOpen: true});
  };

  submitOrder=()=>{
    const { token } = this.props.auth;
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    this.props.showRollOutPay(this.state.withdrawOrder.orderId,token);
    this.setState({ approveType: 1});
    this.handleConfirmClose();
    //this.handleResultOpen();
  }

  refuseOrder=()=>{
    const { token } = this.props.auth;
    this.props.approveRollOut(this.state.withdrawOrder.orderId,2,token);
    this.setState({ approveType: 2});
    this.handleRefuseClose();
    //this.handleResultOpen();
  }

  payOrder=()=>{
    const { approvalLink,isLoading} = this.props.admin.orderLink.data;
    //console.log(approvalLink);
    if(!isLoading){
      if(approvalLink){
        window.open(approvalLink, "_blank");
      }
    }
    this.handleResultClose();
  }

  render() {
    const { data: payments, isLoading, pagination={totalUsers:0,totalPages:0,pageNumber:0}} = this.props.admin.rollOuts||{};
    //const {isTipLoading, tipPagination } = this.props.admin.rollOutsAll;
    //const { isLoading: paypalLoading, errors: paypalError } = this.props.admin.rollOuts;
    const { search,approveType} = this.state;
    const {startDate:dateStart,endDate:dateEnd} = this.state.filters;
    const { role } = this.props.auth.user;
    //const isAdmin = role === 'admin';
    //const isHelper = role === 'helper';
    const isFinance = role === 'finance';
    //const { orderId:approveOrderId } = this.props.admin.approve.data;
    const { isLoading:errorLoading,hasError} = this.props.admin.orderLink.errors;
    const { isLoading:approveLoading,hasError:hasApproveError} = this.props.admin.approve.errors;
    //const { approvalLink} = this.props.admin.orderLink.data;
    //console.log(this.props.admin.approve);
    const { withdrawOrder,dialogConfirmOpen,dialogRefuseOpen,dialogResultOpen } = this.state;
    //console.log(this.props.admin.approve.errors);
    //console.log(dateStart,typeof  dateStart);
    //console.log(dateEnd,typeof  dateEnd);
    //let hasError=false;
    //let haRes=false;

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };
    //this.props.tip(tipPagination?tipPagination.totalUsers:0);
    return (
      <section className="APaymentList">
        <section className="APaymentList__wrapper">
          {isFinance && (<h2 className="APaymentList__title"> RECORDS:  <span>{ pagination?pagination.totalUsers:0 } (Page {((pagination?pagination.totalPages:0)>=1)?pagination.pageNumber:0} of {pagination.totalPages})</span></h2>)}
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">

                <div className="Search_Input">
                  <Icon color='#bfbfbf' name='search' />
                  <input
                    type="text"
                    id="search-bar-input"
                    label="SearchBarInput"
                    placeHolder="Please enter UserName/OrderNo,Multiple lines can be separated by commas"
                    value={search}
                    onChange={e => this.handleSearchChange(e)}
                    onKeyDown={(e) => e.key === 'Enter' && this.handleSearch()}
                  />
                </div>
                <Typography className="date_input_head_style">Application time</Typography>
                {/* <label> */}
                  <input type='date' placeholder="Select Start Date" className='date_input_style' value={dateStart?.toISOString().split('T')[0]} onChange={(e) => this.handleStartDateChange(new Date(e.target.value))}/>
                {/* </label> */}

                <input type='date' placeholder="Select End Date" className='date_input_style' value={dateEnd?.toISOString().split('T')[0]} onChange={this.handleEndDateChange} min={dateStart}/>
                { ((pagination?pagination.totalUsers:0)>0) && (
                <button
                  style={{marginLeft:'10px'}}
                  onClick={this.openDownloadModal1.bind(this)}>
                  <i className="fa fa-download" />
                </button>
                )}
                {/*<button style={{marginLeft:'10px'}} onClick={this.refreshRollOuts.bind(this)}>
                  Refresh Payments

                </button>*/}
              </div>
              <div className="ToggleTable__header__right">
                {/* <button
                  className={classNames('')}
                  onClick={this.openFiltersModal.bind(this)}
                >
                  Filters
                </button> */}
                {(pagination?pagination.totalUsers:0)>0&&(<button className="regular" onClick={this.paginationLeft.bind(this)}>
                  <i className="fa fa-arrow-left" />
                </button>)}
                {(pagination?pagination.totalUsers:0)>0&&(<button className="regular" onClick={this.paginationRight.bind(this)}>
                  <i className="fa fa-arrow-right" />
                </button>)}
              </div>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                {/*<div className="row">
                  <div
                    className="table-cell headline"
                  >
                    Filters: {this.renderFiltersString(false)}
                  </div>
                </div>*/}
                <Table>
                  <TableHead>
                    <TableRow>
                      {this.getTableHeaders().map(headCell => (
                        <TableHeaderCell
                          key={headCell.id}
                          align="center"
                          padding={headCell.disablePadding ? 'none' : 'default'}
                          sortDirection="asc"
                        >
                          <TableSortLabel
                            active={this.state.orderBy === headCell.id}
                            direction={this.state.order}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                            style={{ marginLeft: '30px' }}
                          >
                            {headCell.label}
                            {this.state.orderBy === headCell.id ? (
                              <span style={{ display: 'none' }}>
                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                          ) : null}
                          </TableSortLabel>
                        </TableHeaderCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { (!isLoading) &&
                    this.getRollOutsRows(payments)
                    }
                  </TableBody>
                </Table>
                <div className="NoPayments">
                  { (isLoading) &&
                    <div className="PaymentList__loading">
                      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                  }
                  { (!isLoading && !payments.length) &&
                    <span> No RollOuts Found </span>
                  }
                </div>
              </section>
            </section>
            {(pagination?pagination.totalUsers:0)>0&&(<section className="ToggleTable__footer">
              <section className="ToggleTable__footer__left">
                <button onClick={this.paginationLeft.bind(this)}>
                  <i className="fa fa-caret-left" />
                </button>
              </section>
              <section className="ToggleTable__footer__right">
                <button onClick={this.paginationRight.bind(this)}>
                  <i className="fa fa-caret-right" />
                </button>
              </section>
            </section>)}
          </section>
        </section>
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={!!dialogConfirmOpen}
        >
          <DialogContent dividers>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>UserName</Typography>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>{withdrawOrder.userName}</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>Amount paid</Typography>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>${getCurryFormattedString(withdrawOrder.balance ? roundingMoney(withdrawOrder.balance, 2) : '0.00')}</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>Method Of Payment</Typography>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>PayPal</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>PayPal Account</Typography>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>{withdrawOrder.paymentEmail}</Typography>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleConfirmClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.submitOrder.bind(this)}>
              Make Payment
            </Button>
          </DialogActions>
      </BootstrapDialog>

        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={!!dialogRefuseOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
            Have you confirmed the rejection of <p style={{color:'#E7F328'}}>{withdrawOrder.userName}'s ${getCurryFormattedString(withdrawOrder.balance ? roundingMoney(withdrawOrder.balance, 2) : '0.00')}</p> withdrawal  application?
          </DialogTitle>
          <DialogContent dividers>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>

              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleRefuseClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.refuseOrder.bind(this)}>
              Confirm
            </Button>
          </DialogActions>
        </BootstrapDialog>

        {(!errorLoading||!approveLoading)&&((hasApproveError&&approveType===2)||approveType===1)&&<BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={!!dialogResultOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:(hasError||hasApproveError)?'#ff0000':''}}>
            {approveType===1&&((hasError)? 'Failure to payment' : 'Do you want to redirect to the payment page to complete the payment?')}
            {approveType===2&&((hasApproveError)? 'Failure to refuse' : '')}
          </DialogTitle>
          <DialogContent dividers>
            <div style={{display:'flex',justifyContent:'center'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>
                {approveType===1&&((hasError)?'': 'Withdrawal progress can be viewed in the transaction history')}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleResultClose.bind(this)}>
              Close
            </Button>
            {(!hasError)&&(<Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.payOrder.bind(this)}>
              Confirm
            </Button>)}
          </DialogActions>
        </BootstrapDialog>}
        {/*{ (this.state.filtersModal) && this.renderFiltersModal() }*/}
        { (this.state.downloadModal) && this.renderDownloadModal() }
      </section>
    );
  }
}

Withdrawal.propTypes = {

};

export default Withdrawal;
