import React, { useEffect } from 'react';

import { getPaymentAmountNew, isAutoStreamQuest, isSecondaryCreationQuest } from '../../utils/functions';
import { addRedirectFromLogin } from '../../utils/util';
// import { getStreamerBankAccounts } from '../../utils/apiv2';
import './QuestSelector.scss';

const defaultButtonBehavior = () => console.log('Default Button Behavior');

const toConnectAccounts = () => window.location.assign('/connect-accounts');
const toMyAccount = () => window.location.assign('/my-account');
const toMyQuest = questId => window.location.assign(`/my-quests/${questId}`);

const StatusButton = (props) => {
  const {
    onApply = null,
    buttonStylings = {},
    quest = {},
    user = {},
    userQuest = {},
    userQuestIsLoading = false,
    location = {},
    router = {},
  } = props;

  // const [paymentMethods, setPaymentMethods] = useState([]);
  const paymentMethods = [];

  // const getPaymentMethods = async () => {
  //   const {
  //     data: { data: accountInfo },
  //   } = (await getStreamerBankAccounts()) || { data: [] };
  //   if (accountInfo) setPaymentMethods(accountInfo);
  // };

  // write a drop down input component to pick a country

  useEffect(() => {
    // getPaymentMethods();
  }, []);

  const {
    closeRegistrationDate = null,
    id: questId = '',
    memberTiers,
    openRegistrationDate = null,
    endDateTime = null,
    platforms = [],
    status = 'disabled',
    twitchAccountRequired = false,
    viewerTiers,
    splitTiers,
    rankTiers,
    miniFollowers = 0
  } = quest;

  const {
    connectedAccounts: {
      twitch: { id: twitchId, username: twitchUsername, followers: twitchFollowers },
    },
    displayName,
    username,
  } = user;

  const {
    status: userQuestStatus = null,
  } = userQuest;

  const brandQuest = !!platforms.length && platforms[0].platform === 'Brand';
  const stripeLinked = !!paymentMethods?.length && paymentMethods[0]?.details_submitted && paymentMethods[0]?.payouts_enabled;
  let buttonClass = 'SelectorButton__red disabled';
  let buttonDisabled = true;
  let buttonFunction = onApply || defaultButtonBehavior;
  let buttonText = 'Button';

  //判断是否是新的类型任务
  const autoOrSecondQuest = isAutoStreamQuest(quest) || isSecondaryCreationQuest(quest);
  if (status !== 'active') { // Check if Quest is Currently Active
    buttonText = `Quest is ${status}`;
  } else if (closeRegistrationDate && openRegistrationDate && endDateTime) { // Check if Quest Dates are there
    const today = new Date();
    const dateBegin = new Date(openRegistrationDate);
    const dateEnd = new Date(closeRegistrationDate);
    const dateQuestEnd = new Date(endDateTime);

    if (today < dateBegin || today > dateEnd) {

      if (autoOrSecondQuest) {
        if (userQuestStatus && (userQuestStatus === 'approved' || userQuestStatus === 'normal')) {
          //如果是自动审核任务，用户已经离开了任务，那么可以再次申请
          buttonFunction = () => toMyQuest(questId);
          buttonClass = 'SelectorButton__red';
          buttonDisabled = false;
          buttonText = 'See MyQuest';
        } else {
          // Check if Quest is Currently Active
          buttonClass = 'SelectorButton__lightGrey disabled';
          buttonText = today <= dateBegin ? 'Quest Has Not Started' : today < dateQuestEnd ? 'Registration Has Ended' : 'Quest Has Ended';
        }
      } else {
        // Check if Quest is Currently Active
        buttonClass = 'SelectorButton__lightGrey disabled';
        buttonText = today < dateBegin ? 'Quest Has Not Started' : 'Quest Has Ended';
      }
    } else {
      buttonClass = 'SelectorButton__purple';
      buttonDisabled = false;

      if (!displayName && !username) {
        // 如果没有用户名，提示用户进行登录
        buttonText = 'Sign In';
        buttonFunction = () => addRedirectFromLogin(router, location.pathname);
      } else if (twitchAccountRequired && !twitchId && !twitchUsername) {
        //如果要求twitch账号，用户没有，则提示用户，点击进行twitch授权
        buttonText = 'Twitch Account Required';
        buttonFunction = toConnectAccounts;
      } else if (!userQuestStatus) {
        // Check if User has Applied for Quest
        const { paymentAmount } = getPaymentAmountNew(splitTiers, rankTiers, memberTiers, user, viewerTiers, userQuest);
        if ((!brandQuest || (brandQuest && stripeLinked)) && paymentAmount !== Number.MIN_SAFE_INTEGER) {
          if (autoOrSecondQuest && (twitchFollowers < miniFollowers)) {
            buttonClass = 'SelectorButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Ineligible';
          } else {
            buttonText = 'Apply Now';
            buttonFunction = onApply || defaultButtonBehavior;
          }
        } else if (brandQuest && !stripeLinked) {
          // Check if Brand Quest And No Stripe Account
          buttonClass = 'SelectorButton__cyan';
          buttonFunction = toMyAccount;
          buttonText = !!paymentMethods.length && (!paymentMethods[0].stripeAccountRaw?.details_submitted || !paymentMethods[0].stripeAccountRaw?.payouts_enabled) ? 'Check Your Stripe Details' : 'Link Stripe Account to Apply';
        } else {
          buttonClass = 'SelectorButton__red ineligible';
          buttonDisabled = true;
          buttonFunction = defaultButtonBehavior;
          buttonText = 'Ineligible';
        }
      } else if (userQuestStatus) {
        // Check Status of Application
        switch (userQuestStatus) {
          case 'approved':
          case 'normal':
            //如果被管理员在检查数据的时候拒绝了，也要显示该按钮
            if (autoOrSecondQuest && userQuest.isReject) {
              buttonClass = 'SelectorButton__red ineligible';
              buttonDisabled = true;
              buttonFunction = defaultButtonBehavior;
              buttonText = 'Ineligible';
            } else {
              buttonFunction = () => toMyQuest(questId);
              buttonText = 'See MyQuest';
            }
            break;
          case 'pending':
            buttonClass = 'SelectorButton__lightGrey disabled';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Pending';
            break;
          case 'declined':
          case 'rejected':
            buttonClass = 'SelectorButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Ineligible';
            break;
          case 'leftQuest':
            if (autoOrSecondQuest && (twitchFollowers >= miniFollowers)) {
              //如果是自动审核任务
              buttonText = 'Apply Now';
              buttonFunction = onApply || defaultButtonBehavior;
            } else {
              buttonClass = 'SelectorButton__red ineligible';
              buttonDisabled = true;
              buttonFunction = defaultButtonBehavior;
              buttonText = 'Left Quest';
            }
            break;
          case 'payFinish':
            buttonClass = 'SelectorButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Quest Has Ended';
            break;
          default:
            buttonClass = 'SelectorButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Error';
            break;
        }
      }
    }
  } else {
    buttonText = 'Error';
  }

  const spinner = <i className="fa fa-spinner fa-spin fa-3x fa-fw" />;

  return (
    <button
      className={buttonClass}
      disabled={buttonDisabled}
      onClick={buttonFunction}
      styles={buttonStylings}
    >
      {userQuestIsLoading ? spinner : buttonText}
    </button>
  );
};

export default StatusButton;
