import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import './MyQuests.scss';
import { dayjs, localizeIncorrectDate } from '../../utils/dateTime';
import {
  getNumApprovedRequirements,
  getNumInReviewRequirements,
  getQuestRequirements,
  getStreamTimeCompleted,
  isQuestOver,
  isAutoStreamQuest
} from '../../utils/functions';

// 添加按结束时间排序的函数
function sortByEndDateTime(quests) {
  return [...quests].sort((a, b) => {
    // 获取结束时间
    const endDateA = a.endDateTime ? new Date(a.endDateTime) : new Date(0);
    const endDateB = b.endDateTime ? new Date(b.endDateTime) : new Date(0);

    // 降序排列（结束时间离现在越近的排在前面）
    return endDateB - endDateA;
  });
}

function MyQuests({
  active, myQuests, myUserQuests, timedActivity, toggle,
}) {
  const questsNeedingAttention = [];
  const questsUnderReview = [];
  const questsCompleted = [];

  // 添加专门用于tiered-auto类型任务的数组
  const tieredAutoQuests = [];
  const tieredAutoCompleted = [];
  const tieredAutoNeedAttention = [];

  // 添加专门用于basic类型任务的数组
  const basicQuests = [];
  const basicCompleted = [];
  const basicNeedAttention = [];

  if (!myQuests.isLoading && !myUserQuests.isLoading && !timedActivity.isLoading) {
    for (let i = 0; i < myQuests.data.length; i += 1) {
      const quest = { ...myQuests.data[i] };
      const userQuest = myUserQuests.data.find(uq => uq.quest === quest.id);

      // 对tiered-auto类型任务的特殊处理
      if (quest.type === 'tiered-auto') {
        // 将所有tiered-auto类型的任务添加到总列表
        tieredAutoQuests.push(quest);

        // 检查任务是否已结束
        const questEnded = isQuestOver(quest);

        // 额外保存一些有用的信息到任务对象中，供UI显示
        const enrichedQuest = {
          ...quest,
        };

        // 判断任务是否"已完成"或"需要注意"
        if (questEnded) {
          // 任务已结束且满足流媒体时间要求
          tieredAutoCompleted.push(enrichedQuest);
          questsCompleted.push(enrichedQuest); // 也添加到普通完成列表
        } else if (!questEnded) {
          // 任务未结束，需要注意
          tieredAutoNeedAttention.push(enrichedQuest);
          questsNeedingAttention.push(enrichedQuest); // 也添加到普通注意列表
        }

        // 跳过常规处理
        continue;
      }

      // 对basic类型任务的特殊处理
      if (quest.type === 'basic') {
        // 将所有basic类型的任务添加到总列表
        basicQuests.push(quest);

        // 检查任务是否已结束
        const questEnded = isQuestOver(quest);

        // 获取时间信息
        const userQuestTimedActivity = timedActivity.data.find(ta => (ta.quest ? ta.quest._id === quest.id : false));
        const timePlaying = userQuestTimedActivity ? userQuestTimedActivity.timePlaying : 0;
        const streamTimeRemaining = quest.requiredStreamTimeMinutes ? quest.requiredStreamTimeMinutes - timePlaying : 0;
        const streamTimeCompleted = getStreamTimeCompleted(quest, timePlaying);

        // 额外保存一些有用的信息到任务对象中，供UI显示
        const enrichedQuest = {
          ...quest,
          timePlaying,
          streamTimeRemaining,
          streamTimeCompleted
        };

        // 判断任务是否"已完成"或"需要注意"
        if (questEnded) {
          // 任务已结束且满足流媒体时间要求
          basicCompleted.push(enrichedQuest);
          questsCompleted.push(enrichedQuest); // 也添加到普通完成列表
        } else if (!questEnded) {
          // 任务未结束，需要注意
          basicNeedAttention.push(enrichedQuest);
          questsNeedingAttention.push({
            ...enrichedQuest,
            streamTimeRemaining,
          }); // 也添加到普通注意列表
        }

        // 跳过常规处理
        continue;
      }

      if (!quest.paymentMade && !(userQuest && ['pending', 'declined', 'leftQuest'].includes(userQuest.status))) {
        const userQuestTimedActivity = timedActivity.data.find(ta => (ta.quest ? ta.quest._id === quest.id : false));

        // Figure out how many submission requirements they completed
        let requirements = getQuestRequirements(quest, true).length;
        let completedRequirements = getNumApprovedRequirements(quest, userQuest);
        const inReviewRequirements = getNumInReviewRequirements(quest, userQuest);
        const submissionsNeeded = requirements - completedRequirements - inReviewRequirements; // Do this before mutating the variables below
        const timePlaying = userQuestTimedActivity ? userQuestTimedActivity.timePlaying : 0;
        const streamTimeRemaining = quest.requiredStreamTimeMinutes ? quest.requiredStreamTimeMinutes - timePlaying : 0;
        const streamTimeCompleted = getStreamTimeCompleted(quest, timePlaying);

        // Adjust for completed time requirement
        if (quest.requiredStreamTimeMinutes) {
          requirements += 1;
          if (streamTimeCompleted) {
            completedRequirements += 1;
          }
        }

        const inactive = dayjs() > localizeIncorrectDate(quest.endDateTime).add(7, 'day');
        const completed = requirements === completedRequirements;
        if (!(inactive && !completed)) {
          if (completed) questsCompleted.push(quest);
          if ((requirements === (completedRequirements + inReviewRequirements)) && (inReviewRequirements > 0)) questsUnderReview.push(quest);
          if (requirements > (completedRequirements + inReviewRequirements)) {
            questsNeedingAttention.push({
              ...quest,
              streamTimeRemaining,
              submissionsNeeded,
            });
          }
        }
      }
    }
  }
  const [activeTab, setActiveTab] = useState("all");
  const questsTotal = questsNeedingAttention.length + questsUnderReview.length + questsCompleted.length;

  return (
    <section className="MyQuests">
      <section className="ModalMaster">
        <section className={classNames('Modal', { active })}>
          <div className="Modal__layer" role="button" aria-hidden onClick={toggle} />
          <section className="Modal__wrapper">
            <div className="Modal_title">MY QUESTS</div>
            <div className="Modal_body">
              <ul className="Modal_body__tabs">
                <li className={` ${activeTab === "all" ? "active" : ""}`} onClick={() => setActiveTab("all")}>all</li>
                <li className={` ${activeTab === "complete" ? "active" : ""}`} onClick={() => setActiveTab("complete")}>Complete</li>
                <li className={` ${activeTab === "needAttention" ? "active" : ""}`} onClick={() => setActiveTab("needAttention")}>Needs Attention</li>
              </ul>
              <div className="QuestBook active">
                {myQuests.isLoading && <div className="Loading">Loading</div>}
                {(questsTotal === 0 && !myQuests.isLoading) && (
                  <div className="NoGames sidebar">
                    {'You haven\'t joined any quests yet. '}<Link to="/quests" href="Link/quests">Join some now!</Link>
                  </div>
                )}
                {
                  (!myQuests.isLoading && activeTab === "all") && (
                    <>
                      {/* 合并所有类型的任务并按结束时间排序 */}
                      <QuestsByStatus
                        quests={sortByEndDateTime([
                          ...tieredAutoQuests,
                          ...basicQuests,
                          ...questsNeedingAttention.filter(q => q.type !== 'tiered-auto' && q.type !== 'basic'),
                          ...questsUnderReview,
                          ...questsCompleted.filter(q => q.type !== 'tiered-auto' && q.type !== 'basic')
                        ])}
                        status="All"
                        myUserQuests={myUserQuests}
                      />
                    </>
                  )
                }
                {
                  (!myQuests.isLoading && activeTab === "complete") && (
                    <>
                      {/* 合并所有已完成的任务并按结束时间排序 */}
                      <QuestsByStatus
                        quests={sortByEndDateTime([
                          ...tieredAutoCompleted,
                          ...basicCompleted,
                          ...questsCompleted.filter(q => q.type !== 'tiered-auto' && q.type !== 'basic')
                        ])}
                        status="Complete"
                        myUserQuests={myUserQuests}
                      />
                    </>
                  )
                }{
                  (!myQuests.isLoading && activeTab === "needAttention") && (
                    <>
                      {/* 合并所有需要注意的任务并按结束时间排序 */}
                      <QuestsByStatus
                        quests={sortByEndDateTime([
                          ...tieredAutoNeedAttention,
                          ...basicNeedAttention,
                          ...questsNeedingAttention.filter(q => q.type !== 'tiered-auto' && q.type !== 'basic')
                        ])}
                        status="Needs Attention"
                        myUserQuests={myUserQuests}
                      />
                    </>
                  )
                }
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}

export default MyQuests;

function QuestsByStatus({ quests, status, myUserQuests }) {
  // if (quests.length === 0) return null;
  return (
    <section className="QuestByStatus">
      {quests.map(quest => (
        <QuestItem
          key={quest.id}
          quest={quest}
          status={status}
          myUserQuests={myUserQuests}
        />
      ))}
    </section>
  );
}

function QuestItem({ quest, status, myUserQuests }) {
  const {
    endDateTime, expectedPayoutDate, game, id, startDateTime, streamTimeRemaining, submissionsNeeded, title, slug
  } = quest;
  const dateInterval
    = (startDateTime && endDateTime)
      ? `${localizeIncorrectDate(startDateTime).format('MM/DD')} - ${localizeIncorrectDate(endDateTime).format('MM/DD')}`
      : 'No date';

  // 引入判断任务类型的函数
  const isAutoQuest = isAutoStreamQuest(quest);

  // 获取当前任务的用户状态
  const userQuest = myUserQuests?.data?.find(uq => uq.quest === id);
  const userQuestStatus = userQuest?.status || '';

  // 决定跳转路径
  let redirectPath;
  if (isAutoQuest) {
    // 如果是自动流媒体任务，根据用户状态决定路径
    if (userQuestStatus === 'approved' || userQuestStatus === 'normal') {
      redirectPath = `/my-quests/${id}`;
    } else {
      redirectPath = `/quests/${slug || id}`;
    }
  } else {
    // 非自动流媒体任务，使用默认路径
    redirectPath = `/my-quests/${id}`;
  }
  return (
    <Link key={id} href={redirectPath}>
      <button className="QuestByStatus__item" key={id}>
        <figure
          className="QuestByStatus__item--cover"
          style={{ backgroundImage: `url(${game.cover})` }}
          alt="Game Logo"
        />
        <div className="QuestByStatus__item--details">
          <div className="QuestByStatus__item--details-title">
            {title.length > 60 ? `${title.substring(0, 60)}...` : title}
          </div>
          <div className="QuestByStatus__item--details-date">
            {dateInterval}
          </div>
          {(status === 'Needs Attention') && (
            <>
              {(submissionsNeeded > 0) && (
                <div className="QuestByStatus__item--details-requirements failure">
                  {`${submissionsNeeded} submissions needed`}
                </div>
              )}
              {(streamTimeRemaining > 0) && (
                <div className="QuestByStatus__item--details-requirements failure">
                  {`Stream for ${streamTimeRemaining} minutes`}
                </div>
              )}
            </>
          )}
          {(status === 'Under Review') && (
            <div className="QuestByStatus__item--details-requirements underReview">
              Under review
            </div>
          )}
          {(status === 'Complete' && expectedPayoutDate) && (
            <div className="QuestByStatus__item--details-requirements success">
              {`Payment arrives by ${localizeIncorrectDate(expectedPayoutDate).format('MM/DD')}`}
            </div>
          )}

        </div>
      </button>
    </Link>
  );
}
