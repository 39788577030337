import React from 'react';
import classNames from 'classnames';
import { find, without } from 'lodash';
import { Link } from 'react-router';
import {
  // Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import anime from 'animejs';
import Axios from 'axios';

import ContentMediaSelector from './ContentMediaSelector';
import CustomCheckbox from './CustomCheckbox';
import Loading from '../../loading/Loading';
import expandMobile from '../../../components/images/quest-details-page/expand-icon-mobile.svg';
import condenseMobile from '../../../components/images/quest-details-page/condense-icon-mobile.svg';
import expandDesktop from '../../../components/images/quest-details-page/expand-icon-desktop.svg';
import condenseDesktop from '../../../components/images/quest-details-page/condense-icon-desktop.svg';

import { simpleNotification } from '../../../utils/notifications';
import { genericUrlRegex, twitterOrXUrlRegex } from '../../../utils/regularExpresions';
import {
  QUEST_GRACE_PERIOD_DAYS,
  formatLink,
  getCompletionStatus,
  getDisplayGameTitle,
  getDefaultIcon,
  getNumApprovedRequirements,
  getNumInReviewRequirements,
  getPaymentAmountNew,
  getQuestHashtagsTitle,
  getQuestRequirements,
  getReferralBonus,
  getStreamTrackTitle,
  getTrackingHashtagsTitle,
  humanFileSize,
  isQuestOver,
  isQuestInGracePeriod,
  preloadRequirement,
  removeCommissionRounding,
  rounding,
  textTruncate,
  isStreamingQuest,
  isAutoStreamQuest,
  formatMinutesToHoursAndMinutes,
  formatValueWithUnit,
} from '../../../utils/functions';
import { localizeIncorrectDate, token12v24 } from '../../../utils/dateTime';

import './QuestDetailPage.scss';
import Icon from '../../../global/images/icons/Icon';

const MOBILE_SCREEN_WIDTH = 768;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

class QuestDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      leaveQuestModal: false,
      leaveQuestReason: null,
      leavingQuest: false,
      leftQuest: false,
      submissions: [],
      errors: [],
      errorMessages: [],
      editRequirementId: false,
      uqRequirements: [],
      quest: false,
      questOver: false,
      questInGracePeriod: false,
      collapsedRequirements: [],
      canCollapseRequirements: [],
      sticky: false,
      activeRequirementId: false,
      mobile: false,
      statusBarOpen: false,
      animatingRequirementId: false,
      // navStickyTop: false,
      navStickyBottom: false,
      tooltipOpen: false,
      gracePeriodModal: false,
      uploading: false,
      uploadingProgress: 0,
      uploadingTotal: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  // Handle change to a requirement's input
  handleChange(value, reqId) {
    const { errors, errorMessages, submissions } = this.state;
    submissions[reqId] = {
      ...submissions[reqId],
      value,
    };
    errors[reqId] = false;
    errorMessages[reqId] = false;

    this.setState({
      submissions,
      errors,
      errorMessages,
    });
  }

  handleContentTypeChange = (contentType, reqId) => {
    const { submissions } = this.state;
    submissions[reqId] = {
      ...submissions[reqId],
      contentType,
    };

    this.setState({
      submissions,
    });
  };

  isPreview = () => {
    const prev = this.props.router.getCurrentLocation().pathname.split('/')[2];
    return prev === 'preview';
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    this.props.getQuest(questId, false, token);
    this.props.getUserReferralsActivity(token);
    this.props.getMyQuestsPageData(token);
    this.setState({ questId });
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
    document.removeEventListener('scroll', this.handleScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { questId } = this.props.router.params;

    // Handle user leaving quest
    if (this.props.user.myQuests.isLeavingAQuest && !nextProps.user.myQuests.isLeavingAQuest) {
      this.setState({
        leftQuest: true,
      });
    }

    const { data: myUserQuests, isLoading: myUserQuestsIsLoading } = nextProps.user.myUserQuests;
    const { data: userQuestReferral, isLoading: userQuestIsLoading } = nextProps.config.userQuestReferral;

    // Recieving requirement data after user submitted something
    if (this.props.config.userQuestReferral.isLoading && !userQuestIsLoading) {
      this.setState({
        uqRequirements: userQuestReferral.requirements,
      });

      // Setting regular requirement data
    } else if (this.props.user.myUserQuests.isLoading && !myUserQuestsIsLoading) {
      const { collapsedRequirements, canCollapseRequirements } = this.state;
      const userQuest = find(myUserQuests, q => q.quest === questId);

      // Save user quest requirements
      if (userQuest) {
        this.setState({
          uqRequirements: userQuest.requirements,
        });
      }

      if (!collapsedRequirements.length && !canCollapseRequirements.length && userQuest && userQuest.requirements) {
        // Set default collapsed requirements (anything completed) & any collapsable
        if (!this.state.mobile) {
          const collapsedRequirements = [];
          const canCollapseRequirements = [];

          for (let i = 0; i < userQuest.requirements.length; i++) {
            const req = userQuest.requirements[i];
            if (req.status === 'completed') {
              collapsedRequirements.push(req.questReqId);
              canCollapseRequirements.push(req.questReqId);
            }
          }

          this.setState({
            collapsedRequirements,
            canCollapseRequirements,
          });
        }
      }
    }

    // Save quest data to state
    if (this.props.config.quest.isLoading && !nextProps.config.quest.isLoading) {
      const quest = nextProps.config.quest.data;
      const { activeRequirementId } = this.state;
      let newActiveRequirementId = activeRequirementId;

      if (quest.requirements) {
        const requirements = getQuestRequirements(quest, false);
        if (!activeRequirementId && requirements.length) {
          newActiveRequirementId = requirements[0].id;
        }
      }

      // Get status of quest end date & grace period
      const questInGracePeriod = isQuestInGracePeriod(quest);
      const questOver = isQuestOver(quest);

      this.setState({
        quest,
        questInGracePeriod,
        questOver,
        gracePeriodModal: !!((questInGracePeriod && questOver)),
        activeRequirementId: newActiveRequirementId,
      }, () => {
        // 当任务数据加载完成后，检查路径并重定向
        this.checkAndRedirectToRequirements();
      });
    }
  }

  // Listener function for window resizing
  updateWindowWidth() {
    const { mobile } = this.state;
    if (!mobile && window.innerWidth <= MOBILE_SCREEN_WIDTH) this.setState({ mobile: true });
    else if (mobile && window.innerWidth > MOBILE_SCREEN_WIDTH) this.setState({ mobile: false });
  }

  // Listener function for window scroll
  handleScroll() {
    const requirementContainers = document.getElementsByClassName('RequirementContainer');

    // Find the closest requirement to current scroll position
    let firstRec = requirementContainers[0];
    for (let i = requirementContainers.length - 1; i >= 0; i--) {
      const rect = requirementContainers[i].getBoundingClientRect();
      if ((rect.top) >= 0) {
        firstRec = requirementContainers[i];
      }
    }

    // Save the ID of that requirement to the state for use in sidebar nav rendering
    if (firstRec) {
      const { activeRequirementId } = this.state;
      const reqId = firstRec.id;

      if (reqId !== activeRequirementId) {
        this.setState({
          activeRequirementId: reqId,
        });
      }
    }

    // Confirm if we can see footer or not
    const { navStickyBottom } = this.state;
    const ContactFooter = document.getElementsByClassName('noiz-footer');
    const footerVisible = window.innerHeight - ContactFooter[0].getBoundingClientRect().top >= 0;

    // Make table of contents fixed when footer is in view
    if (footerVisible && !navStickyBottom) {
      this.setState({ navStickyBottom: window.scrollY });
    } else if (!footerVisible) {
      this.setState({ navStickyBottom: false });
    }

    // Handle status bar sticky on scroll
    const statusBar = document.getElementById('status-bar');
    const statusBarContainer = document.getElementById('status-bar-container');

    if (statusBar && statusBarContainer) {
      const statusBarRect = statusBar.getBoundingClientRect();
      const statusBarContainerRect = statusBarContainer.getBoundingClientRect();
      const { sticky } = this.state;

      if (statusBarRect.top <= 0 && !sticky) {
        this.setState({
          sticky: true,
        });
      } else if (window.scrollY <= (statusBarContainerRect.top + 100)) {
        this.setState({
          sticky: false,
        });
      }
    }

    // Handle side nav sticky on scroll
    const NavScrollContainer = document.getElementById('nav-container');

    if (NavScrollContainer) {
      const navScrollContainerRec = NavScrollContainer.getBoundingClientRect();

      // Stick when we hit top of scroll container
      if (navScrollContainerRec.top <= 100) {
        this.setState({
          navSticky: true,
          // navStickyTop: true, // top position for when nav is sticky
        });

        // If we pass top of container on the way back up, unstick
      } else {
        this.setState({
          navSticky: false,
          // navStickyTop: false,
        });
      }
    }
  }

  // Animate scroll to requirement
  scrollTo(reqId) {
    const requirementContainers = document.getElementsByClassName('RequirementContainer');
    const container = find(requirementContainers, req => req.id === reqId);
    const pos = container ? container.getBoundingClientRect() : false;
    if (pos) window.scrollBy(0, pos.top - 60);
  }

  // Collapse requirement, update state, and set height to auto
  postAnimation(reqId) {
    this.toggleCollapseRequirement(reqId);
    this.setState({
      animatingRequirementId: false,
    });
    document.getElementById(`box-${reqId}`).style.height = 'auto';
  }

  // Animate the background and collapse on requirement
  animateBackgroundCollapse(reqId) {
    const _this = this;
    this.setState({ animatingRequirementId: reqId });

    // Animate height, if needed
    const requirementContainer = document.getElementById(`box-${reqId}`);
    const requirementRect = requirementContainer ? requirementContainer.getBoundingClientRect() : false;

    let animateHeight = false;
    if (requirementRect && requirementRect.height > 80) animateHeight = true;

    // Swipe across background
    anime({
      targets: `#background-${reqId}`,
      width: 'calc(100% - 64px)',
      duration: 300,
      easing: 'linear',
      complete(anim) {
        // Collapse & opacity
        anime({
          targets: `#background-${reqId}`,
          opacity: 0,
          delay: 200,
          duration: 300,
          easing: 'linear',
          complete(anim) {
            if (!animateHeight) {
              _this.postAnimation(reqId);
            }
          },
        });

        if (animateHeight) {
          anime({
            targets: `#box-${reqId}`,
            delay: 200,
            duration: 300,
            height: '80px',
            easing: 'linear',
            complete(anim) {
              _this.postAnimation(reqId);
            },
          });
        }
      },
    });
  }

  // Animate opening & closing of status bar
  animateStatusBar() {
    const { statusBarOpen } = this.state;
    const _this = this;

    // Animate open
    let height = '150px';

    // Animate closed
    if (statusBarOpen) height = '0px';

    anime({
      targets: '#status-bar-content-mobile',
      height,
      duration: 300,
      easing: 'linear',
      complete(anim) {
        _this.setState({
          statusBarOpen: !statusBarOpen,
        });
      },
    });
  }

  // Show/hide leave quest modal
  leaveQuestModalToggle() {
    this.setState({
      leaveQuestModal: !this.state.leaveQuestModal,
    });
  }

  // Leave quest & show the left quest modal
  leaveQuest(questId) {
    const { token } = this.props.auth;

    this.setState({
      leavingQuest: true,
    });

    this.props.leaveQuest(questId, token, this.state.leaveQuestReason);
  }

  onDrop = (file, req, quest) => {
    if (file?.target?.files[0]) {
      this.setState({
        uploading: req.id,
      });

      this.uploadToS3(file?.target?.files[0], req, quest);
    }
  }

  uploadToS3 = (file, req, quest) => {
    const { token } = this.props.auth;
    const { id: reqId } = req;
    const { id: questId } = quest;

    const { name, type } = file;
    const ext = name.split(".").pop();
    Axios.post('/api/v1/user/request-content-upload-url', { type, format: ext, questId, reqId }, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        const { preSignedUrl } = res.data.data;

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', preSignedUrl);
        xhr.setRequestHeader('Content-Type', type);
        xhr.upload.addEventListener('progress', this.handleProgress);
        xhr.upload.addEventListener('load', e => this.handleUploadLoad(e, req));
        xhr.addEventListener('load', e => this.handleLoad(e, req));
        xhr.send(file);
      })
      .catch(err => {
        console.log(err, "err");
      });
  };

  handleProgress = event => {
    if (event.lengthComputable) {
      this.setState({
        uploading: true,
        uploadingProgress: event.loaded,
        uploadingTotal: event.total,
      });
    }
  };

  handleUploadLoad = (event, req) => {
    if (event.loaded === event.total) {
      this.setState({
        uploading: false,
        uploadingProgress: 0,
        uploadingTotal: 0,
      });
    } else {
      // create somethign went wrong simple notification
      simpleNotification({
        level: "error",
        title: "Error",
        message: "Error uploading content",
        autoDismiss: 5,
        // position: "tl",
      });
    }
  };

  handleLoad = (event, req) => {
    if (event.target.status === 200) {
      const { responseURL } = event.target;
      const toWrite = responseURL.split('?')[0];

      this.handleChange(toWrite, req.id);

      simpleNotification({
        level: "success",
        title: "Success",
        message: "Content uploaded successfully",
        autoDismiss: 5,
      });
    } else {
      simpleNotification({
        level: "error",
        title: "Error",
        message: "Error uploading content",
        autoDismiss: 5,
      });
    }
  };

  // Validate & update requirement in DB
  submitRequirement(req) {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    const { errors, errorMessages, submissions } = this.state;
    const submission = submissions[req.id];

    if (!!submission) {
      // Validate submission types
      if (req.type === 'submit-tweet' && (submission.value === '' || !twitterOrXUrlRegex.test(submission.value))) {
        errors[req.id] = true;
        errorMessages[req.id] = 'Sorry, that URL is invalid. Please make sure your link looks like this: https://www.twitter.com/path-to-content';
        this.setState({ errors, errorMessages });
        return;
      } else if (req.type === 'submit-link' && (submission.value === '' || !genericUrlRegex.test(submission.value))) {
        errors[req.id] = true;
        errorMessages[req.id] = 'Sorry, that URL is invalid. Please make sure your link looks like this: https://www.twitch.tv/path-to-content';
        this.setState({ errors, errorMessages });
        return;
      } else if (req.type === 'submit-content-media' && !submission.value) {
        errors[req.id] = true;
        errorMessages[req.id] = 'Please upload a file.';
        this.setState({ errors, errorMessages });
        return;
      }

      this.setState({
        editRequirementId: false,
      });

      const {
        approvalRequired, id, title, type, subtype,
      } = req;
      const reqToUpdate = {
        content: submission.value,
        questId,
        reqId: id,
        title,
        type,
        subtype,
        approvalRequired,
      };

      if (type === 'submit-content-media' && submission.contentType) {
        reqToUpdate.contentType = submission.contentType;
      }

      const data = JSON.stringify(reqToUpdate);
      this.props.submitRequirementData(data, token);
    }
  }

  // Check off requirement & update in DB
  checkBox(req, questId) {
    const {
      id, type, title, approvalRequired,
    } = req;
    const {
      canCollapseRequirements, collapsedRequirements, mobile, animatingRequirementId,
    } = this.state;

    if (!animatingRequirementId) {
      // Disallow check off on submission requirements
      if (type.includes('submit') || type === 'display-stream-time') {
        const { errors } = this.state;
        errors[id] = true;

        this.setState({
          errors,
        });

        return;
      }

      const { token } = this.props.auth;
      this.props.checkRequirement(id, true, type, title || '', questId, approvalRequired, token);

      // On desktop, collapse the requirement
      if (!mobile && !collapsedRequirements.includes(id)) {
        this.animateBackgroundCollapse(req.id);

        const newCanCollapseRequirements = canCollapseRequirements;
        canCollapseRequirements.push(id);

        this.setState({
          canCollapseRequirements: newCanCollapseRequirements,
        });
      }
    }
  }

  // Copy text from element where id = reqId
  copyText = (reqId) => {
    document.getElementById(reqId).select();
    document.execCommand('copy');
    this.openTooltip(reqId);
  }

  // Open, then close tooltip for copy button on requirement
  openTooltip(reqId) {
    this.setState({
      tooltipOpen: reqId,
    }, () => {
      setTimeout(() => {
        this.setState({
          tooltipOpen: false,
        });
      }, 1000);
    });
  }

  // Set requirement that is currently being edited
  editRequirement(reqId) {
    this.setState({
      editRequirementId: reqId,
    });

    const el = document.getElementById(`input-${reqId}`);
    if (el) el.placeholder = '';
  }

  // Return icon element for requirement box
  getIcon(req, quest) {
    // Custom icon
    const { icon, subtype, type } = req;
    if (icon) {
      return (<img className="imageIcon" alt="icon" src={icon} />);
    }

    // Default icon
    const defaultLogo = getDefaultIcon(type, subtype, quest, quest.game);

    if (['distribute-key', 'display-download'].includes(type) || (type === 'display-text' && subtype === 'game-name')) {
      return (<div className="imageIcon" style={{ backgroundImage: `url(${defaultLogo})` }} />);
    }

    if (subtype === 'schedule') {
      return (<div className="imageIcon schedule" style={{ backgroundImage: `url(${defaultLogo})` }} />);
    }

    return (<div className="icon"><img alt="verification" src={defaultLogo} /> </div>);
  }

  // Return value for distribution in requirement box
  getDistributeValue(req, quest) {
    const { type, subtype } = req;
    const { data: myGameKeys } = this.props.config.myGameKeys;

    if (type === 'distribute-key') {
      const gameKeyObj = find(myGameKeys, gameKeyItem => gameKeyItem.game.id === quest.game.id);

      if (gameKeyObj && gameKeyObj.key) return gameKeyObj.key;
      return 'No key assigned yet.';
    } else if (type === 'distribute-link' && quest) {
      if (subtype === 'affiliate') {
        const { user } = this.props.auth;
        const affiliateLink = `${origin}/buy/${quest.game.slug}/${quest.slug}/${user.username}`;
        return affiliateLink;
      } else if (subtype === 'campaign') {
        if (quest.promoLink) return formatLink(quest.promoLink) || 'No link assigned yet.';
        return 'No link assigned yet.';
      }
    }

    return 'Nothing assigned yet.';
  }

  // Toggle requirement collapsed/open
  toggleCollapseRequirement(reqId) {
    const { collapsedRequirements } = this.state;

    if (collapsedRequirements.includes(reqId)) {
      this.setState({
        collapsedRequirements: [
          ...without(this.state.collapsedRequirements, reqId),
        ],
      });
    } else {
      collapsedRequirements.push(reqId);
      this.setState({
        collapsedRequirements,
      });
    }
  }

  // Get title for requirement box
  getTitle(req, quest) {
    const { type, subtype, title } = req;
    const { game, requiredStreamTimeMinutes } = quest;

    switch (type) {
      case 'display-hashtags':
        if (subtype === 'quest') return getQuestHashtagsTitle(quest);
        else if (subtype === 'tracking') return getTrackingHashtagsTitle(quest);
        break;
      case 'display-text':
        if (subtype === 'game-name') return getDisplayGameTitle(game);
        break;
      case 'display-stream-time':
        return getStreamTrackTitle(requiredStreamTimeMinutes);
      default:
        break;
    }

    if (title) return title;

    return '';
  }

  // Return link for download requirement
  getDownload(req, quest) {
    const { type, subtype } = req;
    const { requiredBanner, overlayImage } = quest;

    if (type === 'display-download') {
      if (subtype === 'banner') return requiredBanner;
      else if (subtype === 'overlay') return overlayImage;
    }

    return null;
  }

  // Return boolean for if requirement is collapsable
  getIsCollapsable(req) {
    const { canCollapseRequirements } = this.state;

    if (!canCollapseRequirements.includes(req.id)) {
      return false;
    } else if (req.description === '') {
      if (req.type === 'display-text' || req.type === 'display-hashtags') {
        return false;
      }
    }

    return true;
  }

  // Return label that appears above copy/submit box
  getLabel(req) {
    const { label, type, subtype } = req;

    if (label) return label;

    const { quest } = this.state;
    const preload = preloadRequirement(type, subtype, quest, quest.game);

    if (preload.label) return preload.label;

    return false;
  }

  // Modal for when user has left quest
  generateLeftQuestModal() {
    return (
      <section className="Modal active">
        <div onClick={this.leaveQuestModalToggle.bind(this)} className="Modal__layer" aria-hidden />
        <section className="Modal__wrapper LeaveQuest">
          <div className='subtitle'> You have left this quest.</div>
          <Link to="/my-quests" href="/my-quests" className='buttonGroup'>
            <button>Return to My Quests</button>
          </Link>
        </section>
      </section>
    );
  }

  // Modal for user to choose to leave quest
  generateLeaveQuestModal(questId) {
    const { leavingQuest } = this.state;

    return (
      <section className="Modal active">
        <div onClick={this.leaveQuestModalToggle.bind(this)} className="Modal__layer" aria-hidden />
        {leavingQuest ? (
          <section className="Modal__wrapper LeaveQuest">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </section>
        ) : (
          <section className="Modal__wrapper LeaveQuest">
            <h1>Are you sure you want to leave this quest?</h1>
            <div className='subtitle'>You will lose any progress you have made and forfeit any payment you have earned for this quest.</div>
            <div className='subtitle'>Any keys you have been sent for this quest may also be revoked.</div>
            <br />
            <div className='reason_title'>REASON (OPTIONAL)</div>
            <TextareaAutosize
              aria-label="minimum height"
              className="textBlock"
              minRows={4}
              onChange={e => this.setState({ leaveQuestReason: e.target.value })}
            />
            <div className='buttonGroup'>
              <button className="leave" onClick={this.leaveQuest.bind(this, questId)}>Leave</button>
              <button className="cancel" onClick={this.leaveQuestModalToggle.bind(this)}>Cancel</button>
            </div>
            <i className="fa fa-times close_icon" onClick={this.leaveQuestModalToggle.bind(this)} />
          </section>
        )}
      </section>
    );
  }

  // Modal for grace period reminder
  generateGracePeriodModal(quest) {
    const { user } = this.props.auth;
    const dateString = localizeIncorrectDate(quest.endDateTime).add(QUEST_GRACE_PERIOD_DAYS, 'day').format(`MMMM Do, YYYY[ by ]${token12v24(user)}:mm a z`);

    return (
      <section className="Modal active">
        <div onClick={() => { this.setState({ gracePeriodModal: false }); }} className="Modal__layer" aria-hidden />
        <section className="Modal__wrapper">
          <h1 style={{ margin: '20px 40px' }}> Submissions are due by {dateString}</h1>
          <span> You will forfeit all compensation for this quest if you do not submit all requirements by that date. </span>
          <button style={{ margin: '32px 0 12px 0' }} onClick={() => { this.setState({ gracePeriodModal: false }); }}> Ok </button>
        </section>
      </section>
    );
  }

  // Create requirement box
  generateRequirementBox(req, quest, index, timedActivityObj, numReqs) {
    const {
      approvalRequired,
      description,
      placeholder,
      tooltip,
      type,
    } = req;
    const { requiredStreamTimeMinutes } = quest;
    const {
      animatingRequirementId,
      collapsedRequirements,
      editRequirementId,
      errors,
      errorMessages,
      mobile,
      questInGracePeriod,
      questOver,
      submissions,
      tooltipOpen,
      uqRequirements,
    } = this.state;

    const timePlaying = timedActivityObj && timedActivityObj.timePlaying ? timedActivityObj.timePlaying : 0;
    const noEditing = !!((questOver && !questInGracePeriod));
    const isUpload = type === 'submit-content-media';
    const isSubmission = type.indexOf('submit') > -1;
    const isDistribute = type.indexOf('distribute') > -1;
    const userQuestRequirement = find(uqRequirements, r => r.questReqId === req.id);
    const label = this.getLabel(req);
    const collapsed = !!(collapsedRequirements && collapsedRequirements.includes(req.id));
    const collapseable = this.getIsCollapsable(req);
    let checked = false;
    let content = false;
    let status = false;
    let note = false;
    // const last = index === (numReqs - 1);
    // const first = index === 0;

    if (userQuestRequirement) {
      status = userQuestRequirement.status;
      content = userQuestRequirement.content;
      note = userQuestRequirement.note;
      if (status === 'completed' || status === 'to-review') checked = true;
      else if (status === 'rejected') checked = false;
    } else if (type === 'display-stream-time' && timePlaying >= requiredStreamTimeMinutes) {
      checked = true;
    } else if (collapsed || collapseable) {
      checked = true;
    }

    // Needs attention when req is rejected, or req is not submitted and quest is over
    const needsAttention = status === 'rejected' || (!['to-review', 'completed'].includes(status) && questOver && approvalRequired);

    // Submission disabled after grace period, when not editing submitted req, and on completed reqs
    const submissionDisabled = noEditing || (status === 'to-review' && req.id !== editRequirementId) || status === 'completed';

    const requirementsBoxClasses = classNames({
      RequirementBox: true,
      rejected: needsAttention,
      'in-review': status === 'to-review',
    });

    const contentRightClasses = classNames({
      'content-right': true,
      collapseable,
    });

    let arrowIcon = false;
    if (collapsed || animatingRequirementId === req.id) {
      if (mobile) arrowIcon = expandMobile;
      else arrowIcon = expandDesktop;
    } else if (mobile) arrowIcon = condenseMobile;
    else arrowIcon = condenseDesktop;

    return (
      <div className="RequirementContainer" id={req.id}>
        {/* Timeline */}
        <div className="timeline">
          {/* <div className={topBarClasses} /> */}
          {/* <div className={bottomBarClasses} /> */}
        </div>
        <div className={requirementsBoxClasses} id={`box-${req.id}`}>
          <React.Fragment>
            <CustomCheckbox
              checked={checked}
              status={status}
              disabled={(status === 'completed' || status === 'to-review' || type === 'display-stream-time')}
              onChange={this.checkBox.bind(this, req, quest.id)}
            />
            {/* Icon */}
            {this.getIcon(req, quest)}

            {/* Collapsed state ( title only) */}
            <div className="content">
              <h2> {this.getTitle(req, quest)}</h2>
            </div>
          </React.Fragment>

          {/* Tooltip & expand/collapse button */}
          <span className={contentRightClasses}>
            {collapseable ? (
              <div className="collapseArea" onClick={this.toggleCollapseRequirement.bind(this, req.id)} />
            ) : null}
            <img className="collapseToggle" alt="collapse-toggle" src={arrowIcon} />
            {tooltip ? (
              <Tooltip interactive title={<span dangerouslySetInnerHTML={{ __html: tooltip }} />} placement="left">
                <div className="toolTip"> i </div>
              </Tooltip>
            ) : null}
          </span>
        </div>
        {collapsed === true ? (
          null
          /* Expanded state */
        ) : (
          <div className="collapsed_content1 content">
            { /* Stream time */}
            {type === 'display-stream-time' ? (
              this.generateStreamTimeBox(requiredStreamTimeMinutes, timePlaying, description)
              /* General title, description, label */
            ) : (
              <React.Fragment>
                {description ? (<span className="description" dangerouslySetInnerHTML={{ __html: description }} />) : (null)}
                {label ? (<span className="label">{label}</span>) : (null)}
              </React.Fragment>
            )}

            {/* Upload Content Media */}
            {isUpload ? (
              <div className="copyContent">
                {!submissionDisabled && <div className="content-media-selector">
                  <ContentMediaSelector
                    handleChange={this.handleContentTypeChange}
                    reqId={req.id}
                    quest={quest}
                    value={submissions[req.id]?.contentType || userQuestRequirement?.contentType}
                  />
                  <input
                    id={`input-${req.id}`}
                    placeholder={content || placeholder || 'Enter here'}
                    onChange={e => this.onDrop(e, req, quest)}
                    type="file"
                    disabled={submissionDisabled}
                    onFocus={e => e.target.placeholder = ''}
                    className={errors[req.id] ? 'error' : ''}
                  />
                </div>
                }
                {submissionDisabled && <div>Content Submitted</div>}

                {/* Submit & edit buttons */}
                {status === 'to-review' && req.id !== editRequirementId ? (
                  <div className="review">
                    {!noEditing ? (
                      <button className="edit" onClick={this.editRequirement.bind(this, req.id)}>
                        Edit
                      </button>
                    ) : null}
                    <span className="reviewStatus"> Under review </span>
                  </div>
                ) : status !== 'completed' ? (
                  noEditing ? (
                    <span className="reviewStatus error"> Quest Failed </span>
                  ) : (
                    <button onClick={this.submitRequirement.bind(this, req)}>
                      {req.id !== editRequirementId ? ('Submit') : ('Update')}
                    </button>
                  )
                ) : null}
              </div>
            ) : isSubmission ? (
              <div className="copyContent">
                <input
                  id={`input-${req.id}`}
                  placeholder={content || placeholder || 'Enter here'}
                  onChange={e => this.handleChange(e.target.value, req.id)}
                  type="text"
                  disabled={submissionDisabled}
                  onFocus={e => e.target.placeholder = ''}
                  className={errors[req.id] ? 'error' : ''}
                />

                {/* Submit & edit buttons */}
                {status === 'to-review' && req.id !== editRequirementId ? (
                  <div className="review">
                    {!noEditing ? (
                      <button className="edit" onClick={this.editRequirement.bind(this, req.id)}>
                        Edit
                      </button>
                    ) : null}
                    <span className="reviewStatus"> Under review </span>
                  </div>
                ) : status !== 'completed' ? (
                  noEditing ? (
                    <span className="reviewStatus error"> Quest Failed </span>
                  ) : (
                    <button onClick={this.submitRequirement.bind(this, req)}>
                      {req.id !== editRequirementId ? ('Submit') : ('Update')}
                    </button>
                  )
                ) : null}
              </div>

              /* Distribute content */
            ) : isDistribute ? (
              <div className="copyContent">
                <input readOnly className="action-box" type="text" id={`copy-${req.id}`} value={this.getDistributeValue(req, quest)} placeholder="Your key will appear here" />
                <Tooltip
                  open={tooltipOpen === `copy-${req.id}`}
                  title="Copied!"
                  disableHoverListener
                  placement="top"
                >
                  <button
                    className={classNames('action-box-button')}
                    onClick={this.copyText.bind(this, `copy-${req.id}`)}
                  >
                    Copy
                  </button>
                </Tooltip>
              </div>

              /* Download content */
            ) : type === 'display-download' ? (
              <a href={this.getDownload(req, quest)} target="_blank" rel="nofollow noopener noreferrer" download>
                <button className="download">
                  Download
                </button>
              </a>
            ) : null}

            {/* Below content notes & errors */}
            {(errors[req.id] && errorMessages[req.id]) ? (
              <span className="error">{errorMessages[req.id]}</span>
            ) : note && status === 'rejected' ? (
              <span className="note">{note}</span>
            ) : noEditing && isSubmission && !['completed', 'to-review'].includes(status) ? (
              <span className="reviewStatus-mobile error"> Quest Failed </span>
            ) : null}
            {status === 'to-review' && req.id !== editRequirementId ? (
              <span className="reviewStatus-mobile"> Under review </span>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  // Create stream time box
  generateStreamTimeBox(requiredTimeMinutes, streamedTimeMinutes, description) {
    const hoursStreamed = streamedTimeMinutes ? Math.floor((streamedTimeMinutes / 60)).toFixed(0) : 0;
    const minutesStreamed = streamedTimeMinutes ? (streamedTimeMinutes % 60).toFixed(0) : 0;
    const timeString = getStreamTrackTitle(requiredTimeMinutes);
    let statusString = 'Stream incomplete';
    const indicatorClasses = { indicator: true };
    if (streamedTimeMinutes >= requiredTimeMinutes) {
      statusString = 'Stream complete';
      indicatorClasses.complete = true;
    } else if (streamedTimeMinutes > 0) {
      statusString = 'Stream in progress';
    }

    return (
      <React.Fragment>
        <h2>{timeString}</h2>
        {description ? (<span className="description" dangerouslySetInnerHTML={{ __html: description }} />) : (null)}
        <div className="timeBox">
          <div className="valueTrack">
            <div className="count">{hoursStreamed}</div>
            <div className="description"> HRS </div>
          </div>
          <div className="valueTrack">
            <div className="count">{minutesStreamed}</div>
            <div className="description"> MIN </div>
          </div>
        </div>
        <div className="timeStatus">
          <span className={classNames(indicatorClasses)} />
          <span>{statusString}</span>
        </div>
      </React.Fragment>
    );
  }

  // Create lefthand-side navigation item
  generateRequirementNav(req, index, quest) {
    const { activeRequirementId, questOver, uqRequirements } = this.state;
    const userQuestRequirement = find(uqRequirements, r => r.questReqId === req.id);

    // Error state on rejected requirements, and missing requirements when quest is over
    let error = false;
    if (questOver && !userQuestRequirement && req.approvalRequired) error = true;
    else if (userQuestRequirement && userQuestRequirement.status === 'rejected') error = true;

    const classes = classNames({
      navItem: true,
      rejected: error,
    });

    // Restrict title length
    let title = this.getTitle(req, quest);
    title = textTruncate(title, 30);

    return (
      <li className={req.id === activeRequirementId ? 'active' : ''} key={req.id}>
        <div className={classes} onClick={this.scrollTo.bind(this, req.id)}>{title}</div>
        {error ? (<i className="fa fa-exclamation-triangle" style={{ margin: '0 0 0 5px' }} aria-hidden="true" />) : (null)}
      </li>
    );
  }

  // Get box for noiz store commission information
  generateCommissionBox(quest, userQuest, referralActivityObj) {
    const { user } = this.props.auth;
    const {
      commissionAmount, commissionPercentage, referralPaymentType, referralBonus, salesForBonus, bonusValue, referralBonusType, referralBonusTimes,
    } = quest;
    const { tooltipOpen } = this.state;
    const affiliateLink = `${window.location.origin}/buy/${quest.game.slug}/${quest.slug}/${user.username}`;

    let numberOfReferrals = 0;
    let totalEarnings = 0;
    let bonus = 0;

    if (referralActivityObj) {
      numberOfReferrals = referralActivityObj.numberOfReferrals;
      totalEarnings = referralActivityObj.totalEarnings;

      if (totalEarnings && numberOfReferrals) {
        bonus = getReferralBonus(userQuest, quest);
      }
    }

    const earnings = totalEarnings ? removeCommissionRounding((totalEarnings / 100) + bonus) : 0;

    let commissionBaseString = '';
    let commissionBonusString = '';

    // Base payment
    if (commissionPercentage && referralPaymentType === 'commission-percentage') commissionBaseString = `Earn ${commissionPercentage}% of every game sale`;
    else if (commissionAmount && referralPaymentType === 'commission-amount') commissionBaseString = `Earn $${commissionAmount} for every game sale`;

    // Bonus payment
    if (referralBonus) {
      if (bonusValue) {
        if (referralBonusType === 'bonus-in-amount') {
          commissionBonusString = `plus $${bonusValue}`;
        } else if (referralBonusType === 'bonus-in-percentage') {
          commissionBonusString = `plus ${bonusValue}%`;
        }

        if (salesForBonus) commissionBonusString += ` every ${salesForBonus} sale(s)`;
        if (referralBonusTimes === 'once') commissionBonusString += ' (maximum of once)';
      }
    }

    return (
      <div className="CommissionBox">
        <div className="static-content">
          <h2> Commission </h2>
          <span>{commissionBaseString + (commissionBonusString ? `, ${commissionBonusString}` : '')}</span>
        </div>

        <div className="variable-content">
          <div className="values">
            <div className="valueTrack">
              <div className="count">{numberOfReferrals || 0}</div>
              <div className="description">Sales</div>
            </div>
            <div className="valueTrack">
              <div className="count">{`$${earnings}`}</div>
              <div className="description">Commission</div>
            </div>
          </div>
          <div className="trackingLink">
            <div className="labelLink">
              <span className="label"> Tracking Link </span>
              <input readOnly className="action-box" type="text" id="referral-link" value={affiliateLink} placeholder="Your link will appear here" />
            </div>
            <Tooltip
              open={tooltipOpen === 'referral-link'}
              title="Copied!"
              disableHoverListener
              placement="top"
            >
              <button
                className={classNames('action-box-button')}
                onClick={this.copyText.bind(this, 'referral-link')}
              >
                Copy
              </button>
            </Tooltip>
          </div>
        </div>

      </div>
    );
  }

  // Create top-page status bar
  generateStatusBar(quest, userQuest, timePlaying) {
    const { user } = this.props.auth;
    const { userCanleave, endDateTime, twitchAccountRequired = false } = quest;
    const {
      mobile, sticky, statusBarOpen, questOver, questInGracePeriod,
    } = this.state;

    const streamingQuest = isStreamingQuest(quest);
    const autoOrSecondQuest = isAutoStreamQuest(quest);

    let payment = false;
    const { paymentAmount } = getPaymentAmountNew(quest.splitTiers, quest.rankTiers, quest.memberTiers, user, quest.viewerTiers, userQuest);

    if (paymentAmount > 0) {
      payment = `$${rounding(paymentAmount)}`;
    } else if (paymentAmount === -1) {
      payment = 'Free Key';
    } else if (paymentAmount === -2) {
      payment = 'Points';
    }

    let dateString = '';
    let statusClass = '';
    const questStatusInfo = getCompletionStatus(quest, userQuest, timePlaying);
    if (!autoOrSecondQuest) {
      // Quest completed
      if (questStatusInfo && questStatusInfo.status === 'C') {
        statusClass = 'completed';
        dateString = 'QUEST COMPLETED';

        // Grace period
      } else if (questOver && questInGracePeriod) {
        statusClass = 'error';
        const endDateString = localizeIncorrectDate(endDateTime).add(QUEST_GRACE_PERIOD_DAYS, 'days').format(`MMMM Do YYYY, ${token12v24(user)}:mm a z`);
        dateString = `SUBMISSIONS DUE BY ${endDateString ? endDateString.toUpperCase() : ''}`;

        // Quest over
      } else if (questOver) {
        statusClass = 'error';
        dateString = 'QUEST FAILED';

        // Quest in progress
      } else {
        const endDateString = localizeIncorrectDate(endDateTime).format(`MMMM Do YYYY, ${token12v24(user)}:mm a z`);
        dateString = `COMPLETE BY ${endDateString ? endDateString.toUpperCase() : ''}`;
        statusClass = 'in-progress';
      }
    }


    let arrowIcon = expandMobile;
    if (statusBarOpen) arrowIcon = condenseMobile;

    // Mobile
    if (mobile) {
      const paymentClasses = classNames({
        freeKey: !!(payment && payment === 'Free Key'),
      });

      return (
        <div className="StatusBarContainer" id="status-bar-container">
          <section className="StatusBar" id="status-bar">
            <h2>{quest ? quest.title : ''}</h2>
            <div id="status-bar-content-mobile">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {payment ? (
                  <div className={classNames('paymentContainer', paymentClasses)}>
                    <h3 className="payment">{payment}</h3>
                  </div>
                ) : null}
                <div className="streamingPlatform">
                  {(streamingQuest || autoOrSecondQuest) && twitchAccountRequired ?
                    (<>
                      <Icon name='twitch' />&nbsp;Twitch Quest
                    </>)
                    :
                    (<>
                      <Icon name='scroll' />&nbsp;Basic Quest
                    </>)
                  }
                </div>
              </div>
              <div className={classNames('status', statusClass)}>{dateString}</div>
              {userCanleave ? (<button className="leave-quest" onClick={this.leaveQuestModalToggle.bind(this)}>Leave Quest</button>) : null}
            </div>
            <img alt="collapse-toggle" onClick={this.animateStatusBar.bind(this)} src={arrowIcon} />
          </section>
        </div>
      );

      // Desktop
    }
    const statusBarClasses = classNames({
      StatusBar: true,
      sticky,
    });

    return (
      <div className="StatusBarContainer" id="status-bar-container">
        <section className={statusBarClasses} id="status-bar">
          <div className="left">
            <h2>{quest ? quest.title : ''}</h2>
            <div className={classNames('status', statusClass)}>{dateString}</div>
          </div>
          {/* {userCanleave ? (<button className="leave-quest" onClick={this.leaveQuestModalToggle.bind(this)}>Leave Quest</button>) : null} */}
          {userCanleave && !isQuestOver(quest) && (autoOrSecondQuest && (!quest.questAutoEnd || !quest.questAutoCheckout)) ? (<button className="leave-quest" onClick={this.leaveQuestModalToggle.bind(this)}>Exit Task</button>) : null}

        </section>
      </div>
    );
  }

  generateMissingRequirementsBar(quest, userQuest, timePlaying) {
    const { user } = this.props.auth;
    const { requirements } = userQuest;
    const { mobile, questOver, questInGracePeriod } = this.state;
    const rejectedRequirements = requirements ? requirements.filter(r => r.status === 'rejected') : [];
    const missingRejectedReqs = [];
    let notifString = '';

    // Quest is not over & nothing rejected, return
    if (!questOver && !rejectedRequirements.length) {
      return;

      // Quest is not over and requirements are rejected
    } else if (!questOver && rejectedRequirements.length) {
      notifString = 'You have not met the following requirement(s)';

      // Get all rejected requirements
      const submittableRequirements = getQuestRequirements(quest, true);
      for (let i = 0; i < rejectedRequirements.length; i++) {
        const req = rejectedRequirements[i];
        const questReq = submittableRequirements ? submittableRequirements.find(r => r.id === req.questReqId) : false;
        if (questReq) missingRejectedReqs.push(questReq);
      }

      // Quest is over
    } else if (questOver) {
      // Get all rejected or missing requirements
      const submittableRequirements = getQuestRequirements(quest, true);
      for (let i = 0; i < submittableRequirements.length; i++) {
        const req = submittableRequirements[i];
        const userQuestReq = requirements ? requirements.find(r => r.questReqId === req.id) : false;
        if (!userQuestReq || (userQuestReq && userQuestReq.status === 'rejected')) missingRejectedReqs.push(req);
      }

      const endDateTimeWithGrace = localizeIncorrectDate(quest.endDateTime).add(QUEST_GRACE_PERIOD_DAYS, 'day')
        .format(`MMMM Do, YYYY[ at ]${token12v24(user)}:mm a z`);

      // Quest in grace period
      if (questInGracePeriod) {
        notifString = `All submissions are due by ${endDateTimeWithGrace}. You have not met the following requirements:`;
      } else {
        notifString = `<b>Quest failed.</b> All submissions were due on ${endDateTimeWithGrace}. You did not meet the following requirements:`;
      }
    }

    if (missingRejectedReqs.length) {
      return (
        <div className="missingRequirementsBar">
          <span dangerouslySetInnerHTML={{ __html: notifString }} />
          <ul>
            {
              missingRejectedReqs.map(req => (
                <li onClick={this.scrollTo.bind(this, req.id)}>{textTruncate(this.getTitle(req, quest), mobile ? 30 : 80)}</li>
              ))
            }
          </ul>
        </div>
      );
    }
  }

  // 添加检查和重定向方法
  checkAndRedirectToRequirements() {
    const { quest } = this.state;
    const { router } = this.props;

    if (quest) {
      const autoQuestStream = isAutoStreamQuest(quest);
      const currentPath = router.getCurrentLocation().pathname;

      // 如果不是自动流任务，且当前路径不包含 requirements，则重定向
      if (!autoQuestStream && !currentPath.includes('requirements')) {
        const questId = this.props.router.params.questId;
        router.replace(`/my-quests/${questId}/requirements`);
      }
    }
  }

  render() {
    const {
      config: {
        quest: {
          isLoading: isLoadingQuest,
        },
      },
      router: {
        params: {
          questId,
        },
      },
      user: {
        myUserQuests: {
          data: myUserQuests,
          isLoading: isLoadingMyUserQuests,
        },
        referralsActivity: {
          data: referralsActivity,
          isLoading: isLoadingReferralsActivity,
        },
        timedActivity: {
          data: timedActivity,
          isLoading: isLoadingTimedActivity,
        },
      },
    } = this.props;

    const {
      // activeRequirementId,
      gracePeriodModal,
      leaveQuestModal,
      leftQuest,
      navStickyBottom,
      quest,
      uploading,
      uploadingProgress,
      uploadingTotal,
      questOver
    } = this.state;

    // User has left quest
    if (leftQuest) {
      return (
        <section className="QuestDetailPage">
          {this.generateLeftQuestModal()}
        </section>
      );
    }

    // Quest stuff is loading
    const isLoading = isLoadingQuest || isLoadingMyUserQuests || isLoadingReferralsActivity || isLoadingTimedActivity;
    const userQuest = find(myUserQuests, q => q.quest === questId) || {};
    // console.log("==============================userquest===============",userQuest)
    const isRequirements = window.location.pathname.includes("requirements")
    // console.log("switchType", isRequirements)

    // Make sure user is in quest
    let userQuestValid = false;
    if (userQuest) {
      const { status } = userQuest;
      if (status === 'approved' || status === 'normal') {
        userQuestValid = true;
      }
    }

    // Invalid quest for this user
    if (!isLoading && (!quest || !userQuestValid) && !this.isPreview()) {
      return (
        <section className="QuestDetailPage">
          <section className="QuestDetailPage__container1">
            <div className="noQuestFound">
              No quest found.
              <div>
                <Link to="/my-quests" href="/my-quests">
                  <button className="left">Return to My Quests</button>
                </Link>
              </div>
            </div>
          </section>
        </section>
      );
    }

    const { isReferral = false, requirements = [], game = {} } = quest;

    // const navClasses = classNames({
    //   NavContainer: true,
    //   sticky: (this.state.navSticky && !navStickyBottom),
    //   stop: !!navStickyBottom,
    // });

    // Check which nav section has active requirement
    // const questRequirements = requirements;
    // let activeNavHeader = 'beforeStream';
    // if (questRequirements && questRequirements.duringStream && find(questRequirements.duringStream, req => req.id === activeRequirementId)) {
    //   activeNavHeader = 'duringStream';
    // } else if (questRequirements && questRequirements.afterStream && find(questRequirements.afterStream, req => req.id === activeRequirementId)) {
    //   activeNavHeader = 'afterStream';
    // }

    const referralActivityObj = referralsActivity ? find(referralsActivity, obj => obj.questId === quest.id) : false;
    const timedActivityObj = find(timedActivity, obj => (obj && obj.quest && obj.quest._id === quest.id));

    const beforeStream = requirements && requirements.beforeStream ? requirements.beforeStream : [];
    const duringStream = requirements && requirements.duringStream ? requirements.duringStream : [];
    const afterStream = requirements && requirements.afterStream ? requirements.afterStream : [];

    // const beforeStreamIndex = beforeStream && beforeStream.length ? 1 : 0;
    // const duringStreamIndex = duringStream && duringStream.length ? beforeStreamIndex + 1 : 0;
    // const afterStreamIndex = afterStream && afterStream.length ? duringStreamIndex + 1 : 0;

    // Stick to top while scrolling;
    // let { navStickyTop } = this.state;
    if (navStickyBottom) {
      // navStickyTop = navStickyBottom;
    }

    const timePlaying = timedActivityObj && timedActivityObj.timePlaying ? timedActivityObj.timePlaying : 0;

    const graceModal = gracePeriodModal && ((getNumApprovedRequirements(quest, userQuest) + getNumInReviewRequirements(quest, userQuest)) < getQuestRequirements(quest, true).length);

    const autoQuestStream = isAutoStreamQuest(quest);
    return (
      <section
        className="QuestDetailPage"
        style={{
          backgroundImage: `
          linear-gradient(to bottom, rgba(24, 25, 29, 1), rgba(24, 25, 29, 0.7)), 
          linear-gradient(to bottom, rgba(58, 116, 224, 0.25), rgba(161, 66, 255, 0.25)), 
          url(${game.cover})`,
        }}
      >
        <section className="QuestDetailPage__container1">
          <Loading isLoading={isLoading} />
          {this.generateStatusBar(quest, userQuest, timePlaying)}
          {this.generateMissingRequirementsBar(quest, userQuest, timePlaying)}
          <section className='QuestDetailPage__inContainer'>
            <div className='sidebar'>
              <ul className="nav-tabs">
                {autoQuestStream ? (<li>
                  <Link
                    className="nav-tab"
                    to={`/my-quests/${questId}/statistics`}
                    href={`/my-quests/${questId}/statistics`}
                    role="button"
                    aria-hidden
                    activeClassName="active"
                  >
                    Statistics
                  </Link>
                </li>) : null}
                <li>
                  <Link
                    className="nav-tab"
                    to={`/my-quests/${questId}/requirements`}
                    href={`/my-quests/${questId}/requirements`}
                    role="button"
                    aria-hidden
                    activeClassName="active"
                  >
                    Requirements
                  </Link>
                </li>
              </ul>
            </div>
            {isRequirements &&
              <section className="QuestDetailPage__scrollContainer" style={{ padding: 0 }}>
                { /* Lefthand Nav Bar */}
                <div className="ReqContainer" id="ReqContainer">
                  {isReferral ? (
                    this.generateCommissionBox(quest, userQuest, referralActivityObj)
                  ) : null}
                  {beforeStream && beforeStream.length ? (
                    <div className='ReqContainer_items'>
                      <h1>
                        <span >1</span>
                        Before the Stream
                      </h1>
                      {requirements.beforeStream.map((r, index) => (
                        this.generateRequirementBox(r, quest, index, timedActivityObj, requirements.beforeStream.length)
                      ))}
                    </div>
                  ) : null}
                  {duringStream && duringStream.length ? (
                    <div className='ReqContainer_items'>
                      <h1> <span >2</span> During the Stream </h1>
                      {requirements.duringStream.map((r, index) => (
                        this.generateRequirementBox(r, quest, index, timedActivityObj, requirements.duringStream.length)
                      ))}
                    </div>
                  ) : null}
                  {afterStream && afterStream.length ? (
                    <div className='ReqContainer_items'>
                      <h1> <span >3</span> After the Stream </h1>
                      {requirements.afterStream.map((r, index) => (
                        this.generateRequirementBox(r, quest, index, timedActivityObj, requirements.afterStream.length)
                      ))}
                    </div>
                  ) : null}
                  {!isReferral
                    && beforeStream.length === 0
                    && duringStream.length === 0
                    && afterStream.length === 0 &&
                    (
                      <div className='no_req'>
                        There are no additional requirements.
                      </div>
                    )}
                </div>
              </section>}
            {!isRequirements && autoQuestStream &&
              <section className="QuestDetailPage__scrollContainer">
                { /* Lefthand Nav Bar */}
                <div className="ReqContainer" id="ReqContainer">
                  <p className='update_message'>
                    <i className="fa fa-caret-down" />
                    Data updates automatically every day
                  </p>
                  <div className='platform-indicator'>
                    <div className="platform-icon-wrapper">
                      <Icon name='twitch' />
                    </div>
                    <span className="platform-name">Twitch Streaming Data</span>
                  </div>
                  <div className='ReqContainer_item'>
                    <p className='ReqContainer_item__title'> Reward Progress </p>
                    {quest.splitTiers && quest.splitTiers.length ? (
                      quest.splitTiers.map((tier, index) => {
                        // Make the conversion explicit with a descriptive variable name
                        const requiredMinutes = tier.streamHours * 60;
                        const userMinutes = userQuest.liveStreamingTime || 0;
                        const userDays = userQuest.liveStreamingDays || 0;
                        const requiredDays = tier.streamDays || 0;

                        // Clear comparison with properly named variables
                        const achieved = (
                          userMinutes >= requiredMinutes &&
                          userDays >= requiredDays
                        );
                        return (
                          <div key={index} className={`reward_item ${achieved ? 'achieved' : ''}`}>
                            <div className='item__info'>
                              <div className='info__name'>
                                {`Tier ${index + 1}`}
                              </div>
                              <div className='info__requirement'>
                                {tier.streamHours ? `Total streaming time for ${formatValueWithUnit(tier.streamHours, 'hour')}` : ''}
                                {tier.streamDays ? `, Total streaming days for ${formatValueWithUnit(tier.streamDays, 'day')}` : ''}
                                {tier.prizePool ? `, Prize pool: $${tier.prizePool}` : ''}
                              </div>
                            </div>
                            <div className='item__status'>
                              <div className={`item__status__icon ${achieved ? 'achieved_color' : ''}`}>
                                {achieved ? '√' : ''}
                              </div>
                              <span style={{ color: '#fff' }}>
                                {achieved ? 'Achieved' : questOver ? 'Failed' : 'In Progress'}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No tiers</div>
                    )}
                  </div>
                  <div className='ReqContainer_item'>
                    <p className='ReqContainer_item__title'> Current Ranking </p>
                    <div className='rank-info'>
                      <div className='current-rank'>{userQuest.rank ? `#${userQuest.rank}` : '--'}</div>
                      <div className='estimated-earnings'>
                        <div className="earnings-label">Estimated Prize Money</div>
                        <div className="earnings-value">${userQuest.leaderboardAmount}</div>
                      </div>
                    </div>
                  </div>
                  <div className='ReqContainer_item'>
                    <p className='ReqContainer_item__title'> Overall Statistics </p>
                    <div className='overview-grid'>
                      <div className='overview-item'>
                        <div className="overview-value">{formatMinutesToHoursAndMinutes(userQuest.liveStreamingTime)}</div>
                        <div className="overview-label">Total Streaming Time</div>
                      </div>
                      <div className='overview-item'>
                        <div className="overview-value">${userQuest.rewardData}</div>
                        <div className="overview-label">Total Revenue</div>
                      </div>
                      <div className='overview-item'>
                        <div className="overview-value">{formatValueWithUnit(userQuest.liveStreamingDays, 'day')}</div>
                        <div className="overview-label">Total Streaming Days</div>
                      </div>
                      <div className='overview-item'>
                        <div className="overview-value">{formatMinutesToHoursAndMinutes(userQuest.viewingTime)}</div>
                        <div className="overview-label">Total Watch Time</div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>}
          </section>
        </section>
        {leaveQuestModal && this.generateLeaveQuestModal(quest.id)}
        {graceModal && this.generateGracePeriodModal(quest)}
        <div className="BottomGradient" />

        <BootstrapDialog
          // onClose={handleSave}
          aria-labelledby="customized-dialog-title"
          disableEscapeKeyDown
          open={!!uploading}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Uploading
          </DialogTitle>
          <DialogContent dividers>
            <div className='uploading-progress'>
              {humanFileSize(uploadingProgress)} / {humanFileSize(uploadingTotal)}
              <br />
              {rounding(uploadingProgress / uploadingTotal * 100)} %
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleSave}>
              Save changes
            </Button>
          </DialogActions> */}
        </BootstrapDialog>
      </section>
    );
  }
}

export default QuestDetailPage;
