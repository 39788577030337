/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import React from 'react';
// import { Link } from 'react-router';
import classNames from 'classnames';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  find,
  isBoolean,
  isEqual,
  isObject,
  isNumber,
  keys,
  omit,
  sortBy,
  toNumber,
  // forEach,
} from 'lodash';
import { CountryRegionData } from 'react-country-region-selector';
import languages from 'languages';
import JSONPretty from 'react-json-pretty';
import Dropzone from 'react-dropzone';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import 'react-json-pretty/themes/acai.css';
import RichText from '../../utils/RichText';
import {
  differenceObject,
  // dynamicSort,
  friendlyURICharacters,
  getDefaultIcon,
  getQuestDurationHours,
  preloadRequirement,
  replaceSpaces,
  isAutoStreamQuest,
  isSecondaryCreationQuest,
  isStreamingQuest,
  isQuestTimeSetValid,
  isNewTypeQuestStart
} from '../../utils/functions';
import { simpleNotification } from '../../utils/notifications';

import ArrayDropDown from '../../utils/redux-forms-custom-fields/ArrayDropDown';
import CustomDateTimePicker from '../../utils/redux-forms-custom-fields/CustomDateTimePicker';
import CustomDropDownArray from '../../utils/redux-forms-custom-fields/CustomDropDownArray';
import CustomDropDownGeneric from '../../utils/redux-forms-custom-fields/CustomDropDownGeneric';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import CustomInputCheckbox from '../../utils/redux-forms-custom-fields/CustomInputCheckbox';
import CustomTagField from '../../utils/redux-forms-custom-fields/CustomTagField';
import TiersWhitelist from './QuestForm/TiersWhitelist';
import { arrayDropDownColors } from '../../utils/constants';
import './skin.min.css';

import { ReactComponent as AdminBannerIcon } from '../../components/images/requirement-icons/admin/banner-icon.svg';
import { ReactComponent as AdminBuildStreamIcon } from '../../components/images/requirement-icons/admin/build-stream.svg';
import { ReactComponent as AdminGameDirectoryIcon } from '../../components/images/requirement-icons/admin/game-directory-icon.svg';
import { ReactComponent as AdminGameKeyIcon } from '../../components/images/requirement-icons/admin/game-key-icon.svg';
import { ReactComponent as AdminHashtagIcon } from '../../components/images/requirement-icons/admin/hashtag-icon.svg';
import { ReactComponent as AdminCheckboxIcon } from '../../components/images/requirement-icons/admin/checkbox-icon.svg';
import { ReactComponent as AdminAchievementIcon } from '../../components/images/requirement-icons/admin/achievement-icon.svg';
import { ReactComponent as AdminClipIcon } from '../../components/images/requirement-icons/admin/clip-icon.svg';
import { ReactComponent as AdminTextIcon } from '../../components/images/requirement-icons/admin/text-icon.svg';
import { ReactComponent as AdminLinkIcon } from '../../components/images/requirement-icons/admin/link-icon.svg';
import { ReactComponent as AdminStreamIcon } from '../../components/images/requirement-icons/admin/stream-icon.svg';
import { ReactComponent as AdminTwitterIcon } from '../../components/images/requirement-icons/admin/twitter-icon.svg';
import { ReactComponent as AdminVerificationIcon } from '../../components/images/requirement-icons/admin/verification-icon.svg';
import closeButton from '../../components/images/close-button.svg';
import RequiredGames from './QuestForm/RequiredGames';
import TiersTaskMode from './QuestForm/TiersTaskMode';

// 修改格式定义，使用ISO格式作为存储格式
const dateStoreFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'; // ISO 8601格式，与服务器匹配
const dateDisplayFormat = 'MM-DD-YYYY HH:mm'; // 本地显示格式

// 在文件顶部或 validate 函数外部定义辅助函数
const getQuestTypeFlagsFromValues = (values = {}) => {
  const streamingQuest = isStreamingQuest(values);
  const autoStreamingQuest = isAutoStreamQuest(values);
  const secondaryCreationQuest = isSecondaryCreationQuest(values);
  const isNewTypeQuest = autoStreamingQuest || secondaryCreationQuest;
  const isStreamOrNewTypeQuest = autoStreamingQuest || secondaryCreationQuest || streamingQuest;
  return {
    streamingQuest,
    autoStreamingQuest,
    secondaryCreationQuest,
    isNewTypeQuest,
    isStreamOrNewTypeQuest,
  };
};

class QuestForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.faqs = [];
    this.questDescriptions = [];
    this.state = {
      activeModal: 0,
      addRequirementModal: false,
      dropzoneActive: false,
      file: '',
      image: '',
      indexIcon: false,
      logsModal: 0,
      modalType: '',
      news: [],
      olds: [],
      parametersSubTab: 0,
      pointInQuest: false,
      pointInQuestIcon: false,
      prevGamesPlayed: [],
      subTab: 'landing',
      tab: 'details',
      parametersAwardMode: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.preSignedUrl.isLoading === true && nextProps.preSignedUrl.isLoading === false) {
      const { data: signedUrl } = nextProps.preSignedUrl;
      this.uploadToS3(this.state.file, signedUrl.preSignedUrl);
    }

    if (this.props.quest && this.props.quest.isCreating === true && nextProps.quest.isCreating === false) {
      if (nextProps.quest.errors.hasError) {
        if (nextProps.quest.errors.code === 'E_QUEST_SLUG_DUPLICATED') {
          this.setState({ tab: 'user-info' });
          this.scrollToSlug();
        }
      }
    }

    if (this.props.quest && this.props.quest.isUpdating === true && nextProps.quest.isUpdating === false) {
      if (nextProps.quest.errors.hasError) {
        if (nextProps.quest.errors.code === 'E_QUEST_SLUG_DUPLICATED') {
          this.setState({ tab: 'user-info' });
          this.scrollToSlug();
        }
      }
    }

    if (this.props.isLoading && !nextProps.isLoading && nextProps.quest) {
      this.setState({ prevGamesPlayed: (nextProps.quest.prevGamesPlayed || []) });
    }
  }

  getQuest = (quest) => {
    if (quest.comingSoon) return;

    const { auth: { token }, getQuest } = this.props;

    getQuest(quest.id, true, token);
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const file = files && files.length ? files[0] : e[0];
    this.setState({
      dropzoneActive: false,
      file,
    });
    const { image } = this.state;
    const format = file.name.split('.')[1];
    const { auth: { token }, requestUploadCCImageUrl } = this.props;

    if (image === 'banner') {
      requestUploadCCImageUrl('banner', format, token);
    } else if (image === 'regular') {
      requestUploadCCImageUrl('regular', format, token);
    } else if (image === 'overlay') {
      requestUploadCCImageUrl('overlay', format, token);
    } else if (image === 'icon') {
      requestUploadCCImageUrl('icon', format, token);
    }

    this.setState({ activeModal: 2 });
  }

  getStepsTypes = (questIsAffiliate, requiredPreapproval) => {
    let stepTypeOptions = [
      {
        label: 'Banner', value: 'display-download-banner', type: 'display-download', subtype: 'banner',
      },
      {
        label: 'Build Stream', value: 'display-text-build-stream', type: 'display-text', subtype: 'build-stream',
      },
      {
        label: 'Clip', value: 'submit-link-clip', type: 'submit-link', subtype: 'clip',
      },
      {
        label: 'Checkbox', value: 'display-text-checkbox', type: 'display-text', subtype: 'checkbox',
      },
      {
        label: 'Game Directory', value: 'display-text-game-name', type: 'display-text', subtype: 'game-name',
      },
      {
        label: 'Game Key', value: 'distribute-key', type: 'distribute-key', subtype: false,
      },
      {
        label: 'Hashtag (Quest)', value: 'display-hashtags-quest', type: 'display-hashtags', subtype: 'quest',
      },
      {
        label: 'Hashtag (Tracking)', value: 'display-hashtags-tracking', type: 'display-hashtags', subtype: 'tracking',
      },
      {
        label: 'In-game Achievement', value: 'display-text-achievement', type: 'display-text', subtype: 'achievement',
      },
      {
        label: 'Link (Affiliate)', value: 'distribute-link-affiliate', type: 'distribute-link', subtype: 'affiliate',
      },
      {
        label: 'Link (Campaign)', value: 'distribute-link-campaign', type: 'distribute-link', subtype: 'campaign',
      },
      {
        label: 'Overlay', value: 'display-download-overlay', type: 'display-download', subtype: 'overlay',
      },
      {
        label: 'Stream Tracking', value: 'display-stream-time', type: 'display-stream-time', subtype: false,
      },
      {
        label: 'Submit Stream Schedule', value: 'submit-schedule', type: 'submit-text', subtype: 'schedule',
      },
      {
        label: 'Text (General)', value: 'display-text', type: 'display-text', subtype: false,
      },
      {
        label: 'Tweet', value: 'submit-tweet', type: 'submit-tweet', subtype: false,
      },
      {
        label: 'Verification', value: 'submit-text', type: 'submit-text', subtype: false,
      },
      {
        label: 'VOD', value: 'submit-link-vod', type: 'submit-link', subtype: 'vod',
      },
      {
        label: 'Submit link', value: 'submit-link', type: 'submit-link', subtype: false,
      },
      {
        label: 'Submit Content Media', value: 'submit-content-media', type: 'submit-content-media', subtype: false,
      },
      {
        label: 'Submit Text (Optional)', value: 'submit-text-optional', type: 'submit-text-optional', subtype: false,
      },
    ];
    /* START - Only Affiliate quest steps */
    if (questIsAffiliate) {
      const affiliateStepTypeOptions = [
        { label: 'Instagram', value: 'instagram' },
        { label: 'Facebook', value: 'facebook' },
        { label: 'Twitter', value: 'twitter' },
        { label: 'Youtube', value: 'youtube' },
      ];
      stepTypeOptions = [
        ...stepTypeOptions,
        ...affiliateStepTypeOptions,
      ];
    }
    stepTypeOptions = sortBy(stepTypeOptions, ['label']);
    /* END - Only Affiliate quest steps */
    return stepTypeOptions;
  }

  scrollToSlug = () => {
    this.slugE.scrollIntoView({ behavior: 'smooth' });
  }

  changeModalState = (value) => {
    this.setState({ activeModal: value });
  }

  submit = (questFormInformation) => {

    const {
      autoStreamingQuest,
      secondaryCreationQuest,
      isNewTypeQuest,
    } = this.getQuestTypeFlags();

    const { game, onSubmit, quest } = this.props;
    const { prevGamesPlayed } = this.state;
    const questFormInfo = questFormInformation;
    const requiredDateTimeStart = moment(questFormInfo.requiredStartDateTime, 'YYYY-MM-DD');
    const requiredDateTimeEnd = moment(questFormInfo.requiredEndDateTime, 'YYYY-MM-DD');
    const range = requiredDateTimeEnd.diff(requiredDateTimeStart, 'minutes');
    questFormInfo.requiredStreamRangeTime = range;
    questFormInfo.requiredStreamTimeHours = 24;
    if (questFormInfo.requiredStreamTimeMinutes) {
      questFormInfo.requiredStreamTimeMinutes = parseInt(questFormInfo.requiredStreamTimeMinutes, 10);
    }

    if (questFormInfo.paymentAmount) {
      questFormInfo.paymentAmount = parseInt(questFormInfo.paymentAmount, 10);
    }

    if (questFormInfo.dailyEarning) {
      questFormInfo.dailyEarning = parseInt(questFormInfo.dailyEarning, 10);
    }

    if (questFormInfo.miniFollowers) {
      questFormInfo.miniFollowers = parseInt(questFormInfo.miniFollowers, 10);
    }

    if (questFormInfo.viewerTiers && questFormInfo.viewerTiers.length > 0) {
      for (let i = 0; i < questFormInfo.viewerTiers.length; i += 1) {
        questFormInfo.viewerTiers[i].spotsAvailable = parseInt(questFormInfo.viewerTiers[i].spotsAvailable, 10);
        questFormInfo.viewerTiers[i].minimumAverageViewers = parseInt(questFormInfo.viewerTiers[i].minimumAverageViewers, 10);
        questFormInfo.viewerTiers[i].paymentAmount = parseFloat(questFormInfo.viewerTiers[i].paymentAmount, 10);
      }
    }

    if (questFormInfo.memberTiers && questFormInfo.memberTiers.length > 0) {
      for (let k = 0; k < questFormInfo.memberTiers.length; k += 1) {
        const { identity, paymentAmount } = questFormInfo.memberTiers[k];
        questFormInfo.memberTiers[k] = {
          identity: identity.trim(),
          paymentAmount: parseFloat(paymentAmount, 10),
        };
      }
    }

    if (questFormInfo.splitTiers && questFormInfo.splitTiers.length > 0) {
      //streamDays, streamHours, maxRevenue, prizePool
      for (let i = 0; i < questFormInfo.splitTiers.length; i += 1) {
        if (autoStreamingQuest) {
          questFormInfo.splitTiers[i].streamDays = parseInt(questFormInfo.splitTiers[i].streamDays, 10);
          questFormInfo.splitTiers[i].streamHours = parseInt(questFormInfo.splitTiers[i].streamHours, 10);

        } else if (secondaryCreationQuest) {
          questFormInfo.splitTiers[i].totalUploadDays = parseInt(questFormInfo.splitTiers[i].totalUploadDays, 10);
          questFormInfo.splitTiers[i].validUploadCount = parseInt(questFormInfo.splitTiers[i].validUploadCount, 10);
          questFormInfo.splitTiers[i].totalViews = parseInt(questFormInfo.splitTiers[i].totalViews, 10);
        }
        questFormInfo.splitTiers[i].maxRevenue = parseInt(questFormInfo.splitTiers[i].maxRevenue, 10);
        questFormInfo.splitTiers[i].prizePool = parseInt(questFormInfo.splitTiers[i].prizePool, 10);

      }
    }

    if (questFormInfo.rankTiers && questFormInfo.rankTiers.length > 0) {
      //rankUpper, rankLower, watchTime, prize
      for (let i = 0; i < questFormInfo.rankTiers.length; i += 1) {
        questFormInfo.rankTiers[i].rankUpper = parseInt(questFormInfo.rankTiers[i].rankUpper, 10);
        questFormInfo.rankTiers[i].rankLower = parseInt(questFormInfo.rankTiers[i].rankLower, 10);
        if (autoStreamingQuest) {
          questFormInfo.rankTiers[i].watchTime = parseInt(questFormInfo.rankTiers[i].watchTime, 10);
        } else if (secondaryCreationQuest) {
          questFormInfo.rankTiers[i].minTotalViews = parseInt(questFormInfo.rankTiers[i].minTotalViews, 10);
        }
        questFormInfo.rankTiers[i].prize = parseInt(questFormInfo.rankTiers[i].prize, 10);
      }
    }

    questFormInfo.type = questFormInfo.type || 'one-time';

    if (questFormInfo.type === 'tiered-one-time' || questFormInfo.type === 'timed') {
      questFormInfo.frequency = 'one-time';
    }

    if (questFormInfo.type === 'tiered-multi-days' || questFormInfo.type === 'paid bounty') {
      questFormInfo.frequency = 'daily';
    }

    if (isNewTypeQuest) {
      questFormInfo.requirePreapproval = true;
      questFormInfo.frequency = 'daily';
      //判断时间
      const { valid, messages } = isQuestTimeSetValid(quest, questFormInfo);
      if (!valid) {
        simpleNotification({
          level: 'error',
          title: 'Time Validation Error',
          message: messages.join(' '),
        });
        return; // 阻止提交
      }
    }

    if (autoStreamingQuest) {
      questFormInfo.twitchAccountRequired = true;
    }

    if (secondaryCreationQuest) {
      questFormInfo.youtubeAccountRequired = true;
    }

    questFormInfo.group = (questFormInfo.group) ? questFormInfo.group : '';

    if (questFormInfo.campaigns && questFormInfo.campaigns.length > 0) {
      questFormInfo.campaigns = questFormInfo.campaigns.map(q => q.value);
    }

    if (questFormInfo.requirements) {
      if (questFormInfo.requirements.beforeStream) {
        for (let i = 0; i < questFormInfo.requirements.beforeStream.length; i += 1) {
          const req = questFormInfo.requirements.beforeStream[i];
          delete questFormInfo.requirements.beforeStream[i].value;
          if (!req.customIcon || req.icon === getDefaultIcon(req.type, req.subtype, questFormInformation, game)) {
            questFormInfo.requirements.beforeStream[i].icon = false;
          }

          if (req.type === 'display-hashtags' || req.type === 'display-stream-time' || (req.type === 'display-text' && req.subtype === 'game-name')) {
            questFormInfo.requirements.beforeStream[i].title = '';
          }
        }
      }

      if (questFormInfo.requirements.duringStream) {
        for (let i = 0; i < questFormInfo.requirements.duringStream.length; i += 1) {
          const req = questFormInfo.requirements.duringStream[i];
          delete questFormInfo.requirements.duringStream[i].value;
          if (req.icon === getDefaultIcon(req.type, req.subtype, questFormInformation, game)) {
            questFormInfo.requirements.duringStream[i].icon = false;
          }

          if (req.type === 'display-hashtags' || req.type === 'display-stream-time' || (req.type === 'display-text' && req.subtype === 'game-name')) {
            questFormInfo.requirements.duringStream[i].title = '';
          }
        }
      }

      if (questFormInfo.requirements.afterStream) {
        for (let i = 0; i < questFormInfo.requirements.afterStream.length; i += 1) {
          const req = questFormInfo.requirements.afterStream[i];
          delete questFormInfo.requirements.afterStream[i].value;
          if (req.icon === getDefaultIcon(req.type, req.subtype, questFormInformation, game)) {
            questFormInfo.requirements.afterStream[i].icon = false;
          }

          if (req.type === 'display-hashtags' || req.type === 'display-stream-time' || (req.type === 'display-text' && req.subtype === 'game-name')) {
            questFormInfo.requirements.afterStream[i].title = '';
          }
        }
      }
    }

    if (questFormInfo.countries && questFormInfo.countries.length > 0) {
      questFormInfo.countries = questFormInfo.countries.map(c => c.value);
    }

    if (questFormInfo.languages && questFormInfo.languages.length > 0) {
      questFormInfo.languages = questFormInfo.languages.map(l => l.value);
    }

    if (questFormInfo.platforms) {
      questFormInfo.platforms = [questFormInfo.platforms.value];
    }

    questFormInfo.prevGamesPlayed = prevGamesPlayed.sort();

    onSubmit(questFormInfo);
  }

  swapStep = (kind, index, direction) => {
    const swapIndex = (direction === 'up') ? index - 1 : index + 1;
    const { questForm, swapQuestStep } = this.props;
    let arr;
    if (kind === 'additional-questions') {
      arr = questForm.values.stepsPage1;
    }

    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    swapQuestStep(kind, arr);
  }

  swapRequirement = (pointInQuest, index, direction) => {
    const swapIndex = (direction === 'up') ? index - 1 : index + 1;
    const { questForm, swapRequirements } = this.props;
    const arr = questForm.values.requirements[pointInQuest];
    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    const newRequirements = questForm.values.requirements;
    newRequirements[pointInQuest] = arr;
    swapRequirements(newRequirements);
  }

  uploadBanner = () => {
    this.setState({
      activeModal: 1,
      image: 'banner',
    });
  }

  uploadRegularImage = () => {
    this.setState({
      activeModal: 1,
      image: 'regular',
    });
  }

  uploadOverlayImage = () => {
    this.setState({
      activeModal: 1,
      image: 'overlay',
    });
  }

  uploadIcon = (pointInQuest, index) => {
    this.setState({
      activeModal: 1,
      image: 'icon',
      pointInQuestIcon: pointInQuest,
      indexIcon: index,
    });
  }

  removeIcon = (pointInQuest, index) => {
    this.props.removeIcon({ pointInQuest, index });
  }

  uploadToS3 = (file, preSignedUrl) => {
    const { image } = this.state;
    if ((image === 'banner' || image === 'regular' || image === 'overlay') && file.size > 500000) {
      if (file.size > 500000) {
        simpleNotification({
          level: 'warning',
          title: 'Warning',
          message: 'Image has a limit of 500kb.',
        });
        this.setState({
          activeModal: 1,
        });
        return;
      }
    }
    this.setState({ activeModal: 2 });
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.addEventListener('load', this.handleLoad);
    xhr.send(file);
  }

  getTabClasses = (tab) => {
    const { questForm } = this.props;
    if (!questForm) return '';

    const { syncErrors, submitFailed } = questForm;
    if (!syncErrors || !submitFailed) return '';

    const errorClass = 'fa fa-times-circle red';

    if (tab === 'details') {
      if (
        syncErrors.type
        || syncErrors.title
        || syncErrors.status
        || syncErrors.isPrivate
        || syncErrors.openSpots
        || syncErrors.startDateTime
        || syncErrors.endDateTime
        || syncErrors.openRegistrationDate
        || syncErrors.closeRegistrationDate
        || syncErrors.expectedPayoutDate
        || syncErrors.group
        || syncErrors.minimumAverageViewers
        || syncErrors.requiredBanner
        || syncErrors.bannerLink
      ) {
        return errorClass;
      }
    }

    if (tab === 'parameters') {
      if (
        syncErrors.isReferral
        || syncErrors.referralBonus
        || syncErrors.salesForBonus
        || syncErrors.bonusValue
        || syncErrors.referralBonusType
        || syncErrors.referralBonusTimes
        || syncErrors.comingSoon
        || syncErrors.keyRequired
        || syncErrors.userCanleave
        || syncErrors.plus18
        || syncErrors.tags
        || syncErrors.paymentMade
        || syncErrors.hideFrontpage
        || syncErrors.hideOpenSpots
        || syncErrors.requirePreapproval
        || syncErrors.twitterAccountRequired
        || syncErrors.joinButtonText
        || syncErrors.linkout
        || syncErrors.referralPaymentType
        || syncErrors.commissionAmount
        || syncErrors.commissionPercentage
        || syncErrors.minimumAverageViewersReferral
        || syncErrors.requiredStreamTimeMinutes
        || syncErrors.questTags
        || syncErrors.dailyEarning
        || syncErrors.paymentAmount
        || syncErrors.memberTiers
        || syncErrors.viewerTiers
        || syncErrors.splitTiers
        || syncErrors.rankTiers
        || syncErrors.whitelist
        || syncErrors.platforms
        || syncErrors.countries
        || syncErrors.languages
        || syncErrors.miniFollowers
      ) {
        return errorClass;
      }
    }

    if (tab === 'user-info') {
      if (
        syncErrors.slug
        || syncErrors.stepsPage1
        || syncErrors.requirements
      ) {
        return errorClass;
      }
    }

    if (tab === 'faq') {
      if (
        syncErrors.faqs
      ) {
        return errorClass;
      }
    }

    if (tab === 'questDescription') {
      if (
        syncErrors.questDescriptions
      ) {
        return errorClass;
      }
    }

    // Sub-tabs
    if ((
      tab === 'viewerTiers'
      || tab === 'memberTiers'
      || tab === 'whitelist'
      || tab === 'slug'
      || tab === 'stepsPage1'
      || tab === 'requirements'
      || tab === 'splitTiers'
      || tab === 'rankTiers'
    ) && syncErrors[tab]) {
      return errorClass;
    }

    return '';
  }

  campaignsOptions = () => this.props.campaigns.map(({ id, name }) => ({
    value: id,
    label: <span style={{ zIndex: '1000' }}>{name}</span>,
  }))

  countriesOptions = () => [{
    value: 'Anywhere',
    label: <span style={{ zIndex: '1000' }}>Anywhere</span>,
  }, ...CountryRegionData.map(c => ({
    value: c[0],
    label: <span style={{ zIndex: '1000' }}>{c[0]}</span>,
  }))]

  languagesOptions = () => {
    const langs = languages.getAllLanguageCode().reduce((acc, langCode) => {
      const landDesc = languages.getLanguageInfo(langCode);
      acc[landDesc.name] = langCode;
      return acc;
    }, {});

    const final = Object.keys(langs).sort().map(name => ({
      value: langs[name],
      label: <span style={{ zIndex: '1000' }}>{name}</span>,
    }));

    return [{ value: 'any', label: <span style={{ zIndex: '1000' }}>Any Language</span> }, ...final];
  }

  handleLoad = (event) => {
    const { preSignedUrl: { data: signedUrl }, updateCCQuestForm } = this.props;
    const { image, pointInQuestIcon, indexIcon } = this.state;

    if (event.target.status !== 200) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: 'error',
        title: 'Error',
        message: 'Error uploading the image, try again please.',
      });
      return;
    }

    simpleNotification({
      level: 'success',
      title: 'Success',
      message: 'The image was uploaded succesfully!',
    });

    if (image === 'banner') {
      updateCCQuestForm({ requiredBanner: signedUrl.url });
    } else if (image === 'regular') {
      updateCCQuestForm({ regularImage: signedUrl.url });
    } else if (image === 'overlay') {
      updateCCQuestForm({ overlayImage: signedUrl.url });
    } else if (image === 'icon') {
      updateCCQuestForm({ icon: signedUrl.url, pointInQuest: pointInQuestIcon, index: indexIcon });
    }

    this.setState({ activeModal: 0 });
  }

  addRequirement(fields, req) {
    this.toggleAddRequirementModal();
    const { questForm, game, quest = {} } = this.props;

    // Get preload requirement stuff
    // 确保传递有效的 quest 对象
    const safeQuest = quest || {};
    const preloadedReq = preloadRequirement(req.type, req.subtype, questForm.values, game, safeQuest);

    fields.push({
      type: req.type,
      subtype: req.subtype,
      title: preloadedReq.title,
      description: preloadedReq.description || '',
      label: preloadedReq.label,
      placeholder: preloadedReq.placeholder,
      tooltip: preloadedReq.tooltip,
      approvalRequired: preloadedReq.approvalRequired,
      id: uuidv4().replace(/-/g, ''),
      icon: preloadedReq.icon,
      customIcon: false,
      value: req.value,
    });

    return fields;
  }

  getStepReadOnly = (req) => {
    if (['display-hashtags', 'display-stream-time'].includes(req.type)) {
      return true;
    } else if (req.type === 'display-text' && req.subtype === 'game-name') {
      return true;
    }
    return false;
  }

  renderStepsGeneric = ({
    fields,
    meta: { error, submitFailed },
    kind,
  }) => (
    <section className="Step">
      <ul>
        {fields.map((step, index) => (
          <li key={index}>
            <section className="Step__header">
              <button
                className="Step__item-remove"
                type="button"
                onClick={
                  () => {
                    if (window.confirm('Are you sure about removing this field?')) {
                      fields.remove(index);
                    }
                  }
                }
              >
                <i className="fa fa-trash" />
              </button>
            </section>
            <section className="Step__body">
              <section className="Step__fields">
                <div className="Step__row Step__row--2">
                  <Field
                    name={`${step}.title`}
                    id={`${step}.title`}
                    type="text"
                    component={CustomInput}
                    label="Title"
                    placeholder="Title"
                    customLabel="Title"
                  />
                  <Field
                    name={`${step}.isRequired`}
                    id={`${step}.isRequired`}
                    type="checkbox"
                    placeholder="Is Required"
                    customLabel="Is Required"
                    component={CustomInputCheckbox}
                    value
                  />
                </div>
                <Field
                  type="text"
                  name={`${step}.description`}
                  id={`${step}.description`}
                  placeholder="Additional Information Description"
                  customLabel="Description"
                  component={CustomInput}
                // handleChange={content => this.handleRichTextUpdates(content, `${step}.description`)}
                />
                {/* <RichText
                  initialValue={fieldValues[index].description}
                  callback={content => this.handleRichTextUpdates(content, textEditorQueryString)}
                /> */}
              </section>
              <section className="Step__arrows">
                <div className="Step__arrow--up" style={{ display: (index !== 0) ? 'block' : 'none' }} onClick={this.swapStep.bind(this, kind, index, 'up')} aria-hidden>
                  <i className="fa fa-arrow-up" />
                </div>
                <div className="Step__arrow--down" style={{ display: (fields.length - 1 !== index) ? 'block' : 'none' }} onClick={this.swapStep.bind(this, kind, index, 'down')} aria-hidden>
                  <i className="fa fa-arrow-down" />
                </div>
              </section>
            </section>
          </li>
        ))}
      </ul>
      {(submitFailed && error) && <span className="Step__error">{error}</span>}
      <button
        className="Step__add-item"
        type="button"
        onClick={() => fields.push({
          description: null,
          isRequired: true,
          title: null,
          type: 'collect-user-information',
        })}
      >
        Add Additional Information
      </button>
    </section>
  );

  /* START REQUIREMENTS */
  renderRequirements = ({
    fields,
    meta: { error, submitFailed },
    stepTypeOptions,
    pointInQuest,
  }) => {
    const fieldValues = fields.getAll();
    const { game, questForm } = this.props;
    return (
      <section className="Step">
        <ul>
          {fields.map((step, index) => (
            <li key={index}>
              <section className="Step__header">
                <button
                  className="Step__item-remove"
                  type="button"
                  id={pointInQuest}
                  onClick={() => {
                    fields.remove(index);
                  }}
                >
                  Remove requirement
                </button>
                <h4 className="Step__title">Requirement #{index + 1}</h4>
              </section>
              <section className="Step__body">
                <section className="Step__fields">
                  <div className="Step__row">
                    <div className="input-container" style={{ width: '20%' }}>
                      <label>Requirement Type</label>
                      <span style={{ fontSize: '16px', marginTop: '8px' }}>{stepTypeOptions.find(o => o.type === fieldValues[index].type && o.subtype === fieldValues[index].subtype)?.label}</span>
                    </div>
                    <div className="input-container" style={{ width: '75%' }}>
                      <Field
                        name={`${step}.title`}
                        id={`${step}.title`}
                        type="text"
                        component={CustomInput}
                        label="Title"
                        placeholder="Title"
                        readOnly={this.getStepReadOnly(fields.get(index))}
                        customLabel="Title"
                      />
                    </div>
                    <div style={{ display: (questForm.values && questForm.values.requirementError === true) ? 'block' : 'none' }} />
                  </div>
                  <div className="Step__row">
                    <div className="input-container" style={{ width: '25%' }}>
                      <label>Icon</label>
                      {['display-download'].includes(fieldValues[index].type) ? (
                        <img alt="requirement-icon" style={{ objectFit: 'cover', width: '80px', height: '80px' }} src={getDefaultIcon(fieldValues[index].type, fieldValues[index].subtype, questForm.values, game)} />
                      ) : (
                        <div
                          className="QuestEdit__upload"
                          onClick={this.uploadIcon.bind(this, pointInQuest, index)}
                          style={{ cursor: 'pointer' }}
                          aria-hidden
                        >
                          <i className="fa fa-close" style={{ color: 'white', position: 'absolute', marginLeft: '70px' }} aria-hidden />
                          <img alt="requirement-icon" style={{ objectFit: 'contain', width: '80px', height: '80px' }} src={fieldValues[index].icon && fieldValues[index].customIcon ? fieldValues[index].icon : getDefaultIcon(fieldValues[index].type, fieldValues[index].subtype, questForm.values, game)} />
                        </div>
                      )}
                    </div>
                    <div className="input-container" style={{ width: '75%' }}>
                      <label>Description</label>
                      <RichText
                        callback={content => this.handleRichTextUpdates(content, `requirements.${pointInQuest}[${index}].description`)}
                        initialValue={fieldValues[index].description}

                      />
                    </div>
                  </div>
                  {(fieldValues[index].type.includes('submit')) ? (
                    <div className="Step__row" style={{ width: '75%', marginLeft: '25%' }}>
                      <div className="input-container" style={{ width: '45%' }}>
                        <Field
                          name={`${step}.label`}
                          id={`${step}.label`}
                          type="text"
                          component={CustomInput}
                          label="Label"
                          placeholder="Label"
                          customLabel="Label"
                        />
                      </div>
                      <div className="input-container" style={{ width: '45%' }}>
                        <Field
                          name={`${step}.placeholder`}
                          id={`${step}.placeholder`}
                          type="text"
                          component={CustomInput}
                          label="Placeholder"
                          placeholder="Placeholder"
                          customLabel="Placeholder"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="Step__row" style={{ width: '75%', marginLeft: '25%', marginBottom: '10px' }}>
                    <div className="input-container" >
                      <label>Tooltip</label>
                      <RichText
                        callback={content => this.handleRichTextUpdates(content, `requirements.${pointInQuest}[${index}].tooltip`)}
                        initialValue={fieldValues[index].tooltip}
                      />
                    </div>
                  </div>
                  <div className="Step__row" style={{ width: '75%', marginLeft: '25%' }}>
                    <div className="input-container" style={{ width: '45%' }}>
                      <Field
                        name={`${step}.showOnApp`}
                        id={`${step}.showOnApp`}
                        component={CustomInputCheckbox}
                        customLabel="Show on Application Page"
                      />
                    </div>
                    <div className="input-container" style={{ width: '45%', display: (questForm.values && questForm.values.requirements[pointInQuest][index].showOnApp ? 'block' : 'none') }}>
                      <Field
                        name={`${step}.appPageTitle`}
                        id={`${step}.appPageTitle`}
                        component={CustomInput}
                        type="text"
                        label="Application Page Title"
                        customLabel="Application Page Title"
                        placeholder="Application Page Title"
                      />
                    </div>
                  </div>
                  {/* <div className="Step__row" style={{ width: '75%', marginLeft: '25%' }}>
                    <div className="input-container" style={{ width: '45%' }}>
                      <Field
                        name={`${step}.optional`}
                        id={`${step}.optional`}
                        component={CustomInputCheckbox}
                        customLabel="Make Optional Requirement"
                      />
                    </div>
                  </div> */}
                </section>
                <section className="Step__arrows">
                  <div className="Step__arrow--up" style={{ display: (index !== 0) ? 'block' : 'none' }} onClick={this.swapRequirement.bind(this, pointInQuest, index, 'up')} aria-hidden>
                    <i className="fa fa-arrow-up" />
                  </div>
                  <div className="Step__arrow--down" style={{ display: (fields.length - 1 !== index) ? 'block' : 'none' }} onClick={this.swapRequirement.bind(this, pointInQuest, index, 'down')} aria-hidden>
                    <i className="fa fa-arrow-down" />
                  </div>
                </section>
              </section>
            </li>
          ))}
        </ul>
        {(submitFailed && error) && <span className="Step__error">{error}</span>}
        <button
          className="Step__add-item"
          type="button"
          onClick={this.toggleAddRequirementModal.bind(this, pointInQuest)}
        >
          Add Requirement
        </button>
        {(this.state.addRequirementModal && pointInQuest === this.state.pointInQuest) ? (
          <section className="ModalMaster_requirements">
            <div onClick={this.toggleAddRequirementModal.bind(this, false)} className="Modal__layer" aria-hidden />
            <section className="Modal__wrapper">
              <img alt="close-button" className="close" src={closeButton} onClick={this.toggleAddRequirementModal.bind(this, false)} />
              <h2> Add New Requirement </h2>
              <div className="reqContainer">
                {stepTypeOptions.map((req) => {
                  let inactive = false;
                  let tooltip = false;
                  if (req.type === 'display-download') {
                    if (req.subtype === 'banner' && !questForm.values.requiredBanner) {
                      inactive = true;
                      tooltip = 'You must upload banner artwork in the \'Details\' section for this requirement to become available.';
                    } else if (req.subtype === 'overlay' && !questForm.values.overlayImage) {
                      inactive = true;
                      tooltip = 'You must upload overlay artwork in the \'Details\' section for this requirement to become available.';
                    }
                  } else if (req.type === 'display-hashtags') {
                    if (req.subtype === 'tracking' && (!questForm.values.trackingTags || questForm.values.trackingTags.length === 0)) {
                      inactive = true;
                      tooltip = 'You must add tracking hashtags in the \'Parameters\' section for this requirement to become available.';
                    } else if (req.subtype === 'quest' && (!questForm.values.questTags || questForm.values.questTags.length === 0)) {
                      inactive = true;
                      tooltip = 'You must add quest hashtags in the \'Parameters\' section for this requirement to become available.';
                    }
                  }

                  const inactiveClass = classNames({ inactive });
                  if (tooltip) {
                    return (
                      <Tooltip title={tooltip} placement="top">
                        <div className="reqSelect" key={req.value} onClick={!inactive ? (() => { fields = this.addRequirement(fields, req); }) : null}>
                          <div className="icon">
                            {this.getRequirementComponent(req, inactiveClass)}
                          </div>
                          <span className={inactiveClass}>{req.label}</span>
                        </div>
                      </Tooltip>
                    );
                  }
                  return (
                    <div className="reqSelect" key={req.value} onClick={!inactive ? (() => fields = this.addRequirement(fields, req)) : null}>
                      <div className="icon">
                        {this.getRequirementComponent(req, inactiveClass)}
                      </div>
                      <span className={inactiveClass}>{req.label}</span>
                    </div>
                  );
                })}
              </div>
            </section>
          </section>
        ) : (null)}
      </section>
    );
  };

  getRequirementComponent = (req, inactiveClass) => {
    switch (req.value) {
      case 'display-download-banner':
        return (<AdminBannerIcon className={inactiveClass} />);
      case 'display-text-checkbox':
        return (<AdminCheckboxIcon className={inactiveClass} />);
      case 'display-text-build-stream':
        return (<AdminBuildStreamIcon className={inactiveClass} />);
      case 'display-text-game-name':
        return (<AdminGameDirectoryIcon className={inactiveClass} />);
      case 'distribute-key':
        return (<AdminGameKeyIcon className={inactiveClass} />);
      case 'display-hashtags-quest':
        return (<AdminHashtagIcon className={inactiveClass} />);
      case 'display-hashtags-tracking':
        return (<AdminHashtagIcon className={inactiveClass} />);
      case 'submit-link-clip':
        return (<AdminClipIcon className={inactiveClass} />);
      case 'submit-link-vod':
        return (<AdminClipIcon className={inactiveClass} />);
      case 'display-download-overlay':
        return (<AdminBannerIcon className={inactiveClass} />);
      case 'display-text-achievement':
        return (<AdminAchievementIcon className={inactiveClass} />);
      case 'submit-tweet':
        return (<AdminTwitterIcon className={inactiveClass} />);
      case 'distribute-link-campaign':
        return (<AdminLinkIcon className={inactiveClass} />);
      case 'distribute-link-affiliate':
        return (<AdminLinkIcon className={inactiveClass} />);
      case 'display-stream-time':
        return (<AdminStreamIcon className={inactiveClass} />);
      case 'display-text':
        return (<AdminTextIcon className={inactiveClass} />);
      case 'submit-text':
      case 'submit-text-optional':
        return (<AdminVerificationIcon className={inactiveClass} />);
      default:
        return (<AdminVerificationIcon className={inactiveClass} />);
    }
  }
  /* END REQUIREMENTS */

  handleRichTextUpdates = (content, formValueString) => {
    this.props.dispatch(change('questForm', formValueString, content));
  }

  handleTypeChange = (value, form) => {
    const { questForm } = this.props;
    if (value === 'tiered-one-time' || value === 'timed') {
      // form.change('frequency', 'one-time');
    } else if (value === 'tiered-multi-days' || value === 'paid bounty' || value === 'tiered-auto' || value === 'secondary-creation') {
      // form.change('frequency', 'daily');
    }

    // 清空所有 tiers 相关数据 - 无论切换到什么类型都清空
    // 1. 清空 memberTiers
    if (questForm?.values?.memberTiers?.length > 0) {
      this.props.change('memberTiers', []);
    }

    // 2. 清空 viewerTiers
    if (questForm?.values?.viewerTiers?.length > 0) {
      this.props.change('viewerTiers', []);
    }

    // 3. 清空 rankTiers
    if (questForm?.values?.rankTiers?.length > 0) {
      this.props.change('rankTiers', []);
    }

    // 4. 清空 splitTiers
    if (questForm?.values?.splitTiers?.length > 0) {
      this.props.change('splitTiers', []);
    }

    if (value === 'tiered-auto' || value === 'secondary-creation') {
      // form.change('twitchAccountRequired', true);
      // form.change('requirePreapproval', true);
    }

    if (value === 'tiered-auto') {
      // form.change('requirePreapproval', true);
    }
  }

  handleStartChange = (value) => {
    const { isNewTypeQuest } = this.getQuestTypeFlags();
    console.log('handleStartChange 收到值:', value);

    if (isNewTypeQuest) {
      // 不需要进行时区转换，因为在 CustomDateTimePicker 组件中已经处理过
      this.props.change('openRegistrationDate', value);
    }
  }

  // 当结束时间改变时，将 closeRegistrationDate 更新为相同的值，并将 expectedPayoutDate 设置为结束时间加24小时
  handleEndChange = (value) => {
    const { isNewTypeQuest } = this.getQuestTypeFlags();
    console.log('handleEndChange 收到值:', value);

    if (isNewTypeQuest) {
      // 同步设置 Close Registration Date 为 endDateTime
      this.props.change('closeRegistrationDate', value);

      // 计算预计支付日期为 endDateTime 加24小时
      // 使用 moment 处理 ISO 字符串，确保在 UTC 时区进行运算
      const expectedPayout = moment.utc(value)
        .add(24, 'hours')
        .toISOString();

      console.log('计算的预计支付日期:', expectedPayout);
      this.props.change('expectedPayoutDate', expectedPayout);
    }
  };

  renderLogs = () => {
    const { data: adminActivityLogs } = this.props.adminActivityLogs;
    if (!adminActivityLogs || adminActivityLogs.length <= 0) {
      return (<React.Fragment>There are no logs to show.</React.Fragment>);
    }

    return adminActivityLogs.map((log) => {
      let differences = differenceObject(log.newDataRef, log.oldDataRef);

      /* START - STEPS */
      const newSteps = [];
      const oldSteps = [];
      if (log.newDataRef && log.newDataRef.steps) {
        log.newDataRef.steps.forEach((st) => {
          newSteps.push(omit(st, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      if (log.oldDataRef && log.oldDataRef.steps) {
        log.oldDataRef.steps.forEach((st) => {
          oldSteps.push(omit(st, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      const stepsChanged = !isEqual(newSteps, oldSteps);
      /* END - STEPS */
      // < Blocks -
      const newBlocks = [];
      const oldBlocks = [];
      if (log.newDataRef && log.newDataRef.blocks) {
        log.newDataRef.blocks.forEach((st) => {
          newBlocks.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.blocks) {
        log.oldDataRef.blocks.forEach((st) => {
          oldBlocks.push(st);
        });
      }
      const blocksChanged = !isEqual(newBlocks, oldBlocks);
      // - Blocks >
      // < Steps Page1 -
      const newStepsPage1 = [];
      const oldStepsPage1 = [];
      if (log.newDataRef && log.newDataRef.stepsPage1) {
        log.newDataRef.stepsPage1.forEach((st) => {
          newStepsPage1.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage1) {
        log.oldDataRef.stepsPage1.forEach((st) => {
          oldStepsPage1.push(st);
        });
      }
      const stepsPage1Changed = !isEqual(newStepsPage1, oldStepsPage1);
      // - Steps Page1 >
      // < Steps Page2 -
      const newStepsPage2 = [];
      const oldStepsPage2 = [];
      if (log.newDataRef && log.newDataRef.stepsPage2) {
        log.newDataRef.stepsPage2.forEach((st) => {
          newStepsPage2.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage2) {
        log.oldDataRef.stepsPage2.forEach((st) => {
          oldStepsPage2.push(st);
        });
      }
      const stepsPage2Changed = !isEqual(newStepsPage2, oldStepsPage2);
      // - Steps Page2 >
      // < Steps Page3 -
      const newStepsPage3 = [];
      const oldStepsPage3 = [];
      if (log.newDataRef && log.newDataRef.stepsPage3) {
        log.newDataRef.stepsPage3.forEach((st) => {
          newStepsPage3.push(omit(st, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage3) {
        log.oldDataRef.stepsPage3.forEach((st) => {
          oldStepsPage3.push(omit(st, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      const stepsPage3Changed = !isEqual(newStepsPage3, oldStepsPage3);
      // - Steps Page3 >

      // < Requirements -
      const newRequirements = { beforeStream: [], duringStream: [], afterStream: [] };
      const oldRequirements = { beforeStream: [], duringStream: [], afterStream: [] };
      if (log.newDataRef && log.newDataRef.requirements) {
        let reqs = log.newDataRef.requirements.beforeStream;
        reqs && reqs.forEach((st) => {
          newRequirements.beforeStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });

        reqs = log.newDataRef.requirements.duringStream;
        reqs && reqs.forEach((st) => {
          newRequirements.duringStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });

        reqs = log.newDataRef.requirements.afterStream;
        reqs && reqs.forEach((st) => {
          newRequirements.afterStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });
      }

      if (log.oldDataRef && log.oldDataRef.requirements) {
        let reqs = log.oldDataRef.requirements.beforeStream;
        reqs && reqs.forEach((st) => {
          oldRequirements.beforeStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });

        reqs = log.oldDataRef.requirements.duringStream;
        reqs && reqs.forEach((st) => {
          oldRequirements.duringStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });

        reqs = log.oldDataRef.requirements.afterStream;
        reqs && reqs.forEach((st) => {
          oldRequirements.afterStream.push(omit(st, ['id', 'createdAt', 'updatedAt']));
        });
      }

      const requirementsChanged = !isEqual(newRequirements, oldRequirements);
      // - Requirements >
      /* START - WHITELIST */
      const newWhitelist = [];
      const oldWhitelist = [];
      if (log.newDataRef && log.newDataRef.whitelist) {
        log.newDataRef.whitelist.forEach((wl) => {
          newWhitelist.push(omit(wl, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      if (log.oldDataRef && log.oldDataRef.whitelist) {
        log.oldDataRef.whitelist.forEach((wl) => {
          oldWhitelist.push(omit(wl, ['id', 'createdAt', 'updatedAt', 'quest']));
        });
      }
      const whitelistChanged = !isEqual(newWhitelist, oldWhitelist);
      /* END - WHITELIST */
      /* START - FAQS */
      const newFaqs = [];
      const oldFaqs = [];
      if (log.newDataRef && log.newDataRef.faqs) {
        log.newDataRef.faqs.forEach((fa) => {
          newFaqs.push(omit(fa, ['question', 'answer']));
        });
      }
      if (log.oldDataRef && log.oldDataRef.faqs) {
        log.oldDataRef.faqs.forEach((fa) => {
          oldFaqs.push(omit(fa, ['question', 'answer']));
        });
      }
      const faqChanged = !isEqual(newFaqs, oldFaqs);
      /* END - FAQS */
      /* START - QUESTDESCRIPTIONS */
      const newQuestDescription = [];
      const oldQuestDescription = [];
      if (log.newDataRef && log.newDataRef.questDescriptions) {
        log.newDataRef.questDescriptions.forEach((fa) => {
          newQuestDescription.push(omit(fa, ['question', 'answer']));
        });
      }
      if (log.oldDataRef && log.oldDataRef.questDescriptions) {
        log.oldDataRef.questDescriptions.forEach((fa) => {
          oldQuestDescription.push(omit(fa, ['question', 'answer']));
        });
      }
      const questDescriptionsChanged = !isEqual(newQuestDescription, oldQuestDescription);
      /* END - QUESTDESCRIPTIONS */
      // < Tags
      const newTags = [];
      const oldTags = [];
      if (log.newDataRef && log.newDataRef.tags) {
        log.newDataRef.tags.forEach((tg) => {
          newTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.tags) {
        log.oldDataRef.tags.forEach((tg) => {
          oldTags.push(tg);
        });
      }
      const tagsChanged = !isEqual(newTags, oldTags);
      // Tags >
      // < trackingTags
      const newTrackingTags = [];
      const oldTrackingTags = [];
      if (log.newDataRef && log.newDataRef.trackingTags) {
        log.newDataRef.trackingTags.forEach((tg) => {
          newTrackingTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.trackingTags) {
        log.oldDataRef.trackingTags.forEach((tg) => {
          oldTrackingTags.push(tg);
        });
      }
      const trackingTagsChanged = !isEqual(newTrackingTags, oldTrackingTags);
      // trackingTags >
      // < Viewer Tiers
      const newViewerTiers = [];
      const oldViewerTiers = [];
      if (log.newDataRef && log.newDataRef.viewerTiers) {
        log.newDataRef.viewerTiers.forEach((vt) => {
          newViewerTiers.push(vt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.viewerTiers) {
        log.oldDataRef.viewerTiers.forEach((vt) => {
          oldViewerTiers.push(vt);
        });
      }
      const viewerTiersChanged = !isEqual(newViewerTiers, oldViewerTiers);
      // Viewer Tiers >
      // < Member Tiers
      const newMemberTiers = [];
      const oldMemberTiers = [];
      if (log.newDataRef && log.newDataRef.memberTiers) {
        log.newDataRef.memberTiers.forEach((mt) => {
          newMemberTiers.push(mt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.memberTiers) {
        log.oldDataRef.memberTiers.forEach((mt) => {
          oldMemberTiers.push(mt);
        });
      }
      const memberTiersChanged = !isEqual(newMemberTiers, oldMemberTiers);
      // Member Tiers >
      // < Quest Tags -
      const newQuestTags = [];
      const oldQuestTags = [];
      if (log.newDataRef && log.newDataRef.questTags) {
        log.newDataRef.questTags.forEach((tg) => {
          newQuestTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.questTags) {
        log.oldDataRef.questTags.forEach((tg) => {
          oldQuestTags.push(tg);
        });
      }

      const questTagsChanged = !isEqual(newQuestTags, oldQuestTags);
      // - Quest Tags >
      // < Split Tiers
      const newSplitTiers = [];
      const oldSplitTiers = [];
      if (log.newDataRef && log.newDataRef.splitTiers) {
        log.newDataRef.splitTiers.forEach((vt) => {
          newSplitTiers.push(vt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.splitTiers) {
        log.oldDataRef.splitTiers.forEach((vt) => {
          oldSplitTiers.push(vt);
        });
      }
      const splitTiersChanged = !isEqual(newSplitTiers, oldSplitTiers);
      // Split Tiers >
      // < Rank Tiers
      const newRankTiers = [];
      const oldRankTiers = [];
      if (log.newDataRef && log.newDataRef.rankTiers) {
        log.newDataRef.rankTiers.forEach((vt) => {
          newRankTiers.push(vt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.rankTiers) {
        log.oldDataRef.rankTiers.forEach((vt) => {
          oldRankTiers.push(vt);
        });
      }
      const rankTiersChanged = !isEqual(newRankTiers, oldRankTiers);
      // Rank Tiers >
      differences = omit(differences, [
        'updatedAt',
        'steps',
        'faqs',
        'whitelist',
        'tags',
        'viewerTiers',
        'memberTiers',
        'stepsLanding',
        'stepsPage1',
        'stepsPage2',
        'stepsPage3',
        'blocks',
        'questTags',
        'trackingTags',
        'promoLinks',
        'completionRequirements',
        'requirements',
        'splitTiers',
        'rankTiers',
        'questDescriptions',
      ]);
      const objKeys = keys(differences);

      return (
        /* QFLog -> Quest Form Log */
        <div className="QFLog" key={log.id}>
          <div className="QFLog_top">
            {log.user.username} - {moment(log.createdAt).format('MMMM DD, YYYY - hh:mm a')}
          </div>
          <div className="QFLog_bot">
            {objKeys.map((oKey) => {
              let oKeyOld = <i>Empty</i>;
              if (log.oldDataRef[oKey]) {
                oKeyOld = log.oldDataRef[oKey];
              } else if (log.oldDataRef[oKey] === '') {
                oKeyOld = '';
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (typeof log.oldDataRef[oKey] === 'undefined') {
                oKeyOld = <i>Undefined</i>;
              }

              let oKeyNew = <i>Empty</i>;
              if (log.newDataRef[oKey]) {
                oKeyNew = log.newDataRef[oKey];
              } else if (log.newDataRef[oKey] === '') {
                oKeyNew = '';
              } else if (log.newDataRef[oKey] === false) {
                oKeyNew = false;
              } else if (typeof log.newDataRef[oKey] === 'undefined') {
                oKeyNew = <i>Undefined</i>;
              }

              if (oKey === 'emailFailure') {
                if (log.newDataRef[oKey] && log.newDataRef[oKey].shouldSend !== undefined) {
                  oKeyNew = `"Send Failure Email" set to ${log.newDataRef[oKey].shouldSend}`;
                }

                if (log.oldDataRef[oKey] && log.oldDataRef[oKey].shouldSend !== undefined) {
                  oKeyOld = `"Send Failure Email" set to ${log.oldDataRef[oKey].shouldSend}`;
                }
              }

              if (oKey === 'emailReminder') {
                if (log.newDataRef[oKey] && log.newDataRef[oKey].shouldSend !== undefined) {
                  oKeyNew = `"Send Reminder Email" set to ${log.newDataRef[oKey].shouldSend}`;
                }

                if (log.oldDataRef[oKey] && log.oldDataRef[oKey].shouldSend !== undefined) {
                  oKeyOld = `"Send Reminder Email" set to ${log.oldDataRef[oKey].shouldSend}`;
                }
              }

              return (
                <div key={oKey} className="QFLog_bot_key">
                  <React.Fragment>
                    {oKey}:
                    &nbsp;
                    <span className="old">&nbsp;{oKeyOld?.toString() || oKeyOld}&nbsp;</span>
                    &nbsp;-&nbsp;
                    <span className="new">&nbsp;{oKeyNew?.toString() || oKeyNew}&nbsp;</span>
                  </React.Fragment>
                </div>
              );
            })}
            {stepsChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'steps',
                      olds: oldSteps,
                      news: newSteps,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Steps changed
                </div>
                <br />
              </React.Fragment>
            }
            {faqChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'faqs',
                      olds: oldFaqs,
                      news: newFaqs,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> FAQs changed
                </div>
                <br />
              </React.Fragment>
            }
            {questDescriptionsChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'questDescriptions',
                      olds: oldQuestDescription,
                      news: newQuestDescription,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Quest description changed
                </div>
                <br />
              </React.Fragment>
            }
            {whitelistChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'whitelist',
                      olds: oldWhitelist,
                      news: newWhitelist,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Whitelist changed
                </div>
                <br />
              </React.Fragment>
            }
            {tagsChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'tags',
                      olds: oldTags,
                      news: newTags,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Tags changed
                </div>
                <br />
              </React.Fragment>
            }
            {trackingTagsChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'trackingTags',
                      olds: oldTrackingTags,
                      news: newTrackingTags,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Tracking Tags changed
                </div>
                <br />
              </React.Fragment>
            }
            {viewerTiersChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'viewerTiers',
                      olds: oldViewerTiers,
                      news: newViewerTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Viewer tiers changed
                </div>
                <br />
              </React.Fragment>
            }
            {memberTiersChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'memberTiers',
                      olds: oldMemberTiers,
                      news: newMemberTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Member tiers changed
                </div>
                <br />
              </React.Fragment>
            }
            {splitTiersChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'splitTiers',
                      olds: oldSplitTiers,
                      news: newSplitTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Split tiers changed
                </div>
                <br />
              </React.Fragment>
            }
            {rankTiersChanged &&
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: 'rankTiers',
                      olds: oldRankTiers,
                      news: newRankTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> Rank tiers changed
                </div>
                <br />
              </React.Fragment>
            }
            {blocksChanged &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'blocks',
                    olds: oldBlocks,
                    news: newBlocks,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> Blocks changed
              </div>
            }
            {stepsPage1Changed &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'stepsPage1',
                    olds: oldStepsPage1,
                    news: newStepsPage1,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> User Information (Page 1) changed
              </div>
            }
            {stepsPage2Changed &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'stepsPage2',
                    olds: oldStepsPage2,
                    news: newStepsPage2,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> User Information (Page 2) changed
              </div>
            }
            {stepsPage3Changed &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'stepsPage3',
                    olds: oldStepsPage3,
                    news: newStepsPage3,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> User Information (Page 3) changed
              </div>
            }
            {questTagsChanged &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'questTags',
                    olds: oldQuestTags,
                    news: newQuestTags,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> Quest Tags changed
              </div>
            }
            {requirementsChanged &&
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: 'requirements',
                    olds: oldRequirements,
                    news: newRequirements,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> Requirements Changed
              </div>
            }
            {(objKeys.length === 0
              && !stepsChanged
              && !faqChanged
              && !whitelistChanged
              && !tagsChanged
              && !viewerTiersChanged
              && !memberTiersChanged
              && !splitTiersChanged
              && !rankTiersChanged
              && !blocksChanged
              && !stepsPage1Changed
              && !stepsPage2Changed
              && !stepsPage3Changed
              && !questTagsChanged
              && !trackingTagsChanged
              && !requirementsChanged
              && !questDescriptionsChanged
            ) && <div className="QFLog_bot_key">No changes (pressed 'Save' without updating content)</div>}
            {(this.state.logsModal === 1) && this.renderLogsModal()}
          </div>
        </div>
      );
    });
  }

  renderLogsModal = () => {
    const { modalType, olds, news } = this.state;
    let typeTitle = '';
    if (modalType === 'steps') {
      typeTitle = 'Steps';
    } else if (modalType === 'faqs') {
      typeTitle = 'FAQs';
    } else if (modalType === 'questDescriptions') {
      typeTitle = 'Quest Descriptions';
    } else if (modalType === 'whitelist') {
      typeTitle = 'Whitelist';
    } else if (modalType === 'tags') {
      typeTitle = 'Tags';
    } else if (modalType === 'viewerTiers') {
      typeTitle = 'Viewer Tiers';
    } else if (modalType === 'memberTiers') {
      typeTitle = 'Member Tiers';
    } else if (modalType === 'blocks') {
      typeTitle = 'Blocks';
    } else if (modalType === 'stepsPage1') {
      typeTitle = 'User Information (Page 1)';
    } else if (modalType === 'stepsPage2') {
      typeTitle = 'User Information (Page 2)';
    } else if (modalType === 'stepsPage3') {
      typeTitle = 'User Information (Page 3)';
    } else if (modalType === 'questTags') {
      typeTitle = 'Quest Tags';
    } else if (modalType === 'requirements') {
      typeTitle = 'Requirements';
    } else if (modalType === 'splitTiers') {
      typeTitle = 'Split Tiers';
    } else if (modalType === 'rankTiers') {
      typeTitle = 'Rank Tiers';
    }

    return (
      <section className="ModalQuestLogs">
        <section className="ModalMaster">
          <section className="Modal Modal__ModalQuestLogs active">
            <div
              className="Modal__layer"
              onClick={() => { this.setState({ logsModal: 0 }); }}
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={() => { this.setState({ logsModal: 0 }); }}
                aria-hidden
              />
              <div className="ModalQuestLogs__body">
                <div className="ModalQuestLogs__olds">
                  <div className="ModalQuestLogs__olds__title">
                    Old {typeTitle}
                  </div>
                  <div className="ModalQuestLogs__olds__row">
                    <JSONPretty data={olds} />
                  </div>
                </div>
                <div className="ModalQuestLogs__news">
                  <div className="ModalQuestLogs__news__title">
                    New {typeTitle}
                  </div>
                  <div className="ModalQuestLogs__news__row">
                    <JSONPretty data={news} />
                  </div>
                </div>
              </div>
              <div className="ModalQuestLogs__footer">
                <div className="ModalQuestLogs__footer__button">
                  <div
                    className="ModalQuestLogs__footer__buttonA"
                    onClick={() => { this.setState({ logsModal: 0 }); }}
                    aria-hidden
                  >
                    Close
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }

  renderCopyUrl = index => (
    <React.Fragment>
      <Field
        type="text"
        copyUrl
        readOnly
        name="regularImage"
        id="regularImage"
        placeholder="Image URL"
        customLabel={(
          <label>
            Generate Image URL&nbsp;&nbsp;
            <div
              className="QuestEdit__upload"
              onClick={this.uploadRegularImage.bind(this)}
              aria-hidden
            >
              (Upload)
            </div>
            &nbsp;&nbsp;
            <div
              className="QuestEdit__upload"
              onClick={() => {
                document.getElementsByClassName('copyUrl')[index].select();
                document.execCommand('copy');
              }}
              aria-hidden
            >
              Copy
            </div>
            &nbsp;&nbsp;
            <i className="fa fa-info-circle" data-tip="Upload your image, copy it, then use the 'Insert' dropdown above to insert the image into the page." />
          </label>
        )}
        component={CustomInput}
      />
      {/* <ReactTooltip effect="solid" place="top" multiline html /> */}
    </React.Fragment>
  );

  sendToRequirementsPreview = (quest) => {
    const host = window.location.hostname;
    const isLocal = host === 'localhost';
    const finalHost = isLocal ? `${host}:3000` : host;
    const protocol = isLocal ? 'http://' : 'https://';
    const { id } = quest;
    window.open(`${protocol}${finalHost}/my-quests/preview/${id}`, '_blank');
  }

  toggleAddRequirementModal(pointInQuest) {
    this.setState({
      addRequirementModal: !this.state.addRequirementModal,
      pointInQuest,
    });
  }


  renderFollowers = ({ fields, meta: { error } }) => {

    return (
      <section className="WhiteListWrapper">
        <div className="followers-requirement">
          <div className="input-group">
            <Field
              name="miniFollowers"
              id="miniFollowers"
              type="text"
              component={CustomInput}
              placeholder="Enter Minimum Follower Requirement"
              onlyNumbers="true"
              customLabel={
                (
                  <label className="requirement-label">
                    Minimum Follower Requirement
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        This is set mini followers for Applicants.
                      </div>
                    </span>
                  </label>
                )
              }
            />
          </div>
        </div>
      </section>
    );
  };

  renderRankMode = ({ fields, meta: { error } }) => {
    const { questForm = {}, quest } = this.props;
    const isNew = !quest;

    let questStarted = false;
    if (questForm.values && questForm.values.startDateTime && quest) {
      questStarted = isNewTypeQuestStart(quest);;
    }
    const {
      autoStreamingQuest,
      secondaryCreationQuest,
    } = this.getQuestTypeFlags();
    if ((!questForm?.values?.splitTiers || questForm?.values?.splitTiers.length === 0)) {
      if (fields.length === 0) {
        let nextRankUpper = 1;

        if (autoStreamingQuest) {
          // 自动结算任务使用这组参数
          fields.push({
            prize: 0,
            watchTime: 0,
            rankLower: nextRankUpper,
            rankUpper: nextRankUpper,
          });
        } else if (secondaryCreationQuest) {
          // 二次创作任务使用另一组参数，与validate中的对应
          fields.push({
            prize: 0,
            minTotalViews: 0,
            rankLower: nextRankUpper,
            rankUpper: nextRankUpper,
          });
        }
      }

      // 设置标志，防止重复初始化
      // this.setState({ rankTiersInitialized: true });
    }

    const handleAddNewTier = () => {
      const rankTiers = questForm?.values?.rankTiers;
      let nextRankUpper = 1;

      if (rankTiers && rankTiers.length > 0) {
        const lastTier = rankTiers[rankTiers.length - 1];
        nextRankUpper = parseInt(lastTier.rankLower) + 1 || 1;
      }

      if (autoStreamingQuest) {
        // 自动结算任务使用这组参数
        fields.push({
          prize: 0,
          watchTime: 0,
          rankLower: nextRankUpper,
          rankUpper: nextRankUpper,
        });
      } else if (secondaryCreationQuest) {
        // 二次创作任务使用另一组参数，与validate中的对应
        fields.push({
          prize: 0,
          minTotalViews: 0,
          rankLower: nextRankUpper,
          rankUpper: nextRankUpper,
        });
      }
    };

    return (
      <section className="WhiteListWrapper">
        <TableContainer>
          <Table>
            {autoStreamingQuest && questForm?.values?.rankTiers?.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Rank Upper &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Rank Lower &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Watch Time(hours) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Prize($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  {(!questStarted || isNew) && (questForm?.values?.splitTiers?.length > 0 || questForm?.values?.rankTiers?.length > 1) && (
                    <TableCell align="center">
                      Action &nbsp;
                      <Tooltip title="You can delete this tier." placement="top">
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            {secondaryCreationQuest && questForm?.values?.rankTiers?.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Rank Upper &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Rank Lower &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Min Total Views&nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Prize($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  {(!questStarted || isNew) && (questForm?.values?.splitTiers?.length > 0 || questForm?.values?.rankTiers?.length > 1) && (
                    <TableCell align="center">
                      Action &nbsp;
                      <Tooltip title="You can delete this tier." placement="top">
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {fields.map((item, index) => (
                <TableRow key={index}>
                  {autoStreamingQuest && (
                    <>
                      <TableCell>
                        <Field
                          name={`${item}.rankUpper`}
                          id={`${item}.rankUpper`}
                          type="text"
                          component={CustomInput}
                          placeholder="Rank Upper"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.rankLower`}
                          id={`${item}.rankLower`}
                          type="text"
                          component={CustomInput}
                          placeholder="Rank Lower"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.watchTime`}
                          id={`${item}.watchTime`}
                          type="text"
                          component={CustomInput}
                          placeholder="Watch Time(hours)"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.prize`}
                          id={`${item}.prize`}
                          type="text"
                          component={CustomInput}
                          placeholder="Prize"
                          onlyNumbers="true"
                        />
                      </TableCell>
                    </>
                  )}

                  {secondaryCreationQuest && (
                    <>
                      <TableCell>
                        <Field
                          name={`${item}.rankUpper`}
                          id={`${item}.rankUpper`}
                          type="text"
                          component={CustomInput}
                          placeholder="Rank Upper"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.rankLower`}
                          id={`${item}.rankLower`}
                          type="text"
                          component={CustomInput}
                          placeholder="Rank Lower"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.minTotalViews`}
                          id={`${item}.minTotalViews`}
                          type="text"
                          component={CustomInput}
                          placeholder="MIn Total Views"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.prize`}
                          id={`${item}.prize`}
                          type="text"
                          component={CustomInput}
                          placeholder="Prize"
                          onlyNumbers="true"
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    {(!questStarted || isNew) && (questForm?.values?.splitTiers?.length > 0 || questForm?.values?.rankTiers?.length > 1) && (
                      <div className="RepeaterWrapper__item__new">
                        <button
                          className="RepeaterWrapper__item-remove-new"
                          type="button"
                          onClick={() => {
                            if (window.confirm('Are you sure you want to remove this tier?')) {
                              fields.remove(index);
                            }
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button className="RepeaterWrapper__add-item" type="button" onClick={handleAddNewTier}>
          Add New Tier
        </button>
      </section>
    );
  };


  renderSplitMode = ({ fields, meta: { error } }) => {
    const { questForm = {}, quest } = this.props;
    const isNew = !quest || !quest.id;
    let questStarted = false;
    if (questForm.values && questForm.values.startDateTime && quest) {
      questStarted = isNewTypeQuestStart(quest);
    }

    const {
      autoStreamingQuest,
      secondaryCreationQuest,
    } = this.getQuestTypeFlags();

    if (!questForm?.values?.rankTiers || questForm?.values?.rankTiers?.length === 0) {
      if (fields.length === 0) {
        if (autoStreamingQuest) {
          // 自动结算任务使用这组参数
          fields.push({
            streamDays: 0,
            streamHours: 0,
            maxRevenue: 0,
            prizePool: 0,
          });
        } else if (secondaryCreationQuest) {
          // 二次创作任务使用另一组参数，与validate中的对应
          fields.push({
            totalUploadDays: 0,
            validUploadCount: 0,
            totalViews: 0,
            maxRevenue: 0,
            prizePool: 0,
          });
        }
      }
    }

    const handleAddSplitNewTier = () => {
      if (autoStreamingQuest) {
        // 自动结算任务使用这组参数
        fields.push({
          streamDays: 0,
          streamHours: 0,
          maxRevenue: 0,
          prizePool: 0,
        });
      } else if (secondaryCreationQuest) {
        // 二次创作任务使用另一组参数，与validate中的对应
        fields.push({
          totalUploadDays: 0,
          validUploadCount: 0,
          totalViews: 0,
          maxRevenue: 0,
          prizePool: 0,
        });
      }
    };

    return (
      <section className="WhiteListWrapper">
        <TableContainer>
          <Table>
            {autoStreamingQuest && questForm?.values?.splitTiers?.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Stream Days &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Stream Hours &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Max Revenue($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Prize Pool($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  {(!questStarted || isNew) && (questForm?.values?.rankTiers?.length > 0 || questForm?.values?.splitTiers?.length > 1) && (
                    <TableCell align="center">
                      Action &nbsp;
                      <Tooltip title="You can delete this tier." placement="top">
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}

            {secondaryCreationQuest && questForm?.values?.splitTiers?.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Total Upload Days &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Valid Upload Count &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 9999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Total Views &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Max Revenue($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    Prize Pool($) &nbsp;
                    <Tooltip title="Must Above 0, Range From 1 To 99999999." placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  {(!questStarted || isNew) && (questForm?.values?.rankTiers?.length > 0 || questForm?.values?.splitTiers?.length > 1) && (
                    <TableCell align="center">
                      Action &nbsp;
                      <Tooltip title="You can delete this tier." placement="top">
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {fields.map((item, index) => (
                <TableRow key={index}>
                  {autoStreamingQuest && (
                    <>
                      <TableCell>
                        <Field
                          name={`${item}.streamDays`}
                          id={`${item}.streamDays`}
                          type="text"
                          component={CustomInput}
                          placeholder="Stream Days"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.streamHours`}
                          id={`${item}.streamHours`}
                          type="text"
                          component={CustomInput}
                          placeholder="Stream Hours"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.maxRevenue`}
                          id={`${item}.maxRevenue`}
                          type="text"
                          component={CustomInput}
                          placeholder="Max Revenue"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.prizePool`}
                          id={`${item}.prizePool`}
                          type="text"
                          component={CustomInput}
                          placeholder="Prize Pool"
                          onlyNumbers="true"
                        />
                      </TableCell>
                    </>
                  )}

                  {secondaryCreationQuest && (
                    <>
                      <TableCell>
                        <Field
                          name={`${item}.totalUploadDays`}
                          id={`${item}.totalUploadDays`}
                          type="text"
                          component={CustomInput}
                          placeholder="Total Upload Days"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.validUploadCount`}
                          id={`${item}.validUploadCount`}
                          type="text"
                          component={CustomInput}
                          placeholder="Valid Upload Count"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.totalViews`}
                          id={`${item}.totalViews`}
                          type="text"
                          component={CustomInput}
                          placeholder="Total Views"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.maxRevenue`}
                          id={`${item}.maxRevenue`}
                          type="text"
                          component={CustomInput}
                          placeholder="Max Revenue"
                          onlyNumbers="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${item}.prizePool`}
                          id={`${item}.prizePool`}
                          type="text"
                          component={CustomInput}
                          placeholder="Prize Pool"
                          onlyNumbers="true"
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    {(!questStarted || isNew) && (questForm?.values?.rankTiers?.length > 0 || questForm?.values?.splitTiers?.length > 1) && (
                      <div className="RepeaterWrapper__item__new">
                        <button
                          className="RepeaterWrapper__item-remove-new"
                          type="button"
                          onClick={() => {
                            if (window.confirm('Are you sure you want to remove this tier?')) {
                              fields.remove(index);
                            }
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button className="RepeaterWrapper__add-item" type="button" onClick={handleAddSplitNewTier}>
          Add New Tier
        </button>
      </section>
    );
  };

  renderWhiteList = ({ fields, meta: { error }, ...custom }) => {
    const whiteListTypeOptions = [{ label: 'Twitch Username', value: 'twitch-username' }];
    const { questForm } = this.props;
    const { parametersSubTab } = this.state;

    return (
      <section className="WhiteListWrapper" style={(parametersSubTab !== 2 ? { display: 'none' } : {})}>
        {/* <section className="WhiteListWrapper__header">
          <h3 className="WhiteListWrapper__header--title">Whitelisted Users (Must use Twitch Username)</h3>
        </section> */}
        <ul>
          {fields.map((whiteListItem, index) => (
            <li key={index}>
              <section className="RepeaterWrapper__fields">
                <div className="item-number">{index}</div>
                <Field
                  name={`${whiteListItem}.type`}
                  id={`${whiteListItem}.type`}
                  placeholder="Select type"
                  customLabel="Type of Whitelisting"
                  component={CustomDropDownArray}
                  options={whiteListTypeOptions}
                />
                <Field
                  name={`${whiteListItem}.identity`}
                  id={`${whiteListItem}.identity`}
                  type="text"
                  component={CustomInput}
                  label="Username"
                  placeholder="Username"
                  customLabel="Username"
                />
                <button
                  className="RepeaterWrapper__item-remove"
                  type="button"
                  onClick={() => {
                    if (window.confirm(`Are you sure about removing ${questForm.values.whitelist[index].identity} from this whitelist?`)) {
                      fields.remove(index);
                    }
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </section>
            </li>
          ))}
        </ul>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button className="RepeaterWrapper__add-item" type="button" onClick={() => fields.push({})}>
          Add User to Whitelist
        </button>
      </section>
    );
  };

  renderViewerTiers = ({ fields, meta: { error } }) => {
    const { questForm = {} } = this.props;
    const { parametersSubTab } = this.state;

    let questStarted = false;
    if (questForm.values && questForm.values.startDateTime) {
      questStarted = moment(questForm.values.startDateTime).diff(moment(Date.now())) < 0;
    }

    const streamingQuest = (questForm.values && (questForm.values.type === 'tiered' || questForm.values.type === 'tiered-one-time' || questForm.values.type === 'tiered-multi-days'));

    return (
      <section className="WhiteListWrapper" style={(parametersSubTab !== 0 ? { display: 'none' } : {})}>
        <TableContainer>
          <Table>
            {questForm?.values?.viewerTiers?.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell align="center">Spots Available</TableCell>
                  {streamingQuest && <TableCell align="center">Min. Avg. Viewers</TableCell>}
                  <TableCell align="center">
                    $ Amount &nbsp;
                    <Tooltip title='Put "0" for no message. Put "-1" for free key. Put "-2" for points.' placement="top">
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">Noiz Points</TableCell>
                  <TableCell align="center">Tier Goal</TableCell>
                  <TableCell align="center">Enable Goal</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>)
            }
            <TableBody>
              {fields.map((viewerTierItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.spotsAvailable`}
                      id={`${viewerTierItem}.spotsAvailable`}
                      type="text"
                      component={CustomInput}
                      placeholder="Spots Available"
                      onlyNumbers="true"
                    />
                  </TableCell>
                  {streamingQuest && <TableCell>
                    <Field
                      name={`${viewerTierItem}.minimumAverageViewers`}
                      id={`${viewerTierItem}.minimumAverageViewers`}
                      type="text"
                      component={CustomInput}
                      placeholder="Minimum Average Viewers"
                      onlyNumbers="true"
                    />
                  </TableCell>}
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.paymentAmount`}
                      id={`${viewerTierItem}.paymentAmount`}
                      type="text"
                      component={CustomInput}
                      placeholder="Payment Amount"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.noizPoints`}
                      id={`${viewerTierItem}.noizPoints`}
                      type="text"
                      component={CustomInput}
                      placeholder="ARKade Ticket"
                      onlyNumbers="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.goal`}
                      id={`${viewerTierItem}.goal`}
                      type="text"
                      component={CustomInput}
                      placeholder="Goal Description"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Field
                      name={`${viewerTierItem}.goalEnabled`}
                      id={`${viewerTierItem}.goalEnabled`}
                      type="checkbox"
                      component={CustomInputCheckbox}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {!questStarted &&
                      <button
                        className="RepeaterWrapper__item-remove"
                        type="button"
                        onClick={() => {
                          if (window.confirm('Are you sure you want to remove this tier?')) {
                            fields.remove(index);
                          }
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        {
          (streamingQuest || (!streamingQuest && !questForm?.values?.viewerTiers?.length)) &&
          <button className="RepeaterWrapper__add-item" type="button" onClick={() => fields.push({ minimumAverageViewers: 0 })}>
            Add {!streamingQuest ? 'A Payment Amount' : 'Another Viewer Tier'}
          </button>
        }
      </section>
    );
  };

  memberTierRemoveRender = (mt, fn) => {
    if (mt.status) {
      return (
        <Tooltip title={`This Member has a status of ${mt.status}`} placement="top">
          <div>
            <IconButton disabled>
              <i className="fa fa-trash" />
            </IconButton>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Remove this member tier" placement="top">
        <IconButton onClick={fn}>
          <i className="fa fa-trash" />
        </IconButton>
      </Tooltip>
    );
  };

  renderMemberTiers = ({ fields, meta: { error } }) => {
    const { questForm = {} } = this.props;
    const { parametersSubTab } = this.state;

    return (
      <section className="WhiteListWrapper" style={(parametersSubTab !== 1 ? { display: 'none' } : {})}>
        <TableContainer>
          <Table>
            {(questForm.values && questForm.values.memberTiers && questForm.values.memberTiers.length > 0) &&
              <TableHead>
                <TableRow>
                  <TableCell align="center">Twitch Username</TableCell>
                  <TableCell align="center">Payment Amount</TableCell>
                  <TableCell align="center">Goal Description</TableCell>
                  <TableCell align="center">Enable Goal</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
            }
            <TableBody>
              {fields.map((memberTierItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.identity`}
                      id={`${memberTierItem}.identity`}
                      type="text"
                      component={CustomInput}
                      placeholder="Twitch username"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.paymentAmount`}
                      id={`${memberTierItem}.paymentAmount`}
                      type="text"
                      component={CustomInput}
                      placeholder="Payment Amount"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.goal`}
                      id={`${memberTierItem}.goal`}
                      type="text"
                      component={CustomInput}
                      placeholder="Goal Description"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Field
                      name={`${memberTierItem}.goalEnabled`}
                      id={`${memberTierItem}.goalEnabled`}
                      type="checkbox"
                      component={CustomInputCheckbox}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {this.memberTierRemoveRender(fields.get(index), () => {
                      if (window.confirm('Are you sure you want to remove this member tier?')) {
                        fields.remove(index);
                      }
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button className="RepeaterWrapper__add-item" type="button" onClick={() => fields.push({})}>
          Add Another Member Tier
        </button>
      </section>
    );
  };

  renderRules = ({ fields, meta: { error }, ...custom }) => {
    const { configRules, questForm } = this.props;

    const ruleOptions = configRules.map(rule => ({
      label: rule.name,
      value: rule.id,
    }));

    return (
      <section className="RepeaterWrapper Rules">
        <ul>
          {fields.map((rule, index) => {
            let conditioningFactorAllowed = false;
            if (questForm.values &&
              questForm.values.steps &&
              questForm.values.steps.length > 0 &&
              questForm.values.steps[custom.stepIndex].rules &&
              questForm.values.steps[custom.stepIndex].rules.length > 0) {
              const theRule = find(configRules, myRule => myRule.id === questForm.values.steps[custom.stepIndex].rules[index].id);

              conditioningFactorAllowed = (isObject(theRule)) ? theRule.conditioningFactorAllowed : false;
            } else {
              conditioningFactorAllowed = false;
            }

            return (
              <li key={index}>
                <section className="RepeaterWrapper__header">
                  <button className="RepeaterWrapper__item-remove" type="button" onClick={() => fields.remove(index)}>
                    <i className="fa fa-trash" />
                  </button>
                  <h4 className="RepeaterWrapper__title">Rule #{index + 1}</h4>
                </section>
                <section className="RepeaterWrapper__fields">
                  <Field
                    name={`${rule}.id`}
                    id={`${rule}.id`}
                    placeholder="Select Type"
                    customLabel="Type"
                    component={CustomDropDownArray}
                    options={ruleOptions}
                  />

                  {(conditioningFactorAllowed) &&
                    (
                      <Field
                        name={`${rule}.conditioningFactor`}
                        id={`${rule}.conditioningFactor`}
                        type="number"
                        component={CustomInput}
                        placeholder="Conditioning Factor"
                        label="Conditioning Factor"
                        customLabel="Conditioning Factor"
                      />
                    )
                  }
                </section>
              </li>
            );
          })}
        </ul>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button className="RepeaterWrapper__add-item" type="button" onClick={() => fields.push({})}>
          Add Rule
        </button>
      </section>
    );
  };

  renderQuestDescriptions = ({ fields }) => {
    const fieldValues = fields.getAll();
    return (
      <div>
        {fields.map((item, index) => (
          <div className="faqs_wrapper">
            <button
              className="remove_faq"
              type="button"
              onClick={
                () => {
                  fields.remove(index);
                  this.questDescriptions = this.questDescriptions.splice(index, 1);
                }
              }
            >
              Remove Description
            </button>
            <Field
              name={`${item}.title`}
              id={`${item}.title`}
              type="text"
              component={CustomInput}
              label="Title"
              placeholder="Title"
              customLabel="Title"
            />
            <div className="input-container">
              <label>Description</label>
              <RichText
                initialValue={fieldValues[index].description}
                callback={content => this.handleRichTextUpdates(content, `questDescriptions[${index}].description`)}
              />
            </div>
          </div>
        ))}
        <button className="add_faq" type="button" onClick={() => fields.push({ title: null, description: null })}>
          Add Quest Description
        </button>
      </div>
    );
  };

  renderFAQs = ({ fields, meta: { error, submitFailed } }) => {
    const fieldValues = fields.getAll();
    return (
      <div>
        {fields.map((step, index) => (
          <div className="faqs_wrapper">
            <button
              className="remove_faq"
              type="button"
              onClick={
                () => {
                  fields.remove(index);
                  this.faqs = this.faqs.splice(index, 1);
                }
              }
            >
              Remove FAQ
            </button>
            <Field
              name={`${step}.title`}
              id={`${step}.title`}
              type="text"
              component={CustomInput}
              label="Question"
              placeholder="Question"
              customLabel="Question"
            />
            <div className="input-container">
              <label>Answer</label>
              <RichText
                initialValue={fieldValues[index].description}
                callback={content => this.handleRichTextUpdates(content, `faqs[${index}].description`)}
              />
            </div>
          </div>
        ))}
        <button className="add_faq" type="button" onClick={() => fields.push({ question: null, answer: null })}>
          Add FAQ
        </button>
      </div>
    );
  };

  titleSlug = (e, title) => {
    const {
      questForm: { fields: { slug } },
      titleSlugApply,
    } = this.props;

    if (!slug || !slug.visited) {
      titleSlugApply(friendlyURICharacters(replaceSpaces(title)));
    }
  }

  platformOptions = () => {
    const { game: { platforms = [] } } = this.props;

    const grouped = platforms.reduce((acc, pl) => {
      if (!acc[pl.baseService]) {
        acc[pl.baseService] = [];
      }
      acc[pl.baseService].push(pl);
      return acc;
    }, {});

    return Object.keys(grouped).map(group => ({
      label: group,
      options: grouped[group].map(({ id, platform }) => ({
        value: id,
        label: <span style={{ zIndex: '1000' }}>{platform}</span>,
      })),
    }));
  }

  getQuestTypeFlags = () => {
    const { questForm = {} } = this.props;
    //判断当前任务是老的视频直播任务
    const streamingQuest = isStreamingQuest(questForm.values);
    //判断当前任务是否是自动结算直播任务
    const autoStreamingQuest = isAutoStreamQuest(questForm.values);
    //判断当前任务是否是二次创作任务
    const secondaryCreationQuest = isSecondaryCreationQuest(questForm.values);
    //判断当前任务是否是新类型任务
    const isNewTypeQuest = autoStreamingQuest || secondaryCreationQuest;
    //判断当前任务是否是非基础任务
    const isStreamOrNewTypeQuest = autoStreamingQuest || secondaryCreationQuest || autoStreamingQuest;
    return { streamingQuest, autoStreamingQuest, secondaryCreationQuest, isNewTypeQuest, isStreamOrNewTypeQuest };
  }

  render() {
    const {
      auth: { token },
      // game: { id: gameId = '' },
      handleSubmit,
      pathname,
      preSignedUrl: { isLoading },
      quest = {},
      questForm = {},
    } = this.props;

    const pathSegs = pathname.split('/');
    const action = pathSegs[pathSegs.length - 1];

    const questTypeOptions = [
      { label: 'Basic Quest', value: 'basic' },
      // { label: 'General', value: 'default' },
      // { label: 'Multi Day', value: 'tiered-multi-days' },
      { label: 'Streaming Quest', value: 'tiered-one-time' },
      { label: 'Streaming Quest(Split&Rank)', value: 'tiered-auto' },
      // { label: 'Secondary Creation', value: 'secondary-creation' }
    ];

    const {
      activeModal,
      disableClick,
      dropzoneActive,
      parametersSubTab,
      parametersAwardMode,
      prevGamesPlayed,
      subTab,
      tab
    } = this.state;

    const questIsAffiliate = questForm.values ? questForm.values.type === 'affiliate' : false;
    const questRequirePreapproval = questForm.values ? questForm.values.requirePreapproval : false;
    const questStepsTypesOptions = this.getStepsTypes(questIsAffiliate, questRequirePreapproval);

    // Check for "Send failure email" functionality
    const emailFailureSentDate = (questForm.values && questForm.values.emailFailure && questForm.values.emailFailure.sentDate) ? moment(questForm.values.emailFailure.sentDate).format('MMMM DD, YYYY - hh:mm a') : false;

    // Check for "Send reminder email" functionality
    const emailReminderSentDate = (questForm.values && questForm.values.emailReminder && questForm.values.emailReminder.sentDate) ? moment(questForm.values.emailReminder.sentDate).format('MMMM DD, YYYY - hh:mm a') : false;

    let questMeetsReminderMin = true;
    if (questForm.values && questForm.values.startDateTime && questForm.values.endDateTime && questForm.values.openRegistrationDate && questForm.values.closeRegistrationDate && questForm.values.expectedPayoutDate) {
      questMeetsReminderMin = !(getQuestDurationHours(questForm.values) <= 48);
      // 移除这些格式转换，因为它们在CustomDateTimePicker组件内部已经正确处理了
      // 这些转换会导致时区问题，因为它们转换为字符串并丢失时区信息
      // questForm.values.startDateTime = moment.utc(questForm.values.startDateTime).local().format('YYYY-MM-DD HH:mm')
      // questForm.values.endDateTime = moment.utc(questForm.values.endDateTime).local().format('YYYY-MM-DD HH:mm')
      // questForm.values.openRegistrationDate = moment.utc(questForm.values.openRegistrationDate).local().format('YYYY-MM-DD HH:mm')
      // questForm.values.closeRegistrationDate = moment.utc(questForm.values.closeRegistrationDate).local().format('YYYY-MM-DD HH:mm')
      // questForm.values.expectedPayoutDate = moment.utc(questForm.values.expectedPayoutDate).local().format('YYYY-MM-DD HH:mm')
      // console.log('questForm.values.startDateTime', questForm.values.startDateTime)
      // console.log('quest.endDateTime', quest.endDateTime)
    }

    const detailsTabClasses = this.getTabClasses('details');
    const parametersTabClasses = this.getTabClasses('parameters');
    const userInfoTabClasses = this.getTabClasses('user-info');
    const faqsTabClasses = this.getTabClasses('faqs');
    const splitTiersTabClasses = this.getTabClasses('splitTiers');
    const rankTiersTabClasses = this.getTabClasses('rankTiers');
    const viewerTiersTabClasses = this.getTabClasses('viewerTiers');
    const memberTiersTabClasses = this.getTabClasses('memberTiers');
    const whitelistTabClasses = this.getTabClasses('whitelist');
    const slugTabClasses = this.getTabClasses('slug');
    const stepsPage1TabClasses = this.getTabClasses('stepsPage1');
    const requirementsTabClasses = this.getTabClasses('requirements');


    // 使用封装好的方法，获得任务类型 flags
    const {
      streamingQuest,
      isNewTypeQuest,
      isStreamOrNewTypeQuest,
    } = this.getQuestTypeFlags();


    return (
      <div>
        <div className="Global__tabs">
          <div className={classNames('Global__tab', { active: tab === 'details' })} onClick={() => this.setState({ tab: 'details' })} aria-hidden>Details&nbsp;<i className={detailsTabClasses} /></div>
          <div className={classNames('Global__tab', { active: tab === 'parameters' })} onClick={() => this.setState({ tab: 'parameters' })} aria-hidden>Parameters&nbsp;<i className={parametersTabClasses} /></div>
          <div className={classNames('Global__tab', { active: tab === 'user-info' })} onClick={() => this.setState({ tab: 'user-info' })} style={{ width: '180px' }} aria-hidden>User Information&nbsp;<i className={userInfoTabClasses} /></div>
          <div className={classNames('Global__tab', { active: tab === 'faq' })} onClick={() => this.setState({ tab: 'faq' })} aria-hidden>FAQ&nbsp;<i className={faqsTabClasses} /></div>
          <div className={classNames('Global__tab', { active: tab === 'logs' })} onClick={() => this.setState({ tab: 'logs' })} aria-hidden>Logs</div>
        </div>
        <form className="form-container quests-edit" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <div className="info-tab-wrap" style={(tab === 'details') ? { display: 'block' } : { display: 'none' }}>
            <div className="form--row">
              <Field
                name="type"
                id="type"
                placeholder="Select Type"
                customLabel="Type"
                component={CustomDropDownArray}
                options={questTypeOptions}
                onChange={(e, v) => this.handleTypeChange(v, questForm)}
              />
              <Field
                type="text"
                name="campaigns"
                id="campaigns"
                placeholder="Campaigns"
                customLabel="Campaigns"
                component={ArrayDropDown}
                styles={arrayDropDownColors}
                isMulti
                isClearable={false}
                optionsToRender={this.campaignsOptions()}
              />
            </div>
            <Field
              type="text"
              name="title"
              id="title"
              placeholder="Quest Title"
              customLabel={
                (
                  <label>
                    Quest Title
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        This is what will show on the Quest Card, Modal page 1, My Quests, etc.
                      </div>
                    </span>
                  </label>
                )
              }
              onChange={this.titleSlug}
              component={CustomInput}
            />
            <Field
              name="status"
              id="status"
              placeholder="Select Status"
              customLabel="Status"
              component={CustomDropDownGeneric}
              options={['active', 'archived', 'disabled']}
            />
            <Field
              name="isPrivate"
              id="isPrivate"
              type="checkbox"
              placeholder="Select Value"
              customLabel="Is Private"
              component={CustomInputCheckbox}
            />
            <Field
              name="hideFrontpage"
              id="hideFrontpage"
              type="checkbox"
              placeholder="Select Value"
              customLabel="Hide In Frontpage"
              tooltip="If checked, this quest will absolutely not show up in the frontpage EVEN if it is private and the user is a member tier. Use this for hidden quests with Viewer Tiers."
              component={CustomInputCheckbox}
            />
            <Field
              type="text"
              name="startDateTime"
              id="startDateTime"
              placeholder="Start Date (Tracking)"
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel="Start Date (Tracking)"
              component={CustomDateTimePicker}
              onChange={(e, v) => this.handleStartChange(v)}
            />
            <Field
              type="text"
              name="endDateTime"
              id="endDateTime"
              placeholder="End Date (Tracking)"
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel="End Date (Tracking)"
              component={CustomDateTimePicker}
              onChange={(e, v) => this.handleEndChange(v)}
            />
            <Field
              type="text"
              name="openRegistrationDate"
              id="openRegistrationDate"
              placeholder="Open Registration Date"
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel="Open Registration Date"
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="closeRegistrationDate"
              id="closeRegistrationDate"
              placeholder="Close Registration Date"
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel="Close Registration Date"
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="expectedPayoutDate"
              id="expectedPayoutDate"
              placeholder={isNewTypeQuest ? "Auto Payout Date" : "Expected Payout Date"}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={isNewTypeQuest ? "Auto Payout Date" : "Expected Payout Date"}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="group"
              id="group"
              placeholder="Group"
              customLabel="Group"
              component={CustomInput}
            />
            {streamingQuest &&
              <Field
                type="text"
                name="requiredBanner"
                id="requiredBanner"
                placeholder="Required Banner"
                customLabel={(
                  <label>
                    Required Banner&nbsp;&nbsp;
                    <div
                      className="QuestEdit__upload"
                      onClick={this.uploadBanner.bind(this)}
                      aria-hidden
                    >
                      (Upload Banner)
                    </div>
                  </label>
                )}
                component={CustomInput}
              />
            }
            {streamingQuest &&
              <Field
                type="text"
                name="bannerLink"
                id="bannerLink"
                placeholder="Banner Link"
                customLabel="Banner Link"
                component={CustomInput}
              />
            }
            {streamingQuest &&
              <Field
                type="text"
                name="overlayImage"
                id="overlayImage"
                placeholder="Required Overlay Image"
                customLabel={(
                  <label>
                    Required Overlay Image&nbsp;&nbsp;
                    <div
                      className="QuestEdit__upload"
                      onClick={this.uploadOverlayImage.bind(this)}
                      aria-hidden
                    >
                      (Upload Overlay Image)
                    </div>
                  </label>
                )}
                component={CustomInput}
              />
            }
          </div>
          <div className="info-tab-wrap" style={(tab === 'parameters') ? { display: 'block' } : { display: 'none' }}>

            {
              !isNewTypeQuest && (
                <Field
                  name="comingSoon"
                  id="comingSoon"
                  type="checkbox"
                  placeholder="Select Value"
                  customLabel="Coming Soon"
                  component={CustomInputCheckbox}
                />
              )
            }

            <Field
              name="platforms"
              id="platforms"
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              customLabel={<label>Platforms</label>}
              optionsToRender={this.platformOptions()}
            />
            <Field
              type="text"
              name="countries"
              id="countries"
              placeholder="Countries"
              customLabel="Countries"
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              isMulti
              isClearable={false}
              optionsToRender={this.countriesOptions()}
            />
            <Field
              type="text"
              name="languages"
              id="languages"
              placeholder="Languages"
              customLabel="Languages"
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              isMulti
              isClearable={false}
              optionsToRender={this.languagesOptions()}
            />
            <RequiredGames
              token={token}
              values={prevGamesPlayed}
              onChange={arr => this.setState({ prevGamesPlayed: arr })}
            />
            <Field
              name="keyRequired"
              id="keyRequired"
              type="checkbox"
              placeholder="Select Value"
              customLabel="Is Key Required?"
              component={CustomInputCheckbox}
            />
            <Field
              name="userCanleave"
              id="userCanLeave"
              type="checkbox"
              placeholder="Select Value"
              customLabel="User Can Leave?"
              component={CustomInputCheckbox}
            />
            <Field
              name="plus18"
              id="plus18"
              type="checkbox"
              placeholder="Select Value"
              customLabel="User must be 18+ to participate"
              component={CustomInputCheckbox}
            />
            <div style={{ display: (questForm.values && questForm.values.youtubeAccountRequired === true) ? 'block' : 'none' }}>
              <Field
                name="tags"
                id="tags"
                type="text"
                placeholder="Select Value"
                customLabel={((
                  <label>
                    Tags
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        All tags must be unique to this quest to prevent one stream counting for multiple quests.
                        Please use only A-Z, a-z, _, and 0-9 for the tags. Tags can't be only numbers.
                      </div>
                    </span>
                  </label>
                ))}
                component={CustomTagField}
              />
            </div>
            {
              !isNewTypeQuest && (
                <Field
                  name="paymentMade"
                  id="paymentMade"
                  type="checkbox"
                  placeholder="Select Value"
                  customLabel="Payment Made"
                  component={CustomInputCheckbox}
                />
              )
            }

            {
              !isNewTypeQuest && (
                <Field
                  name="hideOpenSpots"
                  id="hideOpenSpots"
                  type="checkbox"
                  defaultChecked
                  placeholder="Select Value"
                  customLabel="Hide Open Spots"
                  component={CustomInputCheckbox}
                />
              )
            }

            {
              !isNewTypeQuest && (
                <Field
                  name="requirePreapproval"
                  id="requirePreapproval"
                  type="checkbox"
                  placeholder="Select Value"
                  customLabel="Require Preapproval"
                  component={CustomInputCheckbox}
                />
              )
            }

            {
              !isNewTypeQuest && (
                <Field
                  name="twitchAccountRequired"
                  id="twitchAccountRequired"
                  type="checkbox"
                  placeholder="Select Value"
                  customLabel="Require Connected Twitch Account"
                  component={CustomInputCheckbox}
                />
              )
            }

            <Field
              name="twitterAccountRequired"
              id="twitterAccountRequired"
              type="checkbox"
              placeholder="Select Value"
              customLabel="Require Connected X, formerly known as Twitter, Account"
              component={CustomInputCheckbox}
            />
            {!isNewTypeQuest && streamingQuest &&
              <React.Fragment>
                <div className="input-container stream-time-wrap">
                  <label><b>Required Stream Time</b></label>
                  <div className="stream-time-inner-wrap">
                    <label>
                      <Field
                        type="text"
                        name="requiredStreamTimeMinutes"
                        id="requiredStreamTimeMinutes"
                        component={CustomInput}
                        placeholder="00"
                        onlyNumbers="true"
                      />
                    </label>
                    <span className="stream-label">
                      minutes
                      <span className="tooltip" style={{ display: (questForm.values && questForm.values.type === 'tiered-multi-days') ? 'inline-block' : 'none' }}>
                        <i className="tooltip-icon fa fa-info-circle" />
                        <div className="tooltip-content">
                          Indicates how much time they must stream every day for the multi-day quest.
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </React.Fragment>
            }
            {isStreamOrNewTypeQuest &&
              <Field
                name="questTags"
                id="questTags"
                type="text"
                placeholder="Select Value"
                customLabel={((
                  <label>
                    Quest Tags
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        Please use only A-Z, a-z, _, and 0-9 for the tags. Tags can't be only numbers.
                      </div>
                    </span>
                  </label>
                ))}
                component={CustomTagField}
              />
            }
            {isStreamOrNewTypeQuest &&
              <Field
                name="trackingTags"
                id="trackingTags"
                type="text"
                tracking
                placeholder="Select Value"
                customLabel={((
                  <label>
                    Tracking Quest Tags
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        Checked against stream title.  Be sure to use UNIQUE, quest specific tags to ensure we don't over track.  Have streamers remove tags on completion
                      </div>
                    </span>
                  </label>
                ))}
                component={CustomTagField}
              />
            }

            {
              isNewTypeQuest ? (
                <TiersTaskMode
                  rankTiersTabClasses={rankTiersTabClasses}
                  questType={questForm.values?.type}
                  setSubTab={index => this.setState({ parametersAwardMode: index })}
                  streamingQuest={streamingQuest}
                  subTab={parametersAwardMode}
                  splitTiers={
                    () => <FieldArray
                      name="splitTiers"
                      component={this.renderSplitMode}
                    />
                  }
                  rankTiers={
                    () => <FieldArray
                      name="rankTiers"
                      component={this.renderRankMode}
                    />
                  }
                  splitTiersTabClasses={splitTiersTabClasses}
                  followers={() => <Field name="miniFollowers" component={this.renderFollowers} />}
                />
              ) : (
                <TiersWhitelist
                  memberTiers={() => <FieldArray style={{ ...(parametersSubTab !== 1 ? { display: 'none' } : {}) }} name="memberTiers" component={this.renderMemberTiers} />}
                  memberTiersTabClasses={memberTiersTabClasses}
                  questType={questForm.values?.type}
                  setSubTab={index => this.setState({ parametersSubTab: index })}
                  streamingQuest={streamingQuest}
                  subTab={parametersSubTab}
                  viewerTiers={() => <FieldArray style={{ ...(parametersSubTab !== 0 ? { display: 'none' } : {}) }} name="viewerTiers" component={this.renderViewerTiers} />}
                  viewerTiersTabClasses={viewerTiersTabClasses}
                  whitelist={() => <FieldArray style={{ ...(parametersSubTab !== 2 ? { display: 'none' } : {}) }} name="whitelist" component={this.renderWhiteList} />}
                  whitelistTabClasses={whitelistTabClasses}
                />
              )
            }
          </div>
          <div className="info-tab-wrap" style={(tab === 'user-info') ? { display: 'block' } : { display: 'none' }}>
            <div className="Global__subTabs">
              <div className={classNames('Global__subTab', { active: subTab === 'landing' })} onClick={() => this.setState({ subTab: 'landing' })} aria-hidden>
                Landing&nbsp;<i className={slugTabClasses} />
              </div>
              <div className={classNames('Global__subTab', { active: subTab === 'emails' })} onClick={() => this.setState({ subTab: 'emails' })} aria-hidden>
                Emails
              </div>
              <div className={classNames('Global__subTab', { active: subTab === 'additional-questions' })} onClick={() => this.setState({ subTab: 'additional-questions' })} aria-hidden>
                Additional Questions&nbsp;<i className={stepsPage1TabClasses} />
              </div>
              <div className={classNames('Global__subTab', { active: subTab === 'requirements' })} onClick={() => this.setState({ subTab: 'requirements' })} aria-hidden>
                Requirements&nbsp;<i className={requirementsTabClasses} />
              </div>
            </div>
            <div className={classNames('QuestForm__userInfo', { active: subTab === 'landing' })}>
              <div ref={(el) => { this.slugE = el; }} />
              <Field
                type="text"
                name="slug"
                id="slug"
                placeholder="Slug"
                customLabel="Slug"
                component={CustomInput}
                normalize={slug => friendlyURICharacters(replaceSpaces(slug))}
              />
              {questForm.values &&
                <label className="Label__URL">URL: {window.location.origin}/quests/{questForm.values.slug}</label>
              }
              <div className="input-container main_desc">
                <label>Description</label>
                {this.props.questForm && this.props.questForm.values &&
                  <RichText
                    initialValue={this.props.questForm.values.description}
                    callback={content => this.handleRichTextUpdates(content, 'description')}
                    config={this.rte_default_config_landing}
                  />
                }
              </div>
            </div>
            <div className={classNames('QuestForm__userInfo', { active: subTab === 'emails' })}>
              <section className="WhiteListWrapper__header">
                <h3 className="WhiteListWrapper__header--title">
                  Approval Email
                </h3>
              </section>
              <div className="input-container main_desc">
                <label>
                  Reminders & Other Info
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      This will appear in the automated quest approval email (alongside existing date, payment, and requirement info). If left blank, default text will be used.
                    </div>
                  </span>
                </label>
                {questForm.values &&
                  <RichText
                    callback={content => this.handleRichTextUpdates(content, 'emailAdditionalInfo')}
                    initialValue={questForm.values.emailAdditionalInfo}
                  />
                }
              </div>
              <section className="WhiteListWrapper__header">
                <h3 className="WhiteListWrapper__header--title">
                  Failure Email
                </h3>
              </section>
              <div className="input-container main_desc">
                <label>
                  Send Failure Email
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      If checked, all users in this quest who are missing requirements or stream time will be sent a quest failure email 24 hours after the quest ends.
                    </div>
                  </span>
                </label>
                {emailFailureSentDate ? (
                  <span style={{ fontStyle: 'italic' }}> Automated failure emails were sent on {emailFailureSentDate} </span>
                ) : (
                  <Field
                    name="emailFailure.shouldSend"
                    id="emailFailure.shouldSend"
                    type="checkbox"
                    placeholder="Select Value"
                    component={CustomInputCheckbox}
                  />
                )}
              </div>
              <div className="input-container main_desc">
                <label>
                  Send Reminder Email
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      If checked, all users in this quest who are missing requirements or stream time will be sent a quest reminder email 48 hours before the quest ends.
                    </div>
                  </span>
                </label>
                {emailReminderSentDate ? (
                  <span style={{ fontStyle: 'italic' }}> Automated reminder emails were sent on {emailReminderSentDate} </span>
                ) : !questMeetsReminderMin ? (
                  <span style={{ fontStyle: 'italic' }}> Reminder emails do not send for quests with a duration of less than 48 hours.</span>
                ) : (
                  <Field
                    name="emailReminder.shouldSend"
                    id="emailReminder.shouldSend"
                    type="checkbox"
                    placeholder="Select Value"
                    component={CustomInputCheckbox}
                  />
                )}
              </div>
            </div>
            <div className={classNames('QuestForm__userInfo', { active: subTab === 'additional-questions' })}>
              <FieldArray
                name="stepsPage1"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderStepsGeneric}
                kind="additional-questions"
              />
              {/* {this.renderCopyUrl(1)} */}
            </div>
            <div className={classNames('QuestForm__userInfo', { active: subTab === 'requirements' })}>
              <h2> Before the Stream </h2>
              <FieldArray
                name="requirements.beforeStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="beforeStream"
              />
              <h2> During the Stream </h2>
              <FieldArray
                name="requirements.duringStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="duringStream"
              />
              <h2> After the Stream </h2>
              <FieldArray
                name="requirements.afterStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="afterStream"
              />
            </div>
          </div>
          <div className="info-tab-wrap" style={(tab === 'faq') ? { display: 'block' } : { display: 'none' }}>
            <FieldArray name="faqs" component={this.renderFAQs} />
          </div>
          {/* <div className="info-tab-wrap" style={(tab === 'questDescription') ? { display: 'block' } : { display: 'none' }}>
            <FieldArray name="questDescriptions" component={this.renderQuestDescriptions} />
          </div> */}
          <div className="info-tab-wrap" style={(tab === 'logs') ? { display: 'block' } : { display: 'none' }}>
            {this.renderLogs()}
          </div>
          {(tab !== 'logs') &&
            <div className="form__footer">
              <input type="submit" className="CCQuests__save--btn" value="Save" />
              {action !== 'create' &&
                <>
                  {/* <input type="button" onClick={() => this.getQuest(quest)} className="CCQuests__preview--btn" value="Preview Popup Modal" /> */}
                  <input
                    type="button"
                    onClick={() => {
                      const win = window.open(`/quests/${questForm.values.slug}`, '_blank');
                      win.focus();
                    }}
                    className="CCQuests__preview-slug--btn"
                    value="Preview Quest Page"
                  />
                  <input data-tip="Visual reference only - do not interact with page" type="button" onClick={() => this.sendToRequirementsPreview(quest)} className="CCQuests__preview-req--btn" value="Preview Requirements Page" />
                </>
              }
            </div>
          }
        </form>
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__upload Modal__upload__one', { active: activeModal === 1 })}>
            <div onClick={this.changeModalState.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              {isLoading && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
              {!isLoading &&
                <React.Fragment>
                  <div className={classNames('Modal__upload__border', { active: dropzoneActive })} />
                  <div
                    className="Modal__close-btn"
                    style={{ display: (dropzoneActive) ? 'none' : 'block' }}
                    onClick={this.changeModalState.bind(this, 0)}
                    role="button"
                    aria-hidden
                  />
                  <Dropzone
                    ref={(node) => { this.dropzoneRef = node; }}
                    disableClick={disableClick}
                    className="Modal__upload__content"
                    onDrop={(e, event) => this.onDrop(event, e)}
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragLeave={this.onDragLeave.bind(this)}
                    disablePreview={false}
                    multiple={false}
                    accept={['.jpg', '.png']}
                    inputProps={{ id: 'file' }}
                  >
                    <div className="Modal__upload__image" />
                    <div className="Modal__upload__title">
                      Drag and drop the image here
                    </div>
                    <div className="Modal__upload__or">
                      Or
                    </div>
                    <div className="Modal__upload__action">
                      <input type="file" name="file" id="file" className="inputFile" onChange={this.handleSelectedFile} />
                      <button className="cta__button">Choose a file</button>
                    </div>
                    <div className="Modal__upload__or">
                      Mandatory Size: Less than 500kb <br />
                      Recommended Dimensions: 320px (wide) x 320px or less (high)
                    </div>
                  </Dropzone>
                </React.Fragment>
              }
            </section>
          </section>
          <section className={classNames('Modal Modal__upload Modal__upload__two', { active: activeModal === 2 })}>
            <div onClick={this.changeModalState.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div className="Modal__upload__border" />
              <div onClick={this.changeModalState.bind(this, 0)} className="Modal__close-btn" role="button" aria-hidden />
              <section className="Modal__upload__content">
                <div className="Modal__upload__image" />
                <div className="Modal__upload__title">
                  Uploading the image
                </div>
                <div className="Modal__upload__loading">
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              </section>
            </section>
          </section>
          <section className={classNames('Modal Modal__upload Modal__upload__three', { active: activeModal === 3 })}>
            <div onClick={this.changeModalState.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div onClick={this.changeModalState.bind(this, 0)} className="Modal__close-btn" role="button" aria-hidden />
              <section className="Modal__upload__content">
                <section className="Modal__upload__avatar">
                  <div className="Modal__upload__avatar--container" aria-hidden />
                </section>
                <div className="Modal__upload__title2">
                  Upload completed
                </div>
                <div onClick={this.changeModalState.bind(this, 0)} className="Modal__upload__button" aria-hidden>
                  Change Image
                </div>
              </section>
            </section>
          </section>
        </section>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  // 使用封装好的方法，获得任务类型 flags
  const {
    streamingQuest,
    autoStreamingQuest,
    secondaryCreationQuest,
    isNewTypeQuest,
    // isStreamOrNewTypeQuest,
  } = getQuestTypeFlagsFromValues(values);

  if (streamingQuest && !isBoolean(values.twitchAccountRequired)) {
    errors.twitchAccountRequired = 'This field is required';
  }

  if (streamingQuest && !values.twitchAccountRequired && values.requirePreapproval) {
    errors.twitchAccountRequired = 'Twitch account required for preapproval quests';
  }

  if (isNewTypeQuest && (!values.miniFollowers || values.miniFollowers === '')) {
    errors.miniFollowers = 'This field is required';
  } else if (isNewTypeQuest && values.miniFollowers <= 0) {
    errors.miniFollowers = 'This field must be above 0';
  }

  if (!values.type || values.type.trim() === '') {
    errors.type = 'This field is required';
  }

  if (!values.title || values.title.trim() === '') {
    errors.title = 'This field is required';
  }

  if (!values.status || values.status.trim() === '') {
    errors.status = 'This field is required';
  }

  if (!isBoolean(values.isPrivate)) {
    errors.isPrivate = 'This field is required';
  }

  console.log('验证时间字段:', {
    startDateTime: values.startDateTime,
    endDateTime: values.endDateTime,
    openRegistrationDate: values.openRegistrationDate,
    closeRegistrationDate: values.closeRegistrationDate,
  });

  // 支持ISO格式和自定义格式的日期验证
  const isValidStartDate = moment(values.startDateTime, moment.ISO_8601, true).isValid()
    || moment(values.startDateTime, dateStoreFormat, true).isValid();

  const isValidEndDate = moment(values.endDateTime, moment.ISO_8601, true).isValid()
    || moment(values.endDateTime, dateStoreFormat, true).isValid();

  if (!values.startDateTime) {
    errors.startDateTime = 'This field is required';
  } else if (!isValidStartDate) {
    errors.startDateTime = `This date is not valid`;
  }

  if (!values.endDateTime) {
    errors.endDateTime = 'This field is required';
  } else if (!isValidEndDate) {
    errors.endDateTime = `This date is not valid`;
  }

  // 支持ISO格式和自定义格式的日期验证
  const isValidOpenRegistrationDate = moment(values.openRegistrationDate, moment.ISO_8601, true).isValid()
    || moment(values.openRegistrationDate, dateStoreFormat, true).isValid();

  const isValidCloseRegistrationDate = moment(values.closeRegistrationDate, moment.ISO_8601, true).isValid()
    || moment(values.closeRegistrationDate, dateStoreFormat, true).isValid();

  if (!values.openRegistrationDate) {
    errors.openRegistrationDate = 'This field is required';
  } else if (!isValidOpenRegistrationDate) {
    errors.openRegistrationDate = `This date is not valid`;
  }

  if (!values.closeRegistrationDate) {
    errors.closeRegistrationDate = 'This field is required';
  } else if (!isValidCloseRegistrationDate) {
    errors.closeRegistrationDate = `This date is not valid`;
  }

  if (values.type === 'referral' && !values.referralPaymentType) {
    errors.referralPaymentType = 'This field is required';
  }

  if (values.type === 'referral' && values.referralPaymentType && values.referralPaymentType === 'commission-amount' && !values.commissionAmount) {
    errors.commissionAmount = 'This field is required';
  }

  if (values.type === 'referral' && values.referralPaymentType && values.referralPaymentType === 'commission-percentage' && !values.commissionPercentage) {
    errors.commissionPercentage = 'This field is required';
  }

  if (values.type === 'referral' && values.referralPaymentType && values.referralPaymentType === 'commission-amount' && !/^\d+(.\d{1,2})?$/i.test(values.commissionAmount)) {
    errors.commissionAmount = 'Incorrect format';
  }

  if (values.type === 'referral' && values.referralPaymentType && values.referralPaymentType === 'commission-percentage' && !/^[1-9][0-9]?$|^100$/i.test(values.commissionPercentage)) {
    errors.commissionPercentage = 'Incorrect format';
  }

  if (values.type === 'timed' && (!values.requiredStreamTimeMinutes || !isNumber(toNumber(values.requiredStreamTimeMinutes)) || values.requiredStreamTimeMinutes <= 0)) {
    errors.requiredStreamTimeMinutes = 'Required';
  }

  if (values.type === 'timed' && (values.requiredStreamTimeMinutes <= 0)) {
    errors.requiredStreamTimeMinutes = 'gte than 0';
  }

  if (values.type === 'tiered-one-time' || values.type === 'tiered-multi-days' || values.type === 'paid bounty' || values.type === 'timed') {
    const requiredStreamTimeMinutes = toNumber(values.requiredStreamTimeMinutes);
    if (Number.isNaN(requiredStreamTimeMinutes)) {
      errors.requiredStreamTimeMinutes = 'This field is not a number';
    }
    if (isNumber(requiredStreamTimeMinutes) && requiredStreamTimeMinutes <= 0) {
      errors.requiredStreamTimeMinutes = 'This field must be greater than 0';
    }
  }

  if (values.type !== 'basic' && (!values.requiredStreamTimeMinutes || !isNumber(toNumber(values.requiredStreamTimeMinutes)))) {
    errors.requiredStreamTimeMinutes = 'This field is required';
  }

  if (values.isReferral === true && values.referralBonus === true && !values.salesForBonus > 0) {
    errors.salesForBonus = 'Required';
  }

  if (values.isReferral === true && values.referralBonus === true && !values.bonusValue > 0) {
    errors.bonusValue = 'Required';
  }

  if (values.isReferral === true && values.referralBonus === true && (values.referralBonusType !== 'bonus-in-percentage' && values.referralBonusType !== 'bonus-in-amount')) {
    errors.referralBonusType = 'Required';
  }

  if (values.isReferral === true && values.referralBonus === true && (values.referralBonusTimes !== 'once' && values.referralBonusTimes !== 'every-sale')) {
    errors.referralBonusTimes = 'Required';
  }

  if (!values.slug || values.slug.trim() === '') {
    errors.slug = 'This field is required';
  }

  if (values.type === 'timed' && (values.paymentAmount < 0 || !isNumber(toNumber(values.paymentAmount)))) {
    errors.paymentAmount = 'This field is required';
  }

  if (values.type === 'paid bounty' && (values.dailyEarning < 0 || !isNumber(toNumber(values.dailyEarning)))) {
    errors.dailyEarning = 'This field is required';
  }

  if (values.type === 'basic' && !values?.viewerTiers?.length) {
    errors.viewerTiers = 'This field is required';
  }

  if (values.viewerTiers && values.viewerTiers.length > 0) {
    const viewerTiersArrayErrors = [];
    values.viewerTiers.forEach((viewerTier, index) => {
      const viewerTierErrors = {};

      let isErroring = false;

      if ((!viewerTier.paymentAmount && viewerTier.paymentAmount !== 0) || viewerTier.paymentAmount === '') {
        viewerTierErrors.paymentAmount = 'This field is required';
        isErroring = true;
      } else {
        const paymentAmount = toNumber(viewerTier.paymentAmount);
        if (Number.isNaN(paymentAmount)) {
          viewerTierErrors.paymentAmount = 'This field is not a number';
          isErroring = true;
        }
      }

      if ((!viewerTier.minimumAverageViewers && viewerTier.minimumAverageViewers !== 0) || viewerTier.minimumAverageViewers === '') {
        viewerTierErrors.minimumAverageViewers = 'This field is required';
        isErroring = true;
      } else {
        const minimumAverageViewers = toNumber(viewerTier.minimumAverageViewers);
        if (Number.isNaN(minimumAverageViewers)) {
          viewerTierErrors.minimumAverageViewers = 'This field is not a number';
          isErroring = true;
        }
      }

      if (!viewerTier.spotsAvailable || viewerTier.spotsAvailable === '') {
        viewerTierErrors.spotsAvailable = 'This field is required';
        isErroring = true;
      } else {
        const spotsAvailable = toNumber(viewerTier.spotsAvailable);
        if (Number.isNaN(spotsAvailable)) {
          viewerTierErrors.spotsAvailable = 'This field is not a number';
          isErroring = true;
        }
      }

      if (isErroring) viewerTiersArrayErrors[index] = viewerTierErrors;
    });

    if (viewerTiersArrayErrors.length) {
      errors.viewerTiers = viewerTiersArrayErrors;
    }
  }

  if (values.memberTiers && values.memberTiers.length > 0) {
    const memberTiersArrayErrors = [];
    values.memberTiers.forEach(({ paymentAmount, identity }, index) => {
      const memberTierErrors = {};
      const mtPaymentAmount = toNumber(paymentAmount);
      let isErroring = false;

      if (Number.isNaN(mtPaymentAmount) || mtPaymentAmount === '' || !mtPaymentAmount) {
        memberTierErrors.paymentAmount = 'This field is not a number';
        isErroring = true;
      }

      if (isNumber(mtPaymentAmount) && mtPaymentAmount <= 0) {
        memberTierErrors.paymentAmount = 'This field must be greater than 0';
        isErroring = true;
      }

      if (!identity || identity.trim() === '') {
        memberTierErrors.identity = 'This field is required';
        isErroring = true;
      }

      if (isErroring) memberTiersArrayErrors[index] = memberTierErrors;
    });

    if (memberTiersArrayErrors.length) {
      errors.memberTiers = memberTiersArrayErrors;
    }
  }

  if (values.splitTiers && values.splitTiers.length > 0) {
    const splitFiltersArrayErrors = [];

    if (autoStreamingQuest) {
      //走自动审核流程
      values.splitTiers.forEach(({ streamDays, streamHours, maxRevenue, prizePool }, index) => {
        const splitFiltersErrors = {};
        let isErroring = false;

        if (streamDays === '' || !streamDays) {
          splitFiltersErrors.streamDays = 'This field is required';
          isErroring = true;
        } else if (streamDays <= 0) {
          splitFiltersErrors.streamDays = 'This field must be greater than 0';
          isErroring = true;
        } else if (streamDays > 9999) {
          splitFiltersErrors.streamDays = 'This field must be less than 10000';
          isErroring = true;
        }

        if (streamHours === '' || !streamHours) {
          splitFiltersErrors.streamHours = 'This field is required';
          isErroring = true;
        } else if (streamHours <= 0) {
          splitFiltersErrors.streamHours = 'This field must be greater than 0';
          isErroring = true;
        } else if (streamHours > 9999) {
          splitFiltersErrors.streamHours = 'This field must be less than 10000';
          isErroring = true;
        }

        if (maxRevenue === '' || !maxRevenue) {
          splitFiltersErrors.maxRevenue = 'This field is required';
          isErroring = true;
        } else if (maxRevenue <= 0) {
          splitFiltersErrors.maxRevenue = 'This field must be greater than 0';
          isErroring = true;
        } else if (maxRevenue > 99999999) {
          splitFiltersErrors.maxRevenue = 'This field must be less than 100000000';
          isErroring = true;
        }

        if (prizePool === '' || !prizePool) {
          splitFiltersErrors.prizePool = 'This field is required';
          isErroring = true;
        } else if (prizePool > 99999999) {
          splitFiltersErrors.prizePool = 'This field must be less than 100000000';
          isErroring = true;
        }

        if (isErroring) splitFiltersArrayErrors[index] = splitFiltersErrors;
      });
    }

    if (secondaryCreationQuest) {
      //走二创流程
      values.splitTiers.forEach(({ totalUploadDays, validUploadCount, totalViews, maxRevenue, prizePool }, index) => {
        const splitFiltersErrors = {};
        let isErroring = false;

        if (totalUploadDays === '' || !totalUploadDays) {
          splitFiltersErrors.totalUploadDays = 'This field is required';
          isErroring = true;
        } else if (totalUploadDays <= 0) {
          splitFiltersErrors.totalUploadDays = 'This field must be greater than 0';
          isErroring = true;
        } else if (totalUploadDays > 999) {
          splitFiltersErrors.totalUploadDays = 'This field must be less than 1000';
          isErroring = true;
        }

        if (validUploadCount === '' || !validUploadCount) {
          splitFiltersErrors.validUploadCount = 'This field is required';
          isErroring = true;
        } else if (validUploadCount <= 0) {
          splitFiltersErrors.validUploadCount = 'This field must be greater than 0';
          isErroring = true;
        } else if (validUploadCount > 9999) {
          splitFiltersErrors.validUploadCount = 'This field must be less than 10000';
          isErroring = true;
        }

        if (totalViews === '' || !totalViews) {
          splitFiltersErrors.totalViews = 'This field is required';
          isErroring = true;
        } else if (totalViews <= 0) {
          splitFiltersErrors.totalViews = 'This field must be greater than 0';
          isErroring = true;
        } else if (totalViews > 99999999) {
          splitFiltersErrors.totalViews = 'This field must be less than 100000000';
          isErroring = true;
        }

        if (maxRevenue === '' || !maxRevenue) {
          splitFiltersErrors.maxRevenue = 'This field is required';
          isErroring = true;
        } else if (maxRevenue <= 0) {
          splitFiltersErrors.maxRevenue = 'This field must be greater than 0';
          isErroring = true;
        } else if (maxRevenue > 99999999) {
          splitFiltersErrors.maxRevenue = 'This field must be less than 100000000';
          isErroring = true;
        }

        if (prizePool === '' || !prizePool) {
          splitFiltersErrors.prizePool = 'This field is required';
          isErroring = true;
        } else if (prizePool <= 0) {
          splitFiltersErrors.prizePool = 'This field must be greater than 0';
          isErroring = true;
        } else if (prizePool > 99999999) {
          splitFiltersErrors.prizePool = 'This field must beless than 100000000';
          isErroring = true;
        }

        if (isErroring) splitFiltersArrayErrors[index] = splitFiltersErrors;
      });
    }

    if (splitFiltersArrayErrors.length) {
      errors.splitTiers = splitFiltersArrayErrors;
    }
  }

  if (values.rankTiers && values.rankTiers.length > 0) {
    const rankTiersArrayErrors = [];

    if (autoStreamingQuest) {
      values.rankTiers.forEach(({ rankUpper, rankLower, watchTime, prize }, index) => {
        const errorsForTier = {};
        let hasBasicError = false;

        // 基础必填校验：不能为空且大于0
        if (rankUpper == null || rankUpper === '' || toNumber(rankUpper) <= 0) {
          errorsForTier.rankUpper = 'This field is required and must be greater than 0';
          hasBasicError = true;
        } else if (toNumber(rankLower) > 999) {
          errorsForTier.rankLower = 'This field  must be less than 1000';
          hasBasicError = true;
        }
        if (rankLower == null || rankLower === '' || toNumber(rankLower) <= 0) {
          errorsForTier.rankLower = 'This field is required and must be greater than 0';
          hasBasicError = true;
        } else if (toNumber(rankLower) > 9999) {
          errorsForTier.rankLower = 'This field  must be less than 10000';
          hasBasicError = true;
        }
        if (watchTime == null || watchTime === '' || toNumber(watchTime) <= 0) {
          errorsForTier.watchTime = 'This field is required and must be greater than 0';
          hasBasicError = true;
        } else if (toNumber(watchTime) > 99999999) {
          errorsForTier.watchTime = 'This field  must be less than 100000000';
          hasBasicError = true;
        }
        if (prize == null || prize === '' || toNumber(prize) <= 0) {
          errorsForTier.prize = 'This field is required and must be greater than 0';
          hasBasicError = true;
        } else if (toNumber(prize) > 99999999) {
          errorsForTier.prize = 'This field  must be less than 100000000';
          hasBasicError = true;
        }

        // 如果基础验证都通过，再进行 index > 0 时的交叉规则验证
        if (!hasBasicError && index > 0) {
          const prevTier = values.rankTiers[index - 1];
          if (toNumber(rankUpper) !== toNumber(prevTier.rankLower) + 1) {
            errorsForTier.rankUpper = 'Rank Upper must be previous Rank Lower + 1';
          }
          if (toNumber(prize) >= toNumber(prevTier.prize)) {
            errorsForTier.prize = 'Prize must be less than previous tier';
          }
        }

        if (Object.keys(errorsForTier).length > 0) {
          rankTiersArrayErrors[index] = errorsForTier;
        }
      });
    }

    if (secondaryCreationQuest) {
      values.rankTiers.forEach(({ rankUpper, rankLower, minTotalViews, prize }, index) => {
        const errorsForTier = {};
        let hasBasicError = false;

        // 基础必填校验：不能为空且大于0
        if (rankUpper == null || rankUpper === '' || toNumber(rankUpper) <= 0 || toNumber(rankUpper) <= 0) {
          errorsForTier.rankUpper = 'This field is required and must be greater than 0';
          hasBasicError = true;
        }
        if (rankLower == null || rankLower === '' || toNumber(rankLower) <= 0 || toNumber(rankLower) <= 0) {
          errorsForTier.rankLower = 'This field is required and must be greater than 0';
          hasBasicError = true;
        }
        if (minTotalViews == null || minTotalViews === '' || toNumber(minTotalViews) <= 0 || toNumber(minTotalViews) <= 0) {
          errorsForTier.minTotalViews = 'This field is required and must be greater than 0';
          hasBasicError = true;
        }
        if (prize == null || prize === '' || toNumber(prize) <= 0 || toNumber(prize) <= 0 || toNumber(minTotalViews) <= 0) {
          errorsForTier.prize = 'This field is required and must be greater than 0';
          hasBasicError = true;
        }

        // 如果基础验证都通过，再进行 index > 0 时的交叉规则验证
        if (!hasBasicError && index > 0) {
          const prevTier = values.rankTiers[index - 1];
          if (toNumber(rankUpper) !== toNumber(prevTier.rankLower) + 1) {
            errorsForTier.rankUpper = 'Rank Upper must be previous Rank Lower + 1';
          }
          if (toNumber(prize) >= toNumber(prevTier.prize)) {
            errorsForTier.prize = 'Prize must be less than previous tier';
          }
        }

        if (Object.keys(errorsForTier).length > 0) {
          rankTiersArrayErrors[index] = errorsForTier;
        }

      });
    }

    if (rankTiersArrayErrors.length) {
      errors.rankTiers = rankTiersArrayErrors;
    }
  }

  if (values.stepsPage1 && values.stepsPage1.length > 0) {
    const stepsPage1ArrayErrors = [];
    values.stepsPage1.forEach(({ title }, index) => {
      const stepsPage1Errors = {};
      let isErroring = false;

      if (!title || title.trim() === '') {
        stepsPage1Errors.title = 'This field is required';
        isErroring = true;
      }

      if (isErroring) stepsPage1ArrayErrors[index] = stepsPage1Errors;
    });

    if (stepsPage1ArrayErrors.length) {
      errors.stepsPage1 = stepsPage1ArrayErrors;
    }
  }

  if (values.whitelist && values.whitelist.length > 0) {
    const whitelistArrayErrors = [];

    values.whitelist.forEach(({ type, identity }, index) => {
      const whitelistErrors = {};
      let isErroring = false;

      if (!type) {
        whitelistErrors.type = 'This field is required';
        isErroring = true;
      }

      if (!identity || identity === '') {
        whitelistErrors.identity = 'This field is required';
        isErroring = true;
      }

      if (isErroring) whitelistArrayErrors[index] = whitelistErrors;
    });

    if (whitelistArrayErrors.length) {
      errors.whitelist = whitelistArrayErrors;
    }
  }

  const requiredBanner = values.requiredBanner === undefined || values.requiredBanner.trim() === '';
  const bannerLink = values.bannerLink === undefined || values.bannerLink.trim() === '';
  if (requiredBanner || bannerLink) {
    let displayBannerReq = false;
    const questSteps = [].concat(values.steps, values.stepsPage1);
    questSteps.forEach((step) => {
      if (step && step.type === 'display-download' && step.subtype === 'banner') {
        displayBannerReq = true;
      }
    });

    if (displayBannerReq) {
      if (requiredBanner) errors.requiredBanner = 'Display Banner Step exists, this field is required';
      if (bannerLink) errors.bannerLink = 'Display Banner Step exists, this field is required';
    }
  }

  if (!values.platforms) {
    errors.platforms = 'This field is required';
  }

  if (!values.countries || values.countries.length <= 0) {
    errors.countries = 'This field is required';
  }

  if (!values.languages || values.languages.length <= 0) {
    errors.languages = 'This field is required';
  }

  if (values.requirements) {
    const steps = ['beforeStream', 'duringStream', 'afterStream'];
    const requirementErrors = {};
    let isHighErroring = false;

    steps.forEach((step) => {
      if (values.requirements[step] && values.requirements[step].length) {
        const currentStepErrors = [];

        values.requirements[step].forEach(({ title }, index) => {
          const reqErrors = {};
          let isErroring = false;

          if (!title || title.trim() === '') {
            reqErrors.title = 'This field is required';
            isErroring = true;
          }

          if (isErroring) {
            currentStepErrors[index] = reqErrors;
          }
        });

        if (currentStepErrors.length) {
          isHighErroring = true;
          requirementErrors[step] = currentStepErrors;
        }
      }
    });

    if (isHighErroring) {
      errors.requirements = requirementErrors;
    }
  }

  // FAQ 验证：当 FAQ 列表不为空时，question 和 answer 均不能为空
  if (values.faqs && values.faqs.length > 0) {
    const faqArrayErrors = [];

    values.faqs.forEach(({ title }, index) => {
      const faqErrors = {};
      let isErroring = false;

      if (!title) {
        faqErrors.title = 'This field is required';
        isErroring = true;
      }

      if (isErroring) faqArrayErrors[index] = faqErrors;
    });

    if (faqArrayErrors.length) {
      errors.faqs = faqArrayErrors;
    }
  }

  // Quest Description 验证：当 Quest Description长度大于0时，title 不能为空
  if (values.questDescriptions && values.questDescriptions.length > 0) {
    const questDescriptionsArrayErrors = [];

    values.questDescriptions.forEach(({ title }, index) => {
      const questDescriptionErrors = {};
      let isErroring = false;

      if (!title) {
        questDescriptionErrors.title = 'This field is required';
        isErroring = true;
      }

      if (isErroring) questDescriptionsArrayErrors[index] = questDescriptionErrors;
    });

    if (questDescriptionsArrayErrors.length) {
      errors.questDescriptions = questDescriptionsArrayErrors;
    }
  }

  return errors;
};

QuestForm.propTypes = {
  //  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'questForm',
  validate,
})(QuestForm);
