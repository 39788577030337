/* eslint-disable react/no-array-index-key */

import React from 'react';
import { find } from 'lodash';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
// import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';
import { simpleNotification } from '../../utils/notifications';
import { isAuthenticated } from '../../utils/authChecker';
import { localizeIncorrectDate } from '../../utils/dateTime';
import shop from '../../images/shop.png';
import CdkeyBuyModal from './CdkeyBuyModal';
import Counter from './Counter';
import './GameNew.scss';
import {
  getPaymentAmountForAuto,
  getPlatformImages,
  getCurryFormattedString, roundingMoney, getAmountFormattedString, isQuestOver, getLocalTimeString
} from '../../utils/functions';
import Loading from '../loading/Loading';  // 添加导入
import GameGallery from '../quest/GameGallery';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from "@material-ui/core";
import PromptDialog from "../dialog/PromptDialog";
import OrderConfirmDialog from "../dialog/OrderConfirmDialog";  // 添加导入

let platformOptions = [];
// 在文件顶部添加常量定义
const purchaseMethodOptions = [
  { label: 'Buy for yourself', value: 'b' },
  { label: 'Give away', value: 'g' }
];

const paymentMethodOptions = [
  {
    name: 'Noiz Wallet',
    icon: 'wallet-icon',
    value: 'noiz',
  },
  /*{
    name: 'PayPal',
    icon: 'paypal-icon',
    value: 'paypal',
  }*/
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    padding: '10px',
    overflowX: 'hidden'
  },
  '& .MuiDialogContent-dividers': {
    borderTop: '0px',
    borderBottom: '0px'
  },
  '& .MuiTypography-h6': {
    textTransform: 'none'
  },
  '& .group_7': {
    width: '305px',
    height: '52px',
    margin: '32px 0 0 100px'
  },
  '& .flex-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .flex-col': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .text-wrapper_13': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    fontSize: '0',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text_14': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '36px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px',
  },
  '& .text_15': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '60px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text-wrapper_14': {
    width: '305px',
    height: '18px',
    margin: '35px 0 0 0px'
  },
  '& .text_12': {
    width: '305px',
    height: '18px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei-Bold',
    fontWeight: '700',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '18px'
  },
  '& .block_6': {
    width: '542px',
    height: '204px',
    margin: '19px 0 0 0px'
  },
  '& .text-wrapper_9': {
    backgroundColor: 'rgba(33, 28, 39, 1)',
    borderRadius: '7px',
    height: '204px',
    width: '542px',
    overflowY: 'auto'
  },
  '& .paragraph_2': {
    width: '156px',
    //height: '154px',
    overflowWrap: 'break-word',
    color: 'rgba(205, 204, 208, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    lineHeight: '34px',
    margin: '0px 0 0 24px',
  },
  '& .paragraph_3': {
    width: '305px',
    //height: '151px',
    overflowWrap: 'break-word',
    color: 'rgba(217, 215, 215, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    lineHeight: '34px',
    margin: '0px 42px 0 0px'
  },
  '& .text-wrapper_10': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor: '#ff0000',
    borderRadius: '100px',
    cursor: 'pointer',
    justifyContent: 'center'
  },
  '& .text-wrapper_11': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor: '#808280',
    borderRadius: '100px',
    cursor: 'pointer',
    justifyContent: 'center'
  },
  '& .text_13': {
    width: '100%',
    height: '24px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '18px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    margin: '0px 0 0 0px'
  },
  '& .block_7': {
    width: '250px',
    height: '48px',
    margin: '0px 0 0px 0px'
  },
}));

class GameNew extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.thumbSwiper = null;
    this.masterSwiper = null;
    this.agreeBounty = null;
    this.agreeKey = null;
    this.state = {
      activeTab: 1,
      activeGalleryIndex: 0,
      swiper: null,
      currentActiveTab: 0,
      isModalVisible: false,
      counterValue: 0, // Add state to store counter value
      platform: 'pc',
      purchaseMethod: 'b',
      paymentMethod: 'noiz',
      quantity: 1,
      giftEmail: '',
      products: [],
      product: null,
      minProduct: null,
      dialogPayOpen: false,
      curOrder: null,
      dialogResultOpen: false,
      payErrorType: 0,
      sendEmail: ""
    };
  }

  UNSAFE_componentWillMount() {
    if (isAuthenticated()) {
      const { token, user } = this.props.auth;
      const isBasicInfoCompleted = (user.firstName && user.lastName && user.country);
      if (!isBasicInfoCompleted) {
        this.props.router.replace('/complete-registration');
      } else {
        // 如果使用 react-router v4，请使用 this.props.match.params
        const { gameId } = this.props.router.params;
        if (gameId) {
          this.props.getGame(gameId, token);
          this.props.getCDKProducts(gameId, 0, null, false, token, 0);
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config.game.isLoading === true &&
      nextProps.config.game.isLoading === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.config.game.errors.hasError) {
        if (nextProps.config.game.errors.code === 'E_GAME_NOT_AVAILABLE') {
          this.props.router.replace('/not-found');
        } else {
          this.props.router.replace('/not-found');
        }
      }
    }
    if (this.props.user.myPreApprovedGameKeys.isRequestingAGameKey === true &&
      nextProps.user.myPreApprovedGameKeys.isRequestingAGameKey === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myPreApprovedGameKeys.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: nextProps.user.myPreApprovedGameKeys.errors.code,
          message: nextProps.user.myPreApprovedGameKeys.errors.message,
        });
      } else {
        this.openModal('KeyComing');
      }
    }
    if (this.props.user.myBounties.isJoiningToABounty === true &&
      nextProps.user.myBounties.isJoiningToABounty === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myBounties.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: nextProps.user.myBounties.errors.code,
          message: nextProps.user.myBounties.errors.message,
        });
      } else {
        this.changeModalStep(2);
      }
    }
    if (this.props.user.myBounties.isLeavingABounty === true &&
      nextProps.user.myBounties.isLeavingABounty === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myBounties.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: nextProps.user.myBounties.errors.code,
          message: nextProps.user.myBounties.errors.message,
        });
      } else {
        this.closeModal();
      }
    }
    if (this.props.user.cdkProducts.isLoading === true &&
      nextProps.user.cdkProducts.isLoading === false) {
      if (nextProps.user.cdkProducts.errors.hasError) {
      } else {
        if (nextProps.user.cdkProducts.data && nextProps.user.cdkProducts.data.length > 0) {
          const minCp = nextProps.user.cdkProducts.data.reduce((min, cp) => { return min.salePrice < cp.salePrice ? min : cp });
          this.setState({ platform: minCp.platform.id, product: minCp, products: nextProps.user.cdkProducts.data.filter(cp => cp.platform.id === minCp.platform.id), minProduct: minCp });
        }
      }
    }
    if (this.props.user.cdkOrder.isLoading === true &&
      nextProps.user.cdkOrder.isLoading === false) {
      if (nextProps.user.cdkOrder.errors.hasError) {
        if (nextProps.user.cdkOrder.errors.code === 'E_ORDER_HAVE_NOT_PAY') {
          simpleNotification({
            level: 'error',
            title: 'Order error',
            message: 'There is an order to be paid, please complete the payment first',
          });
        } else {
          simpleNotification({
            level: 'error',
            title: 'Order error',
            message: nextProps.user.cdkOrder.errors.message,
          });
        }
      } else {
        if (nextProps.user.cdkOrder.data) {
          if (nextProps.user.cdkOrder.code !== 'CANCEL_CDK_ORDER') {
            if (nextProps.user.cdkOrder.data.order) {
              //this.setState({payOrderId: nextProps.user.cdkOrder.data.orderId});
              this.showPayDialog(nextProps.user.cdkOrder.data.order);
            } else {
              simpleNotification({
                level: 'error',
                title: 'Order error',
                message: 'Unable to make payment',
              });
            }
          }
        }
        const { token } = this.props.auth;
        const { gameId } = this.props.router.params;
        this.props.getCDKProducts(gameId, 0, null, false, token, 0);
      }
    }
    if ((this.props.user.cdkOrder_p.isLoading === true && nextProps.user.cdkOrder_p.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.cdkOrder_p.errors.hasError) {
        if (nextProps.user.cdkOrder_p.errors.code === 'E_WALLET_AMOUNT') {
          this.setState({ payErrorType: 2, dialogResultOpen: true });
        } else {
          this.setState({ payErrorType: -1, dialogResultOpen: true });
        }
      } else {
        this.setState({ payErrorType: 0, dialogResultOpen: true, sendEmail: nextProps.user.cdkOrder_p.data.sendEmail });
      }
    }
    if ((this.props.user.orderLink.isLoading === true && nextProps.user.orderLink.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.orderLink.errors.hasError) {
        this.setState({ payErrorType: -1, dialogResultOpen: true });
      } else {
        this.setState({ payErrorType: 1, dialogResultOpen: true });
      }
    }
  }

  getQuest = (quest, userQuest) => {
    if (quest.comingSoon) {
      return null;
    } else if (userQuest && (userQuest.status === 'normal' || userQuest.status === 'approved')) {
      // browserHistory.push(`/my-quests/${quest.id}`);
      this.props.router.push(`/quests/${quest.slug || quest.id}`);
    } else {
      this.props.router.push(`/quests/${quest.slug || quest.id}`);
    }
  }

  generateGameGalleryHTML(gallery) {
    // If no active games show this message
    const images = gallery.filter(item => item.type === 'image');
    const videos = gallery.filter(item => item.type === 'video');

    if (gallery.length <= 0) {
      return (<div className="NoGallery">No Resource at this moment.</div>);
    }

    return (<section className="GameGallery">
      <section className="GameGallery__wrapper">
        {images.length > 0 && <GameGallery gallery={images} isGameNew={true} />}
        {/* {
          images.length > 0 && (
            <section className="GameGallery__Item">
              <section className="GameGallery__title">Games gallery</section>
              <section className="GameGallery__line"></section>
              <section className="GameGallery__image__content">
                {images.map((image, index) => (
                  <section key={index} className="GameGallery__image">
                    <figure style={{ backgroundImage: `url(${image.url})` }} />
                  </section>
                ))}
              </section>
            </section>
          )
        } */}
        {
          videos.length > 0 && (
            <section className="GameGallery__Item__Bottom">
              <section className="GameGallery__title">games promotional video</section>
              <section className="GameGallery__line"></section>
              <section className="GameGallery__video__content">
                {videos.map((video, index) => (
                  <section key={index} className="GameGallery__video"
                  // onMouseEnter={this.pauseAutoPlay}
                  // onMouseLeave={this.resumeAutoPlay}
                  // onTouchStart={this.pauseAutoPlay}
                  >
                    <iframe
                      className="youtube-video"
                      title="youtube-video"
                      width="100%"
                      height="100%"
                      src={`${video.url}?autoplay=1&rel=0`}
                      // frameBorder="0"
                      allowFullScreen
                    />
                  </section>
                ))}

              </section>
            </section>
          )
        }
      </section>
    </section>)
  }

  generateGameQuestsHTML(quests, isLoading, myQuests, myUserQuests, game) {
    if (game && quests && !isLoading && quests.length > 0) {
      // const today = dayjs();
      return quests.map((quest, i) => {
        const userQuest = find(myUserQuests, o => (o.quest === quest.id));
        let declined = false;
        let applied = false;
        if (userQuest && userQuest.status === 'declined') {
          declined = true;
        } else if (userQuest && userQuest.status === 'pending') {
          applied = true;
        }

        // const alreadyIn = find(myQuests, questItem => questItem.id === quest.id);

        // 修改为使用已定义的 userQuest 变量和状态检查
        const alreadyIn = userQuest && (userQuest.status === 'approved' || userQuest.status === 'normal');
        let dateInterval;
        if (quest.startDateTime && quest.endDateTime) {
          dateInterval = `${localizeIncorrectDate(quest.startDateTime).format('MM/DD')} - ${localizeIncorrectDate(quest.endDateTime).format('MM/DD')}`;
        }
        /*  <-------- START - End Date Time (ended) Validation -------->  */
        let questEnded = false;
        if (quest.endDateTime) {
          questEnded = isQuestOver(quest);
        }
        /*  <-------- END - End Date Time (ended) Validation -------->  */
        let buttonText = ""
        // START - Button HTML
        // let buttonHTML = <React.Fragment />;
        if (questEnded) {
          // buttonHTML = (<button className="SeeQuest disabled">Quest Ended</button>);
          buttonText = "Quest Ended"
        } else if (applied) {
          // buttonHTML = (<button className="SeeQuest disabled">Applied</button>);
          buttonText = "Applied"
        } else if (declined) {
          // buttonHTML =
          //   (
          //     <button className="SeeQuest disabled">
          //       <div className="tooltip">Declined <i className="fa fa-info-circle" aria-hidden />
          //         <span className="tooltiptext">
          //           You were not selected for this quest. Check the quests page and your email for other opportunities.
          //         </span>
          //       </div>
          //     </button>
          //   );
          buttonText = "Declined"
        } else if (alreadyIn) {
          // buttonHTML = <button className="SeeQuest">Joined</button>;
          buttonText = "Joined"
        } else {
          // buttonHTML =
          //   (
          //     <button
          //       className={classNames('SeeQuest', { disabled: (typeof quest.openSpots === 'undefined' || quest.openSpots <= 0) })}
          //     >
          //       See Quest
          //     </button>
          //   );
          buttonText = "See Quest"
        }
        // END - Button HTML
        // START - Tiered quest title
        let questTitle = quest.title;
        if (quest.type === 'tiered' || quest.type === 'tiered-one-time' || quest.type === 'tiered-multi-days') {
          const unpaidQuest2 = (quest.paymentAmount < 0);
          const hours = Math.floor(quest.requiredStreamTimeMinutes / 60);
          const minutes = quest.requiredStreamTimeMinutes % 60;
          const textMinutes = (minutes !== 0) ? ` ${minutes} minutes` : '';
          let textHours = '';
          if (hours === 1) {
            textHours = '1 hour';
          } else if (hours > 1) {
            textHours = `${hours} hours`;
          }
          if (quest.paymentAmount === 0 && !unpaidQuest2) {
            questTitle = quest.title;
            if (quest.openSpots > 0 || alreadyIn) {
              questTitle = `${quest.title}`;
            }
          } else if (quest.frequency === 'one-time' && !unpaidQuest2) {
            questTitle = `${quest.title} - Play for ${textHours}${textMinutes} and earn! - $${quest.paymentAmount}`;
          } else if (quest.frequency === 'daily' && !unpaidQuest2) {
            questTitle = `${quest.title} - Play for ${textHours}${textMinutes} and earn! - $${quest.paymentAmount}`;
          } else {
            questTitle = quest.title;
          }
        }
        // END - Tiered quest title
        // const paymentAmountNew = getPaymentAmountForAuto(quest.splitTiers || [], quest.rankTiers || []);

        const paymentAmountNew = getPaymentAmountForAuto(quest.splitTiers || [], quest.rankTiers || [], quest.viewerTiers || []);
        return (
          <section
            key={quest.id}
            className={classNames('QuestCard', { 'coming-soon': quest.comingSoon })}
            onClick={this.getQuest.bind(this, quest, userQuest)}
            aria-hidden
          >
            <section className="QuestCard__wrapper">
              <div className="QuestCard__image">
                <figure style={{ backgroundImage: `url(${game ? game.cover : ''})` }} alt="Game Cover" />
              </div>

              <section className={classNames('QuestCard__level', {
                'type-1': quest.type === 'tiered-one-time' || quest.type === 'tiered-auto',
                'type-2': quest.type === 'basic',
              })}>
                {/* <div className="QuestCard__level--wrapper">
                  <div className="QuestCard__level--count">{quest.noizPoints || 0}</div>
                </div> */}
                {this.getQuestTypeDisplayName(quest.type)}
              </section>
              <section className="QuestCard__details">
                <div className="QuestCard__background-top" />
                <div className="QuestCard__background-bottom" />
                <section className="QuestCard__details-wrapper">
                  <section className="QuestCard__details--row">
                    {/* <i
                      className={classNames('QuestCard__details--icon', {
                        'noiz-video-camera': quest.type === 'paid bounty',
                        'noiz-game-controller': (quest.type === 'early access key' || quest.type === 'influencers early access'),
                        'noiz-thumbs-up': quest.type === 'alpha feedback',
                        'noiz-discount': quest.type === 'referral',
                        'fa fa-gear': quest.type === 'default',
                        'fa fa-clock-o': quest.type === 'timed',
                        'fa fa-external-link': quest.type === 'link out',
                      })}
                    /> */}
                    <section className="QuestCard__details--info">
                      <section className="primary-info">
                        <div className="QuestCard__details--name">
                          {this.truncateText(questTitle, 60)}
                        </div>
                      </section>
                      {/* <section className="sub-info">
                        <div className="QuestCard__details--game-name">
                          {game.name}
                        </div>
                        <div className="QuestCard__details--date-interval">
                          {dateInterval || 'No Date'}
                        </div>
                      </section> */}
                    </section>
                  </section>

                  <div className="red-line"></div>
                  <div className='QuestCard__details_details_price_date'>
                    <div className='price'>
                      {quest.type === 'tiered-auto' ? 'UP TO ' : ''}
                      ${(quest.type === 'tiered' || quest.type === 'tiered-one-time' || quest.type === 'tiered-multi-days') ? quest.paymentAmount : paymentAmountNew}</div>
                    <div className='time'> {dateInterval || 'No Date'}</div>
                  </div>

                  <section className={classNames('QuestCard__details--bottom', {
                    'apply': buttonText === 'See Quest',
                    'joined': buttonText === 'Joined',
                    'others': buttonText !== 'See Quest' && buttonText !== 'Joined',
                  })}>
                    {/* Button HTML */}
                    {buttonText}
                  </section>
                  {/* <section className="QuestCard__details--row">
                    {(typeof quest.openSpots !== 'undefined' && quest.openSpots > 0) && (
                      <div className="QuestCard__details--open-slots" style={{ color: (questEnded || quest.hideOpenSpots) ? '#212227' : '#83858c' }}>
                        {(quest.openSpots - quest.usersIn) || 0} open spots
                      </div>
                    )}

                    {(typeof quest.openSpots === 'undefined' || quest.openSpots <= 0) && (
                      <div className="QuestCard__details--open-slots" style={{ color: (questEnded || quest.hideOpenSpots) ? '#212227' : '#83858c' }}>
                        <span>0 open spots</span>
                      </div>
                    )}
                  </section> */}
                </section>
              </section>
              {/* {(quest.comingSoon) && (
                <section className="QuestCard__info-panel">
                  <h4 className="QuestCard__info-panel--title">Coming Soon</h4>
                  <section className="QuestCard__info-panel--details">
                    <Truncate lines={10} ellipsis={<span>...</span>}>
                      {ReactHtmlParser(quest.description)}
                    </Truncate>
                  </section>
                </section>
              )} */}
            </section>
          </section>
        );
      });
    }
    // If no active games show this message
    return (<div className="NoQuests">No Quests at this moment.</div>);
  }

  handleTabChange = (param) => {
    if (param !== 2) this.pauseAutoPlay();
    else this.resumeAutoPlay();

    this.setState({
      activeTab: param,
    });
  }

  handleCurrentTabChange = (param) => {
    this.setState({
      currentActiveTab: param
    })
  }

  getQuestTypeDisplayName = (questType) => {
    const questTypeMapping = {
      'tiered-one-time': 'Live Streaming',
      // Add other mappings if needed
      'tiered-auto': 'Prize Pool',
    };

    return questTypeMapping[questType] || questType;
  };
  goToSlide = (index) => {
    this.setState({
      activeGalleryIndex: index,
    });
  }

  truncateText = (text, maxLength) => {
    if (!text) {
      return ""
    }

    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  pauseAutoPlay = () => {
    if (this.state.swiper && this.state.swiper.autoplay) this.state.swiper.autoplay.stop();
  }

  resumeAutoPlay = () => {
    if (this.state.swiper && this.state.swiper.autoplay) this.state.swiper.autoplay.start();
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isModalVisible: !prevState.isModalVisible }));
  };

  handleBuyButtonClick = () => {
    // Implement the logic to handle the buy button click
    this.toggleModal(); // Example: Toggle the modal on button click
  };

  handleCounterChange = (count) => {
    this.setState({ counterValue: count });
  };

  handlePlatformChange = (platform) => {
    const { data: cdkProducts = [] } = this.props.user?.cdkProducts;
    const cps = cdkProducts.filter(cp => cp.platform.id === platform);
    this.setState({ platform: platform, products: cps });
    if (cps && cps.length > 0) {
      this.setState({ product: cps[0] });
      this.handleProductChange(cps[0]);
    } else {
      this.setState({ product: null, quantity: 0 });
    }
  }

  handlePayment = () => {
    const { token } = this.props.auth;
    const { product, quantity, paymentMethod, purchaseMethod, giftEmail } = this.state;
    if (purchaseMethod === 'g' && (!giftEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(giftEmail))) {
      simpleNotification({
        level: 'error',
        title: "Email is invalid",
        message: 'The email format is incorrect',
      });
      return;
    }
    if (quantity <= 0) {
      simpleNotification({
        level: 'error',
        title: "Quantity error",
        message: 'The quantity must be greater than zero',
      });
      return;
    }
    if (product && quantity && paymentMethod && purchaseMethod) {
      this.props.buyCDK(product.id, product.salePrice, quantity, paymentMethod, purchaseMethod, giftEmail, token);
    }
  }

  showPayDialog = (order) => {
    this.setState({ dialogPayOpen: true, curOrder: order });
  }

  handlePayClose = () => {
    const { token } = this.props.auth;
    const { curOrder } = this.state;
    this.props.cancelCDKOrder(curOrder.id, token);
    this.setState({ dialogPayOpen: false });
    this.toggleModal();
  }

  payOrder = () => {
    const { token } = this.props.auth;
    const { curOrder, paymentMethod } = this.state;
    if (curOrder && paymentMethod) {
      if (paymentMethod === 'noiz') {
        this.props.payCDKOrder(curOrder.id, paymentMethod, token);
      } else if (paymentMethod === 'paypal') {
        this.props.showCDKPay(curOrder.id, token);
      }

      this.setState({ dialogPayOpen: false });
    }
  }

  handleResultClose = () => {
    this.setState({ dialogResultOpen: false });
    //this.toggleModal();
  };

  payPaypalOrder = () => {
    const { approvalLink, isLoading } = this.props.user.orderLink.data;
    if (!isLoading) {
      if (approvalLink) {
        window.open(approvalLink, "_blank");
      }
    }
    this.handleResultClose();
  }

  toRecharge = () => {
    const host = window.location.hostname;
    const isLocal = host === 'localhost';
    const finalHost = isLocal ? `${host}:3000` : host;
    const protocol = isLocal ? 'https://' : 'https://';
    window.open(`${protocol}${finalHost}/my-account-detail/wallet`, '_self');
  }

  handleProductChange = (product) => {
    const { quantity } = this.state;
    if (product && quantity) {
      let maxQuantity = 5;
      if (product.onceMax) {
        maxQuantity = product.onceMax;
      }
      if (quantity > maxQuantity) {
        this.setState({ quantity: maxQuantity });
      }
    } else {
      this.setState({ quantity: 0 });
    }
    this.setState({ product: product })
  }

  generateThumbnails() {
    return this.props.config.game.data.gallery.map((item, i) => {
      switch (item.type) {
        case 'video':
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} >
                <div className="play-icon">
                  <i className="play-icon fa fa-play" />
                </div>
              </div>
            </button>);
        case 'image':
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} />
            </button>);
        default:
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} />
            </button>);
      }
    });
  }

  render() {
    // const gameGalleryParams = {
    //   direction: window.innerWidth <= 768 ? 'horizontal' : 'vertical',
    //   spaceBetween: 25,
    //   slidesPerView: 'auto',
    //   centeredSlides: true,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   on: {
    //     slideChange: () => {
    //       this.setState({ activeGalleryIndex: this.state.swiper.activeIndex });
    //     },
    //   },
    //   speed: 1000,
    //   scrollBar: false,
    //   containerClass: 'GameGalleryThumb',
    //   scrollbar: {
    //     el: '.swiper-scrollbar',
    //     hide: false,
    //   },
    //   freeMode: false,
    //   freeModeMomentum: false,
    //   slideToClickedSlide: true,
    //   mousewheel: true,
    // };

    const { data: game, isLoading: isLoadingGame, quests } = this.props.config.game;
    const { data: myQuests } = this.props.user.myQuests;
    const { data: myUserQuests } = this.props.user.myUserQuests;
    const { data: cdkProducts = [] } = this.props.user?.cdkProducts;
    const { user } = this.props.auth;

    // const swiper = !isEmpty(game) && !isLoadingGame && game.gallery && game.gallery.length > 0 && (
    //   <Swiper
    //     ref={(node) => { if (node && !this.state.swiper) this.setState({ swiper: node.swiper }); }}
    //     {...gameGalleryParams}
    //   >
    //     {this.generateThumbnails()}
    //   </Swiper>
    // );

    const gameGallery = game.gallery || [];
    const {
      isModalVisible,
      platform,
      purchaseMethod,
      paymentMethod,
      quantity,
      products,
      product,
      minProduct,
      dialogPayOpen,
      curOrder,
      payErrorType,
      dialogResultOpen,
      sendEmail
    } = this.state;
    const images_platforms = game.platforms && getPlatformImages(game.platforms);
    if (cdkProducts && cdkProducts.length > 0) {
      const cps = cdkProducts.reduce((acc, p) => {
        if (!acc[p.platform.id]) {
          acc[p.platform.id] = p.platform;
        }
        return acc;
      }, {});
      platformOptions = Object.values(cps);
    }
    return (
      <section className="Game">
        <Loading isLoading={isLoadingGame} />
        <section className="Game__container">
          {/* {this.props.auth.token && <Back />} */}
          <section className="GameInfo">
            <figure className="GameInfo__image" style={{ backgroundImage: `url(${game.coverPortrait || game.cover})` }} >
            </figure>
            <section className="GameInfo__details">
              <section className="GameInfo__details--top">
                <div className="GameInfo__details--title">About the game</div>
                <section className="GameInfo__details--content parsed-content">{ReactHtmlParser(this.truncateText(game.description, 400))}</section>
              </section>

              <section className="GameInfo__details--bottom">
                <section className="GameInfo__details--item">
                  <div className="title">Game Name</div>
                  <section>{game.name}</section>
                </section>
                <section className="GameInfo__details--item">
                  <div className="title">Game platform</div>
                  {images_platforms && images_platforms.length > 0 ? (
                    <div className="platform-image-list">
                      {images_platforms.map((image, index) => (
                        <img key={index} src={image} alt='platforms' className="platform-image" />
                      ))}
                    </div>
                  ) : (
                    <section>None</section>
                  )}

                </section>
                <section className="GameInfo__details--item">
                  <div className="title">Release Date</div>
                  <section>{getLocalTimeString(game.releaseDate, 'YYYY-MM-DD', false)}</section>
                </section>
                <section className="GameInfo__details--item">
                  <div className="title">Developer</div>
                  <section>{game.developer}</section>
                </section>
                <section className="GameInfo__details--item">
                  <div className="title">Publisher</div>
                  <section>{game.publisher}</section>
                </section>
              </section>
            </section>
            {minProduct && <section className='GameInfo__cdkey'>
              <div className="GameInfo__cdkey--wrapper">
                <div className='GameInfo__cdkey--tag1'>GET</div>
                <div className='GameInfo__cdkey--tag2'>CD-KEY</div>
                <div className='GameInfo__cdkey--remaining'>Stock Left:{minProduct && minProduct.availableNum > 999 ? '999+' : minProduct?.availableNum}</div>
                {product.discount > 0 && <div className="price_block">
                  <span className="text_45">${getAmountFormattedString(minProduct?.price)}</span>
                  <div className="text-wrapper_18 flex-col">
                    <span className="text_46">-{Math.floor(product.discount.toFixed())}%</span>
                  </div>
                </div>}
                <div className='GameInfo__cdkey--price'>${getAmountFormattedString(minProduct?.salePrice)}</div>
                <div className='GameInfo__cdkey--buy--container' onClick={this.handleBuyButtonClick}>
                  <img src={shop} alt='shop' className='GameInfo__cdkey--buy--icon' />
                  <button className='GameInfo__cdkey--buy--button'>Buy</button>
                </div>
              </div>
            </section>}
          </section>
          <div className='tab-bar-new'>
            <span
              className={`tab-span ${this.state.currentActiveTab === 0 ? "active" : ""}`}
              // className="tab-span"
              onClick={this.handleCurrentTabChange.bind(this, 0)}
            >
              Quests
            </span>
            <span
              className={`tab-span ${this.state.currentActiveTab === 1 ? "active" : ""}`}
              // className="tab-span"
              onClick={this.handleCurrentTabChange.bind(this, 1)}
            >
              Material
            </span>
          </div>

          <div className='tab-line'></div>

          <section className="Games__primary">
            <div className="Games__primary--wrapper">
              <div className={classNames('PrimaryQuestsList', { active: this.state.currentActiveTab === 0 })}>
                {(game && quests && !isLoadingGame) && this.generateGameQuestsHTML(quests, isLoadingGame, myQuests, myUserQuests, game)}
              </div>
              <div className={classNames('PrimaryGameMedia', { active: this.state.currentActiveTab === 1 })}>

                {this.generateGameGalleryHTML(gameGallery)}
              </div>
            </div>
          </section>
        </section>

        <CdkeyBuyModal isVisible={isModalVisible} onClose={this.toggleModal}>
          <div className="cdkey-modal-content">
            <div
              className="cdkey-modal-header"
            >
              <img src={game.cover || game.coverPortrait} alt={game.name} className="game-pic" />
              <div className="game-info1">
                <div className="triangle-right"></div>
                <div className="price-tag">${product ? getAmountFormattedString(product?.salePrice) : '0.00'}</div>

                {product && product.discount > 0 && <div className="section-discount">
                  <span className="text-sale">-{Math.floor(product.discount.toFixed())}%</span>
                  <div className="section-block">
                    <span className="text-price">${getAmountFormattedString(product.price)}</span>
                  </div>
                </div>}

                <div className="stock-tag">STOCK LEFT: </div>
                <div className="stock-num-tag">{product && product.availableNum > 999 ? '999+' : product?.availableNum}</div>
              </div>
            </div>
            <div className="form-section">
              <div className="form-group">
                <label>Platform of purchase</label>
                <div className="platform-options">
                  {platformOptions.map(platformCur => (
                    <button
                      key={platformCur.id}
                      className={`platform-btn ${platform === platformCur.id ? 'active' : ''}`}
                      onClick={() => this.handlePlatformChange(platformCur.id)}
                    >
                      {platformCur.platform}
                    </button>
                  ))}
                </div>
              </div>
              {products && products.length > 0 && <div className="form-group">
                <label>Product of purchase</label>
                <div className="platform-options">
                  {products.map(productCur => (
                    <button
                      key={productCur.id}
                      className={`platform-btn ${product?.id === productCur.id ? 'active' : ''}`}
                      onClick={() => this.handleProductChange(productCur)}
                    >
                      {productCur.name}
                    </button>
                  ))}
                </div>
              </div>}
              <div className="form-group">
                <label>Method of purchase</label>
                <div className="purchase-method-options">
                  {purchaseMethodOptions.map(method => (
                    <button
                      key={method.value}
                      className={`method-btn ${purchaseMethod === method.value ? 'active' : ''}`}
                      onClick={() => this.setState({ purchaseMethod: method.value })}
                    >
                      {method.label}
                    </button>
                  ))}
                </div>
                {purchaseMethod && <div className="email-note">
                  <div>We will send it to {purchaseMethod === 'g' ? 'the following email address:' : 'your NOIZ registered email address:'}</div>
                  {purchaseMethod === 'g' ? (
                    <input
                      type="email"
                      value={this.state.giftEmail}
                      onChange={e => this.setState({ giftEmail: e.target.value })}
                      placeholder="Enter recipient's email address"
                      className="email-input"
                    />
                  ) : (
                    <p className="email-link">{user.email}</p>
                  )}
                </div>}
              </div>
              <div className="form-group">
                <label>Method of payment</label>
                <div className="payment-method-options">
                  {paymentMethodOptions.map(method => (
                    <button
                      key={method.value}
                      className={`payment-btn ${paymentMethod === method.value ? 'active' : ''}`}
                      onClick={() => this.setState({ paymentMethod: method.value })}
                    >
                      <span className={method.icon}></span>
                      {method.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="quantity-group">
              <div className="quantity-section">
                <label>Quantity</label>
                <Counter
                  initialCount={quantity}
                  onCountChange={(value) => { this.setState({ quantity: value }) }}
                  min={1}
                  max={product ? (product.availableNum > (product.onceMax ? product.onceMax : 5) ? (product.onceMax ? product.onceMax : 5) : product.availableNum) : 1}
                  value={quantity}
                  className="cdkey-counter"
                />
              </div>
              <button className={`pay-button ${(product && quantity && paymentMethod && purchaseMethod) ? 'active' : ''}`} onClick={this.handlePayment}>
                PAY ${product ? getCurryFormattedString(product?.salePrice * quantity) : '0.00'}
              </button>
            </div>
          </div>
        </CdkeyBuyModal>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!false}
        >
          <DialogContent dividers>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Product&nbsp;Type</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{curOrder?.productName}-{curOrder?.platformName}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Price</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>${getCurryFormattedString(curOrder?.salePrice ? roundingMoney(curOrder?.salePrice, 2) : '0.00')}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Amount</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{curOrder?.amount}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Total</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>${getCurryFormattedString(curOrder?.balance ? roundingMoney(curOrder?.balance, 2) : '0.00')}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Method Of Payment</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{this.state.paymentMethod}</Typography>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{ fontSize: '16px', fontWeight: '800', backgroundColor: '#CAC1C4', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.handlePayClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.payOrder.bind(this)}>
              Make Payment
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!false}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{ color: (payErrorType !== 0) ? '#ff0000' : '' }}>
            {payErrorType === -1 && 'Failure to payment'}
            {payErrorType === 1 && 'Do you want to redirect to the payment page to complete the payment?'}
            {payErrorType === 2 && 'NOIZ wallet has insufficient balance'}
            {payErrorType === 0 && 'Payment successful.'}
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {payErrorType === 0 && <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>
                Your CDKEY has been sent to {sendEmail}
              </Typography>}
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#CAC1C4', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.handleResultClose.bind(this)}>
              Close
            </Button>
            {(payErrorType === 1) && (<Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.payPaypalOrder.bind(this)}>
              Confirm
            </Button>)}
            {(payErrorType === 100) && (<Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.toRecharge.bind(this)}>
              Confirm
            </Button>)}
          </DialogActions>
        </BootstrapDialog>
        {payErrorType === 0 && dialogResultOpen && <PromptDialog dialogOpen={dialogResultOpen} level="success" title="Payment successful!" content={'Your CDKEY has been sent to ' + sendEmail} cancelAction={this.handleResultClose} />}
        {payErrorType === -1 && dialogResultOpen && <PromptDialog dialogOpen={dialogResultOpen} level="failure" title="Failure to payment." content={'The amount in your NOIZ wallet is abnormal. Please contact the website administrator.'} cancelAction={this.handleResultClose} />}
        {payErrorType === 1 && dialogResultOpen && <PromptDialog dialogOpen={dialogResultOpen} level="success" title="Do you want to redirect to the payment page to complete the payment?" content={''} confirmButtonName="Make Payment" confirmAction={this.payPaypalOrder} cancelButtonName='cancel' cancelAction={this.handleResultClose} />}
        {payErrorType === 2 && dialogResultOpen && <PromptDialog dialogOpen={dialogResultOpen} level="warn" title="NOIZ wallet has insufficient balance" content={'The balance in your Noiz account in insufficient.'} cancelAction={this.handleResultClose} />}
        {dialogPayOpen && <OrderConfirmDialog dialogPayOpen={dialogPayOpen} curOrder={curOrder} button1Action={this.handlePayClose} button1Name="Cancel" button2Action={this.payOrder} button2Name="Make Payment" />}
      </section>
    );
  }
}

GameNew.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
};

export default GameNew;
