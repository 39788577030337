import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';

import CampaignSelector from '../main/brand/CampaignSelector';
import { getMyQuestsFiltered } from '../../utils/functions';

import './NavMenuNew.scss';

function NavMenuNew({
  auth,
  brandUser,
  isBrandUser,
  isClientPortal,
  router,
  toggleMyQuestsMenu,
  user,
  visible,
}) {
  if (!visible) return null;
  const { isLoadingMyQuests, myQuestsFiltered, requirementsOutstanding } = getMyQuestsFiltered(user);
  const myQuestsCount = (myQuestsFiltered.length > 0 && !isLoadingMyQuests) && myQuestsFiltered.length;
  // 获取当前路径
  const currentPath = window.location.pathname;
  // 判断当前路径是否以指定前缀开头
  const isPathMatch = currentPath.startsWith('/game/') ||
    currentPath.startsWith('/quests/') ||
    currentPath.startsWith('/my-quests/');

  // 检查是否是从新窗口或新标签页直接打开的
  // const isNewWindow = window.opener === null && window.history.length <= 1;

  // 只有当路径匹配且不是从新窗口打开时，才显示返回按钮
  const hasBackButton = isPathMatch;

  // 返回上一层函数
  const handleGoBack = () => {
    window.location.href = '/quests';
  };
  return (
    <nav className="navigation">
      <ul className="navigation-left">
        {!isBrandUser && (
          <>
            <li>
              <Link
                activeClassName="active"
                to="/quests"
              >
                QUESTS
              </Link>
            </li>
            <li>
              <Link
                activeClassName="active"
                to="/contact"
              >
                CONTACT
              </Link>
            </li>
          </>
        )}
        {((auth.user.role === 'admin') && isClientPortal) && (
          <CampaignSelector
            campaign={brandUser.campaign.data}
            campaigns={brandUser.campaigns}
            router={router}
          />
        )}
      </ul>
      <ul className="navigation-right">
        {/* 如果当前不是主页，则显示返回按钮 */}
        {hasBackButton && (
          <li>
            <button onClick={handleGoBack} className="custom-btn-back" role="link">
              <span>◄ BACK TO BROWSE</span>
            </button>
          </li>
        )}
        {!isBrandUser && (
          <li>
            <button
              onClick={toggleMyQuestsMenu}
              className="custom-btn"
              role="link"
            >
              {/* <i className="noiz-scroll" /> */}
              <span>QUEST BOOK&nbsp;{myQuestsCount}</span>
            </button>
            {(requirementsOutstanding > 0) && (
              <>
                <ReactTooltip
                  place="bottom" // 将 "left" 改为 "bottom"
                  effect="solid"
                  offset={{ top: 10 }} // 将 { left: 10 } 改为 { top: 10 }，在下方留出一定空间
                  delayShow={200}
                />
                <p data-tip="You have quests that require additional action. Click here for more information!">
                  <i
                    className="fa fa-exclamation-circle"
                    onClick={toggleMyQuestsMenu}
                    style={{ color: '#dd4a41', margin: '0px 20px' }}
                  />
                </p>
              </>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
}

export default NavMenuNew;
