import { Observable } from "rxjs/Observable";
import qs from "qs";
import { queryString } from "../utils/functions";

const api = {
  // START NOIZ V2 API CALLS
  getInitialInfo: token =>
    Observable.ajax({
      url: "/api/v1/account/get-initial-info",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getTwitchLanguage: token =>
    Observable.ajax({
      url: "/api/v1/account/twitch-stream-language",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getBroadcast: (broadcast, token) =>
    Observable.ajax({
      url: `/api/v1/user/broadcasts/${broadcast}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateBroadcast: token =>
    Observable.ajax({
      url: "/api/v1/user/broadcasts",
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getGameList: token =>
    Observable.ajax({
      url: "/api/v1/game",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getGame: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/game/${gameId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getUserGameList: (type, page, token, keyword) =>
    Observable.ajax({
      url: `/api/v1/game/all/${type}/${page}/${keyword}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  requestGameKey: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/game/${gameId}/request-game-key`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  joinToBounty: (bountyId, paymentEmail, token) =>
    Observable.ajax({
      url: `/api/v1/bounty/${bountyId}/join`,
      method: "POST",
      body: {
        paymentEmail,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  leaveBounty: (bountyId, token) =>
    Observable.ajax({
      url: `/api/v1/bounty/${bountyId}/leave`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getGameLiveStreams: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/get-game-live-streams/${gameId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getGameBountiesData: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/get-game-bounties-data/${gameId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getBountyPredictions: (bountyId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/bounty/${bountyId}/predictions`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  uploadKeys: (multipartFormData, gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game-key/import/${gameId}`,
      method: "POST",
      body: multipartFormData,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getGameKeys: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game/${gameId}/keys`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  deleteGameKeys: (gameKeyIds, token) =>
    Observable.ajax({
      url: "/api/v1/admin/game-key/",
      method: "DELETE",
      body: {
        gameKeyIds,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getCCGameList: (token, { owner = null, skip = null, limit = null, brand = false }) =>
    Observable.ajax({
      url: `/api/v1/admin/game${queryString({ owner, skip, limit, brand })}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getWhiteList: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game/${gameId}/white-list`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  toggleUserInWhiteList: (gameId, userId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game/${gameId}/white-list/${userId}`,
      method: "PUT",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  createUserAndWhiteListIt: (gameId, username, email, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game/${gameId}/white-list`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  findUsers: (query, token,isLike) =>
    Observable.ajax({
      url: `/api/v1/admin/user/${query}/${isLike}/find`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getGameActivity: (startDate, endDate, gameName, token) =>
    Observable.ajax({
      url: "/api/v1/admin/game/get-game-activity",
      method: "PUT",
      body: {
        start: startDate,
        end: endDate,
        gameName,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  distributeGameKeys: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game-key/distribute/${gameId}`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  assignNewKeyPlatforms: (token, gameId, platformId, keyIds) =>
    Observable.ajax({
      url: `/api/v1/admin/game-key/assign-new-platform/${gameId}`,
      method: "PUT",
      body: {
        platformId,
        keyIds,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),

  // END OF NOIZ V2 API CALLS

  uploadCSV: (multipartFormData, token) =>
    Observable.ajax({
      url: "/api/v1/admin/import/users",
      method: "POST",
      body: multipartFormData,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  // scheduleABroadcast: (scheduledBroadcastInfo, agreementId, token) => Observable.ajax({
  //   url: `/api/v1/user/agreement/${agreementId}/schedule`,
  //   method: 'POST',
  //   body: scheduledBroadcastInfo,
  //   headers: {
  //     Authorization: `JWT ${token}`,
  //     'Content-Type': 'application/json',
  //   },
  // }),
  // deleteScheduledBroadcast: (scheduledBroadcastId, token) => Observable.ajax({
  //   url: `/api/v1/user/scheduled-broadcast/${scheduledBroadcastId}`,
  //   method: 'DELETE',
  //   headers: {
  //     Authorization: `JWT ${token}`,
  //     'Content-Type': 'application/json',
  //   },
  // }),
  // getLastAgreement: token => Observable.ajax({
  //   url: '/api/v1/user/agreement',
  //   method: 'GET',
  //   headers: {
  //     Authorization: `JWT ${token}`,
  //   },
  // }),
  loginWithTwitchOAuthCode: (code, scope, token) =>
    Observable.ajax({
      url: `/api/v1/auth/twitch/callback?${qs.stringify({ code, scope })}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  loginWithTwitterOAuthCode: (oauth_token, oauth_verifier, token) => {
    console.log(oauth_verifier, "verifier");
    return Observable.ajax({
      url: `/api/v1/auth/twitter/callback?${qs.stringify({ oauth_token, oauth_verifier })}`,
      method: "POST",
      body: { oauth_token, oauth_verifier },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
  loginWithYoutubeOAuthCode: (code, scope, token) =>
    Observable.ajax({
      url: `/api/v1/auth/youtube/callback?${qs.stringify({ code, scope })}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateUserInfo: (userInfo, token) =>
    Observable.ajax({
      url: "/api/v1/account/basic-info",
      method: "PUT",
      body: userInfo,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  // <----- Noiz V3 ------>
  signIn: payload =>
    Observable.ajax({
      url: "/api/v1/auth/sign-in",
      method: "POST",
      body: payload,
    }),
  signUp: userInfo =>
    Observable.ajax({
      url: "/api/v1/auth/sign-up",
      method: "POST",
      body: userInfo,
    }),
  recover: identity =>
    Observable.ajax({
      url: "/api/v1/auth/recover",
      method: "POST",
      body: {
        identity,
      },
    }),
  recoverValidate: recoveryToken =>
    Observable.ajax({
      url: "/api/v1/auth/recover/validate",
      method: "POST",
      body: {
        recoveryToken,
      },
    }),
  recoverChangePassword: (recoveryToken, password, rePassword) =>
    Observable.ajax({
      url: "/api/v1/auth/recover/change-password",
      method: "POST",
      body: {
        recoveryToken,
        password,
        rePassword,
      },
    }),
  getQuest: (questId, openModal, token) =>
    Observable.ajax({
      url: `/api/v1/quest/${questId}/${openModal}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  joinQuest: (questId, data, token) =>
    Observable.ajax({
      url: `/api/v1/quest/${questId}/join`,
      method: "POST",
      body: {
        data,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  joinQuestOffplatform: (questId, userId) =>
    Observable.ajax({
      url: `/api/v1/guest/quest/${questId}/join/${userId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  leaveQuest: (questId, token, reason) =>
    Observable.ajax({
      url: `/api/v1/quest/${questId}/leave`,
      method: "POST",
      body: { reason },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  deleteQuest: (questId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}`,
      method: "DELETE",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),

  requestGameKeyByQuest: (questId, token) =>
    Observable.ajax({
      url: `/api/v1/quest/${questId}/request-game-key`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),

  getAdminQuestListByGame: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest-by-game/${gameId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getCCQuestList: token =>
    Observable.ajax({
      url: "/api/v1/admin/quests",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  copyCCQuest: (questId, gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}/copy`,
      method: "POST",
      body: {
        gameId,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getCCQuest: (questId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createCCQuest: (quest, token) =>
    Observable.ajax({
      url: "/api/v1/admin/quest",
      method: "POST",
      body: {
        ...quest,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateCCQuest: (questId, quest, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}`,
      method: "PUT",
      body: {
        ...quest,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAUserList: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/users/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAUserListCsv: (pageNum, filter, sorting, token) =>
    Observable.ajax({
      url: `/api/v1/admin/users/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAUser: (userId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/user/${userId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createAUser: (user, token) =>
    Observable.ajax({
      url: "/api/v1/admin/user",
      method: "POST",
      body: {
        user,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createAUserBrand: (user, token) =>
    Observable.ajax({
      url: "/api/v1/admin/user-brand",
      method: "POST",
      body: {
        user,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateAUser: (userId, user, token) =>
    Observable.ajax({
      url: `/api/v1/admin/user/${userId}`,
      method: "PUT",
      body: {
        user,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAConfigList: token =>
    Observable.ajax({
      url: "/api/v1/admin/config",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAConfig: (configId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/config/${configId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createAConfig: (config, token) =>
    Observable.ajax({
      url: "/api/v1/admin/config",
      method: "POST",
      body: {
        config,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateAConfig: (config, token) =>
    Observable.ajax({
      url: "/api/v1/admin/config",
      method: "PUT",
      body: {
        config,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getSFGame: gameId =>
    Observable.ajax({
      url: `/api/v1/storefront/game/${gameId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  disconnectTwitch: token => Observable.ajax({
    url: '/api/v1/auth/twitch/disconnect',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  disconnectYoutube: token => Observable.ajax({
    url: '/api/v1/auth/youtube/disconnect',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getSFGameBySlug: slug => Observable.ajax({
    url: `/api/v1/storefront/game-by-slug/${slug}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  postGamePayment: (description, source, currency, amount) => Observable.ajax({
    url: '/api/v1/stripe/payment/post',
    method: 'POST',
    body: {
      description,
      source,
      currency,
      amount,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  getUserPayments: token => Observable.ajax({
    url: '/api/v1/user/payments/get',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  updateProfileInfo: (profileInfo, token) => Observable.ajax({
    url: '/api/v1/user/profile',
    method: 'PUT',
    body: {
      ...profileInfo,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  validateUserHandshake: (token, handshake) => Observable.ajax({
    url: `/api/v1/user/profile/validate-handshake/${handshake}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  // sendVerificationCode: (token,verificationType,isVerExp=false) => Observable.ajax({
  //     url: `/api/v1/user/profile/send-vcode`,
  //     method: 'POST',
  //     body:{verificationType,isVerExp},
  //     headers: {
  //         Authorization: `JWT ${token}`,
  //         'Content-Type': 'application/json',
  //     },
  // }),
  sendVerificationCode: (token, verificationType, isVerExp = false) => {
    //console.log('API Called With:', { token, verificationType, isVerExp }); // 添加日志
    return Observable.ajax({
      url: `/api/v1/user/profile/send-vcode`,
      method: 'POST',
      body: { verificationType, isVerExp },
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  validateVerificationCode: (token, verificationCode, verificationType) => Observable.ajax({
    url: `/api/v1/user/profile/validate-vcode`,
    method: 'POST',
    body: { verificationCode, verificationType },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getUserTodayActivity: token => Observable.ajax({
    url: '/api/v1/user/activity/get',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCCQuestActivity: (questId, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/activity`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCCQuestSales: (questId, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/sales`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getQuestByIdentifier: (identifier, token, type = 'slug') => Observable.ajax({
    url: `/api/v1/quest-by-identifier/${identifier}/${type}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getQuestBySlugGuest: (slug, userId) => Observable.ajax({
    url: `/api/v1/quest-by-slug-guest/${slug}/${userId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  getUserReferralsActivity: token => Observable.ajax({
    url: '/api/v1/user/activity/referrals',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  requestReferralCodeByQuest: (questId, token) => Observable.ajax({
    url: `/api/v1/quest/${questId}/request-referral-code`,
    method: 'POST',
    headers: {
      Authorization: `JWT ${token}`,
    },
  }),
  getCCQuestTimed: (questId, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/timed`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getAGameList: (token) => Observable.ajax({
    url: `/api/v1/admin/games/`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getAGame: (gameId, token) => Observable.ajax({
    url: `/api/v3/admin/game/${gameId}`, // v3 to avoid conflict
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  createAGame: (game, token) => Observable.ajax({
    url: '/api/v1/admin/game',
    method: 'POST',
    body: {
      ...game,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  updateAGame: (gameId, game, token) => Observable.ajax({
    url: `/api/v1/admin/game/${gameId}`,
    method: 'PUT',
    body: {
      ...game,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  createAPlatform: (platform, token) => Observable.ajax({
    url: '/api/v1/admin/platform',
    method: 'POST',
    body: {
      ...platform,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getAllPlatforms: token => Observable.ajax({
    url: '/api/v1/admin/platform/get-all',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  updateAFeatured: (featuredGames, token) => Observable.ajax({
    url: '/api/v1/admin/game-featured',
    method: 'PUT',
    body: {
      featuredGames,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCCQuestGeneral: (numPage, pageSize, tab, questId, filtering, type, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/general/${type}/${numPage}/${pageSize}/${filtering}/${tab}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCCQuestGeneralCsv: (numPage, pageSize, tab, questId, filtering, type, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/general/${type}/${numPage}/${pageSize}/${filtering}/${tab}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCCQuestAffiliate: (questId, token) => Observable.ajax({
    url: `/api/v1/admin/quest/${questId}/affiliate`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getUserTimedActivity: token => Observable.ajax({
    url: '/api/v1/user/activity/timed',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  submitLink: (link, questId, token) => Observable.ajax({
    url: '/api/v1/user/submit-link',
    method: 'POST',
    body: {
      link,
      questId,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  submitAffiliateData: (data, stepType, stepTitle, questId, kind, token) => Observable.ajax({
    url: '/api/v1/user/submit-affiliate-data',
    method: 'POST',
    body: {
      data,
      stepType,
      stepTitle,
      questId,
      kind,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  submitRequirementData: (data, token) => Observable.ajax({
    url: '/api/v1/user/submit-requirement-data',
    method: 'POST',
    body: {
      data,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  checkRequirementData: (reqId, checked, type, title, questId, approvalRequired, token) => Observable.ajax({
    url: '/api/v1/user/check-requirement-data',
    method: 'POST',
    body: {
      reqId,
      checked,
      type,
      title,
      questId,
      approvalRequired,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  postCCPreapproval: (questId, userId, status, tab, numPage, pageSize, token) => Observable.ajax({
    url: '/api/v1/quest/preapproval',
    method: 'POST',
    body: {
      questId,
      userId,
      status,
      tab,
      numPage,
      pageSize,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  postCCRequirement: (data, token) => Observable.ajax({
    url: '/api/v1/quest/requirement',
    method: 'POST',
    body: {
      data,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  postCCRequirementMultiple: (data, questId, token) => Observable.ajax({
    url: '/api/v1/quest/requirement-multiple',
    method: 'POST',
    body: {
      data,
      questId,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getMyQuestsPageData: token => Observable.ajax({
    url: '/api/v1/user/my-quests',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  getUserTickets: token => Observable.ajax({
    url: '/api/v1/user/get-user-tickets',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  postCCAffiliateResolution: (data, token) => Observable.ajax({
    url: '/api/v1/quest/affiliate-resolution',
    method: 'POST',
    body: {
      data,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  verifyYoutubeChannelStreamStatus: (questId, token) => Observable.ajax({
    url: `/api/v1/streaming/youtube/status/${questId}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  // START NOIZ V4 API CALLS
  getOptimizedQuests: (filterType, page, token) =>
    Observable.ajax({
      url: `/api/v4/quests?page=${page}&filterType=${filterType}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getGuestQuests: () =>
    Observable.ajax({
      url: "/api/v4/guest/quests",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestTiered: (questId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}/tiered`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCCQuestTieredMultipleDays: (questId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/quest/${questId}/tiered-multiple-days`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  requestUpdateAvatarUrl: (token, format, type) =>
    Observable.ajax({
      url: `/api/v1/user/request-update-avatar-url/`,
      method: "POST",
      body: {
        format,
        type
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  requestUploadImageUrl: (slug, format, token) =>
    Observable.ajax({
      url: `/api/v1/admin/request-upload-image-url/${slug}/${format}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  requestUploadCCImageUrl: (slug, type, token) =>
    Observable.ajax({
      url: `/api/v1/admin/request-upload-image-url/${slug}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getATrackedGames: token =>
    Observable.ajax({
      url: "/api/v1/admin/game-tracked",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCCCampaignListByGame: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/campaign-by-game/${gameId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCCCampaign: (gameId, campaignId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/campaign/${gameId}/${campaignId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateCCCampaign: (campaignId, campaignData, token) =>
    Observable.ajax({
      url: `/api/v1/admin/campaign/${campaignId}`,
      method: "PUT",
      body: campaignData,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateCCGamesPlatform: (gameIds, platformIds, token) =>
    Observable.ajax({
      url: "/api/v2/admin/game/update-platforms",
      method: "PUT",
      body: {
        gameIds,
        platformIds,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateCCQuestsPlatform: (questIds, platformIds, token) =>
    Observable.ajax({
      url: "/api/v2/admin/quest/update-platforms",
      method: "PUT",
      body: {
        questIds,
        platformIds,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createCCCampaign: (campaignData, token) =>
    Observable.ajax({
      url: "/api/v1/admin/campaign",
      method: "POST",
      body: campaignData,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getBUCampaignList: token =>
    Observable.ajax({
      url: "/api/v1/user-brand/campaigns",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getBUCampaign: (campaignId, startDate, endDate, token) =>
    Observable.ajax({
      url: "/api/v1/user-brand/campaign",
      method: "PUT",
      body: {
        campaignId,
        startDate,
        endDate,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getACampaignList: token =>
    Observable.ajax({
      url: "/api/v1/admin/brand-campaigns",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getACampaign: (campaignId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/brand-campaign/${campaignId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getACampaignQuestsReport: (campaignId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/brand-campaign/${campaignId}/quests-report`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getStoreGames: token =>
    Observable.ajax({
      url: "/api/v1/store/games",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  addCCUserQuest: (userId, questId, status, token) =>
    Observable.ajax({
      url: "/api/v1/admin/quest/add-user-quest",
      method: "POST",
      body: {
        userId,
        questId,
        status,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  removeCCUserQuest: (userId, questId, token) =>
    Observable.ajax({
      url: "/api/v1/admin/quest/remove-user-quest",
      method: "POST",
      body: {
        userId,
        questId,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  moveCCUserQuest: (userId, fromQuestId, toQuestId, status, token) =>
    Observable.ajax({
      url: "/api/v1/admin/quest/move-user-quest",
      method: "POST",
      body: {
        userId,
        fromQuestId,
        toQuestId,
        status,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCCActiveQuestsByGame: (gameId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/game/${gameId}/get-active-quests`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAQuestList: token =>
    Observable.ajax({
      url: "/api/v1/admin/quests",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAPaymentList: (pageNum, filter, sorting, token) =>
    Observable.ajax({
      url: `/api/v1/admin/payments/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getAPaymentListCsv: (pageNum, filter, sorting, token) =>
    Observable.ajax({
      url: `/api/v1/admin/payments/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getPaypalPayments: token =>
    Observable.ajax({
      url: "/api/v1/paypal/payments",
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createPromoLinks: (users, campaign, destinationUrl, domain, slashtagOverride, preassignedLinks, token) =>
    Observable.ajax({
      url: "/api/v1/link/links",
      method: "POST",
      body: {
        users,
        campaign,
        destinationUrl,
        domain,
        slashtagOverride,
        preassignedLinks,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  deleteLinks: (assignedLinks, unassignedLinks, token) =>
    Observable.ajax({
      url: "/api/v1/link/links",
      method: "DELETE",
      body: {
        assignedLinks,
        unassignedLinks,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  uploadLinks: (multipartFormData, campaignId, token) =>
    Observable.ajax({
      url: `/api/v1/link/links/upload-csv/${campaignId}`,
      method: "POST",
      body: multipartFormData,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  assignCustomLinks: (campaignId, token) =>
    Observable.ajax({
      url: "/api/v1/link/links/assign-custom",
      method: "POST",
      body: {
        campaignId,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  assignMasterLinks: (campaignId, token) =>
    Observable.ajax({
      url: "/api/v1/link/links/assign-master",
      method: "POST",
      body: {
        campaignId,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getLinksDashboard: (campaignId, token) =>
    Observable.ajax({
      url: `/api/v1/admin/campaign/links/${campaignId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  postReputationRating: (questId, userId, reputationObj, token) =>
    Observable.ajax({
      url: "/api/v1/admin/reputation-system/update",
      method: "POST",
      body: { questId, userId, reputationObj },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  createOffPlatformUser: (twitchLogin, email, paymentEmail, first, last, token) =>
    Observable.ajax({
      url: "/api/v1/admin/user/off-platform",
      method: "POST",
      body: { twitchLogin, email, paymentEmail, first, last },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getBrandGame: (
    {
      gameId = '',
      gameQuery = null,
      numPage = 1,
      pageSize = 10,
      sort = "desc",
      statusGame = "",
    },
    token,
  ) =>
    Observable.ajax({
      url: `/api/v1/user-brand/game/get${queryString(
        {
          gameId,
          gameQuery,
          numPage,
          pageSize,
          sort,
          statusGame,
        }
      )}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getBrandQuest: (
    {
      questId = null,
      gameQuery = null,
      questQuery = null,
      statusQuest = 'all',
      startDate,
      endDate,
      sort = 'desc',
      numPage = 1,
      pageSize = 10,
      statusGame = '',
    },
    token,
  ) =>
    Observable.ajax({
      url: `/api/v1/user-brand/quest/get${queryString({
        sort,
        questId,
        numPage,
        pageSize,
        statusGame,
        gameQuery,
        questQuery,
        statusQuest,
        startDate,
        endDate,
      })}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getBrandStreamer: (username, token) =>
    Observable.ajax({
      url: `/api/v1/user-brand/streamer/get/${username}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }),

  getBrandUserQuest: (userQuestId, token) =>
    Observable.ajax({
      url: `/api/v1/user-brand/quest/requirement/${userQuestId}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }),

  brandUserUploadImageUrl: (format, token) =>
    Observable.ajax({
      url: `/api/v1/user-brand/request-upload-image-url/${format}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  postBrandGame: (game, token) =>
    Observable.ajax({
      url: '/api/v1/user-brand/game',
      method: 'POST',
      body: game,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }),

  postBrandPreapproval: (userQuestIds, status, token) => Observable.ajax({
    url: '/api/v1/user-brand/quest/preapproval',
    method: 'POST',
    body: {
      userQuestIds,
      status,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  postBrandQuest: (quest, token) =>
    Observable.ajax({
      url: "/api/v1/user-brand/quest/",
      method: "POST",
      body: quest,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  postBrandRequirement: (requirement, token) => Observable.ajax({
    url: '/api/v1/user-brand/quest/requirement',
    method: 'POST',
    body: requirement,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  putBrandGame: (game, token) =>
    Observable.ajax({
      url: `/api/v1/user-brand/game/${game.id}`,
      method: 'PUT',
      body: game,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }),

  putBrandQuest: (quest, token) =>
    Observable.ajax({
      url: `/api/v1/user-brand/quest/${quest.id}`,
      method: "PUT",
      body: { ...quest },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  postApproveQuest: (data, token) => Observable.ajax({
    url: '/api/v1/admin/quest/approve',
    method: 'POST',
    body: {
      data,
    },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),

  getUserWallet: token => Observable.ajax({
    url: '/api/v1/user/get-user-wallet',
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  withdrawWallet: (amount, token) => Observable.ajax({
    url: '/api/v1/user/add-user-wallet-order',
    method: 'POST',
    body: { amount },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getWalletRollOutRecord: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-wallet-roll-out/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getWalletRollOutCsv: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-wallet-roll-out/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getWalletRollInRecord: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-wallet-roll-in/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getWalletRollInCsv: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-wallet-roll-in/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  addRollInOrder: (amount, questAmount, fee, questId, token) =>
    Observable.ajax({
      url: `/api/v1/user/add-user-wallet-in-order`,
      method: "POST",
      body: { amount, questAmount, fee, questId },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollOutRecord: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-out/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollOutCsv: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-out/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  approveRollOut: (orderId, approveType, token) =>
    Observable.ajax({
      url: `/api/v1/user/update-wallet-order`,
      method: "POST",
      body: { orderId, approveType },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollOutRecordAdmin: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-out/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollInCsv: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-in/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollInRecordAdmin: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-in/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getRollOutAll: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-wallet-roll-out/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  showRollOutPay: (orderId, token) =>
    Observable.ajax({
      url: `/api/v1/paypal/withdrawal`,
      method: "POST",
      body: { orderId },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getQuestLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-quest/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getQuestLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-quest/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getProfileLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-profile/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getProfileLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-profile/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getWalletLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-wallet/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getWalletLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-wallet/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getCDKeyLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-cdkey/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getCDKeyLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-cdkey/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getDailyTaskLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-dailytask/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getDailyTaskLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-dailytask/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getChannelLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-channel/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getChannelLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-channel/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getOtherLog: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-other/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getOtherLogCsv: (pageNum, search, filter, sorting, token, type) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-other/${pageNum}/${search}/${filter}/${sorting}/${type}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getLogSummary: (token) =>
    Observable.ajax({
      url: `/api/v1/admin/get-log-summary`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  createRegion: (region, canAdd, token) =>
    Observable.ajax({
      url: "/api/v1/admin/add-region",
      method: "POST",
      body: {
        region,
        canAdd,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  getRegions: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/get-region/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  createRegions: (regions, token) =>
    Observable.ajax({
      url: "/api/v1/admin/add-regions",
      method: "POST",
      body: {
        regions,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  updateRegion: (regionId, regionName, active, country, countryConfirm, token) =>
    Observable.ajax({
      url: "/api/v1/admin/update-region",
      method: "POST",
      body: {
        regionId,
        regionName,
        active,
        country,
        countryConfirm,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  updateRegions: (regions, token) =>
    Observable.ajax({
      url: "/api/v1/admin/update-regions",
      method: "POST",
      body: {
        regions,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),

  createCDKeyProduct: (gameId, cdKeyProductName, cdKeyProductPlatform, cdKeyProductPrice,cdKeyProductDiscount,cdKeyProductSalePrice, token) =>
    Observable.ajax({
      url: `/api/v1/admin/cd-key-product/${gameId}`,
      method: "POST",
      body: {
        cdKeyProductName,
        cdKeyProductPlatform,
        cdKeyProductPrice,
          cdKeyProductDiscount,
          cdKeyProductSalePrice,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  getCDKeyProducts: (gameId, pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/cd-key-products/${gameId}/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updateCDKeyProduct: (cdKeyProductId, cdKeyProductName, active, cdKeyProductPlatform, cdKeyProductPrice, cdKeyProductDiscount, token) =>
    Observable.ajax({
      url: "/api/v1/admin/cd-key-product-update",
      method: "POST",
      body: {
        cdKeyProductId,
        cdKeyProductName,
        active,
        cdKeyProductPlatform,
        cdKeyProductPrice,
          cdKeyProductDiscount,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  uploadCDKeys: (multipartFormData, CDKeyProductId,isIgon, token) =>
    Observable.ajax({
      url: `/api/v1/admin/cd-key/import/${CDKeyProductId}/${isIgon}`,
      method: "POST",
      body: multipartFormData,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  deleteCDKeys: (CDKeyIds, token) =>
    Observable.ajax({
      url: "/api/v1/admin/cd-key/",
      method: "DELETE",
      body: {
        CDKeyIds,
      },
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  buyCDK: (productId, price, amount, payType, purchaseType, doneeEmail, token) => Observable.ajax({
    url: '/api/v1/user/add-cdk-order',
    method: 'POST',
    body: { productId, price, amount, payType, purchaseType, doneeEmail },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCDKOrders: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-cdk-order/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  cancelCDKOrder: (orderId, token) => Observable.ajax({
    url: `/api/v1/user/cancel-cdk-order/${orderId}`,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  payCDKOrder: (orderId, payType, token) => Observable.ajax({
    url: '/api/v1/user/pay-cdk-order',
    method: 'POST',
    body: { orderId, payType },
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  getCDK: (orderId) => Observable.ajax({
    url: `/api/v1/user/get-cdk/${orderId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  showCDKPay: (orderId, token) =>
    Observable.ajax({
      url: `/api/v1/paypal/cdk-pay`,
      method: "POST",
      body: { orderId },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKOrderRecord: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-cdk-orders/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKOrderRecordCsv: (pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-user-cdk-orders/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKOrderRecords: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/get-cdk-orders/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKOrderRecordsCsv: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/get-cdk-orders/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKRecords: (gameId, pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/get-cdk-records/${gameId}/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getCDKProducts: (gameId, pageNum, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/user/get-cdk-products/${gameId}/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  getPermissions: (pageNum, search, filter, sorting, token, exactMatch) =>
    Observable.ajax({
      url: `/api/v1/admin/get-permission/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
  updatePermissions: (permissions, token) =>
    Observable.ajax({
      url: "/api/v1/admin/update-permissions",
      method: "POST",
      body: {
        permissions,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    }),
    getAdmins: (pageNum, search, filter, sorting, token, exactMatch) =>
        Observable.ajax({
            url: `/api/v1/admin/get-admin/${pageNum}/${search}/${filter}/${sorting}/${exactMatch}`,
            method: "GET",
            headers: {
                Authorization: `JWT ${token}`,
                "Content-Type": "application/json",
            },
        }),
    updateAdmins: (admins, token) =>
        Observable.ajax({
            url: "/api/v1/admin/update-admins",
            method: "POST",
            body: {
                admins,
            },
            headers: {
                Authorization: `JWT ${token}`,
                "Content-Type": "application/json",
            },
        }),
};

export default api;

// const brandGame = (req) => {
//   queryString: 'Super Mario Bros',
//   sort: 'createdAt DESC' || 'createdAt ASC',
//   numPage: 1,
//   page_size: 10,
//   statusGame = 'active' || 'in progress' || 'archived' || 'disabled'
// }

// 'GET /api/v1/user-brand/game/get': 'user-brand/game/get',
// 'GET /api/v1/user-brand/quest/get': 'user-brand/quest/get',

// const brandQuest = (req) => {
//   questString: 'Super Mario Bros Super Quest',
//   sort: 'createdAt DESC' || 'createdAt ASC',
//   numPage: 1,
//   page_size: 10,
//   statusQuest = 'active' || 'in progress' || 'archived' || 'disabled',
//   startDate: '2020-01-01' // iso date,
//   endDate: '2020-02-01' // iso date
// }
