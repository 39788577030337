/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import Axios from 'axios';
import { simpleNotification } from '../../../../utils/notifications';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
  TableBody,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  DialogContent,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Utils
import {
  keyDistributionStepExists,
  getTaskCompletionStatus,
  getSorting,
  stableSort,
  isAutoQuestEditMoney,
  getLocalTimeString
} from '../../../../utils/functions';


// Styles
import './QuestPaymentsTableNew.scss';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class QuestPaymentsTableNew extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { showApprovalModal, userQuests, quest } = props;

    const questStatus = getTaskCompletionStatus(quest);

    const qualifiedUserQuests = questStatus.status !== 'P' ? userQuests : [];

    this.state = {
      orderBy: 'dateAccepted',
      order: 'asc',
      selected: [],
      isDialogOpen: false,
      totalAmount: 0,
      currentUserQuestData: qualifiedUserQuests || [],
      showApprovalModal: showApprovalModal,
      splitAmount: 0,//瓜分个人总金额
      rankAmount: 0,//排行榜个人总金额
      currentUserQuestId: null,
      showSuspendConfirm: false,  // 新增，用于显示挂起确认弹窗
      showPaymentTimeModal: false, // 新增，用于显示设置预期支付时间模态框
      paymentTime: "", // 当前输入的预期支付时间，格式："YYYY-MM-DDTHH:mm"
      paymentTimeModalUsage: "paymentTime"
    };

    this.splitInputRef = React.createRef();
    this.rankInputRef = React.createRef();
  }

  handleClose = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  //TODO: 需要加入将选中账户的钱集中打给到noiz账户中去

  submitMoney = () => {


    this.handleClose()
  }

  handleOpen = () => {
    const { selected } = this.state
    const selectedUsers = this.getTabUsers().filter(user => selected.includes(user.id))

    const users = selectedUsers.map(user => ({
      userId: user.id,
      paymentAmount: user.paymentAmount,
      userame: user.username,
      email: user.email,
    }));
    let total = 0
    users.forEach(user => {
      total += user.paymentAmount
    });
    this.setState({
      isDialogOpen: true,
      totalAmount: total
    })
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
    });
  }

  // Sort & define user rows based on quest type & sorting state; return elements to display
  getUserRows = (users) => {
    const { quest } = this.props;
    const { order, orderBy } = this.state;

    const sortedUsers = stableSort(users, getSorting(order, orderBy))
    const questStatus = getTaskCompletionStatus(quest)
    return sortedUsers.map(user => {
      return (
        <React.Fragment key={user.id}>
          <TableRow hover tabIndex={-1}>
            <TableCell align="center">{user.user ? user.user.username : '-'}</TableCell>
            <TableCell align="center">{user.user ? user.user.connectedAccounts.twitch.username : '-'}</TableCell>
            <TableCell align="center">${user?.splitAmount}</TableCell>
            <TableCell align="center">${user?.leaderboardAmount}</TableCell>
            <TableCell align="center">${user?.splitAmount + user?.leaderboardAmount}</TableCell>
            <TableCell align="center">
              <span style={{
                color: questStatus.status === 'P' ? '#ffffff' :
                  questStatus.status === 'E' ? '#4caf50' :
                    questStatus.status === 'C' ? '#2196f3' : '#ffffff'
              }}>
                {questStatus.statusString}
              </span>
            </TableCell>
            <TableCell align="center">{quest.expectedPayoutDate ? getLocalTimeString(quest.expectedPayoutDate) : '-'}</TableCell>
            {!isAutoQuestEditMoney(quest) ? null : (
              <TableCell align="center">
                <Tooltip title="Edit Amount" placement="top">
                  <div >
                    <button className="small-btn" onClick={() => this.openApprovalModal(user?.splitAmount, user?.leaderboardAmount, user?.id)}><span style={{ fontSize: '14px' }}>$</span></button>
                  </div>
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        </React.Fragment>
      );
    });
  }

  rounding = number => parseFloat(number).toFixed(2);

  // Define table headers based on quest type; return array of headers
  getTableHeaders = (questType, platform, keyDistribute) => {
    let tableHeaders = [];


    switch (questType) {
      case 'tiered':
      case 'tiered-one-time':

        break;
      default:
        tableHeaders = [
          {
            id: 'username', numeric: false, disablePadding: false, label: 'Noiz',
          },
          {
            id: 'twitchName', numeric: false, disablePadding: false, label: 'Twitch',
          },
          {
            id: 'tierAmount', numeric: true, disablePadding: true, label: 'Tier Amount',
          },
          {
            id: 'rankAmount', numeric: true, disablePadding: true, label: 'Rank Amount',
          },
          {
            id: 'totalAmount', numeric: true, disablePadding: true, label: 'Total Amount',
          },
          {
            id: 'completionStatus', numeric: false, disablePadding: false, label: 'Status',
          },
          {
            id: 'paymentTime', numeric: false, disablePadding: false, label: 'Payment Time',
          },
          {
            id: 'actions', numeric: false, disablePadding: false, label: 'Actions',
          }
        ];
        break;
    }

    // 如果任务已经结束，则不显示 actions 列
    if (!isAutoQuestEditMoney(this.props.quest)) {
      tableHeaders = tableHeaders.filter(header => header.id !== 'actions');
    }

    return tableHeaders;
  }

  renderFirstRow = () => {
    return (
      <div className="Global__subTabs" style={{ padding: '30px 30px 10px 30px' }}>
        <button className="platform-twitch-btn active" data-platform="twitch">
          <svg className="platform-twitch-btn-icon" viewBox="0 0 24 24">
            <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
          </svg>
          Twitch
        </button>
      </div>
    );
  }

  renderSecondRow = () => {

    const { quest } = this.props;
    const questStatus = getTaskCompletionStatus(quest);
    const disableActions = quest && questStatus.status === 'E';
    //如果任务的状态是中止状态，也不能设置时间
    const disableActionsPaymentTime = disableActions || quest.suspendAutoFlag;
    const disabledButtonStyle = {
      backgroundColor: '#ccc', // 使用灰色背景表示禁用
      pointerEvents: 'none',
      // opacity: 0.9
    };
    return (
      <div className="Global__subTabs" style={{ padding: '0px 30px 10px 30px' }}>
        <div className='SecondRowNew'>
          {!isAutoQuestEditMoney(quest) ? null : (
            <button className='SecondRowNew__left'
              disabled={disableActionsPaymentTime}
              onClick={this.openPaymentTimeModal}
              style={disableActionsPaymentTime ? disabledButtonStyle : {}}>
              Payment time
            </button>
          )}
          {!isAutoQuestEditMoney(quest) ? null : (
            <button
              className={quest.suspendAutoFlag ? 'SecondRowNew__test1' : 'SecondRowNew__right'}
              disabled={disableActions}
              onClick={this.handleTaskSuspendState}
              style={disableActions ? disabledButtonStyle : {}}>
              {quest.suspendAutoFlag ? 'Resume Payment' : 'Suspend Payment'}
            </button>
          )}

          <button className='SecondRowNew__test1' onClick={this.handleTestEndTask}>
            测试任务结束
          </button>
          <button className='SecondRowNew__test2' onClick={this.handleTestEndAutoCheckoutTask}>
            测试任务自动支付
          </button>
        </div>
      </div>
    );
  }

  // 打开设置预期支付时间模态框，并使输入框默认显示 quest.expectedPayoutDate
  openPaymentTimeModal = (usage = "paymentTime") => {
    const { quest } = this.props;
    console.log("expectedPayoutDate", quest.expectedPayoutDate);
    const defaultPaymentTime = quest.expectedPayoutDate
      ? moment(quest.expectedPayoutDate).tz('America/Los_Angeles').format("YYYY-MM-DDTHH:mm")
      : "";
    this.setState({
      showPaymentTimeModal: true,
      paymentTime: defaultPaymentTime,
      paymentTimeModalUsage: usage
    });
  }

  // 新增方法，用于在挂起确认弹窗点击 Confirm 时，根据当前场景作判断
  handleConfirmTaskSuspend = () => {
    const { quest } = this.props;
    if (quest.suspendAutoFlag) {
      // resume 场景：先关闭 suspend 确认框，再打开 Payment Time 模态框（用 resume 用法）
      this.setState({ showSuspendConfirm: false }, () =>
        this.openPaymentTimeModal("resume")
      );
    } else {
      // suspend 场景：直接调用确认的方法
      this.confirmTaskSuspendState();
    }
  };

  // 关闭设置预期支付时间模态框
  closePaymentTimeModal = () => {
    this.setState({ showPaymentTimeModal: false });
  }

  // 在预期支付时间模态框点击 Save 后调用
  handlePaymentTimeSave = async () => {
    // 此处可以添加对 paymentTime 的验证
    await this.handleEndAutoCheckoutTask();
    this.closePaymentTimeModal();
  }

  renderPaymentTimeModal = () => {
    return (
      <Dialog
        open={this.state.showPaymentTimeModal}
        PaperProps={{
          style: {
            backgroundColor: '#2A2A2A',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            color: '#4FD1C5'
          }
        }}
      >
        <DialogTitle className="dialogTitle">
          Set Expected Payment Time
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div style={{ marginBottom: '10px', fontSize: '16px' }}>
            Please set the expected payout time:
          </div>
          <input
            type="datetime-local"
            value={this.state.paymentTime}
            onChange={e => this.setState({ paymentTime: e.target.value })}
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '1px solid #4FD1C5',
              backgroundColor: '#212121',
              color: '#FFF'
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: '20px', gap: '20px' }}>
          <Button
            onClick={this.closePaymentTimeModal}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#f44336',
              color: '#FFF',
              borderRadius: '8px',
              padding: '10px 50px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.state.paymentTimeModalUsage === "resume"
              ? this.confirmTaskSuspendState
              : this.handlePaymentTimeSave}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#4FD1C5',
              color: '#213231',
              borderRadius: '8px',
              padding: '10px 50px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // 修改 handleTaskSuspendState，仅用于打开确认弹窗
  handleTaskSuspendState = () => {
    this.setState({ showSuspendConfirm: true });
  }

  // 新增方法：用户在确认弹窗点击 Confirm 后执行真正的 suspend/resume 操作
  confirmTaskSuspendState = async () => {
    const { quest, updateQuest } = this.props;
    const { token } = this.props.auth;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    let message = quest.suspendAutoFlag ? 'Task auto checkout Resume' : 'Task auto checkout Pause';
    let body = { questId: quest.id, suspendAutoFlag: !quest.suspendAutoFlag };

    // 如果是恢复支付场景（resume），加入预期支付时间到请求体中
    if (this.state.paymentTimeModalUsage === "resume") {
      body.expectedPayoutDate = this.state.paymentTime;
    }
    try {
      await Axios.post(`/api/v1/quest/suspend`, body, config);
      simpleNotification({
        level: 'success',
        title: 'Task Update',
        message: `${message}成功`,
      });
      // 执行更新操作
      updateQuest(quest.id, token);
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Task Update Failed',
        message: `${message}失败`,
      });
    }
    // 关闭确认弹窗
    this.setState({ showSuspendConfirm: false });
  }

  // 新增确认弹窗，格式参考 renderShowApprovalModal
  renderSuspendConfirmModal = () => {
    const { quest } = this.props;
    const actionText = quest.suspendAutoFlag ? 'Resume Payment' : 'Suspend Payment';
    const confirmMsg = quest.suspendAutoFlag
      ? 'Are you sure you want to resume payment?'
      : 'Are you sure you want to suspend payment?';
    return (
      <Dialog
        open={this.state.showSuspendConfirm}
        PaperProps={{
          style: {
            backgroundColor: '#2A2A2A',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            color: '#4FD1C5'
          }
        }}
      >
        <DialogTitle className="dialogTitle">
          Confirm {actionText}
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Typography variant="body1" style={{ fontSize: '18px', color: '#FFF' }}>
            {confirmMsg}
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: '20px', gap: '20px' }}>
          <Button
            onClick={() => this.setState({ showSuspendConfirm: false })}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#f44336',
              color: '#FFF',
              borderRadius: '8px',
              padding: '10px 50px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleConfirmTaskSuspend}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#4FD1C5',
              color: '#213231',
              borderRadius: '8px',
              padding: '10px 50px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleEndAutoCheckoutTask = async () => {
    const { quest, updateQuest } = this.props;
    const { token } = this.props.auth;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let body = {
      questId: quest.id,
      expectedPayoutDate: this.state.paymentTime
    };
    try {
      await Axios.post(`/api/v1/quest/payoutdate`, body, config);
      simpleNotification({
        level: 'success',
        title: 'Quest Update',
        message: 'Quest auto checkout set successfully',
      });
      // 刷新当前页面，重新更新 quest 数据
      updateQuest(quest.id, token);
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Quest Update Failed',
        message: 'Quest auto checkout set failed',
      });
    }
  }

  handleTestEndAutoCheckoutTask = async () => {
    const { quest, updateQuest } = this.props;
    const { token } = this.props.auth;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let body = { questId: quest.id };
    try {
      await Axios.post(`/api/v1/user/test-update-quest-auto-checkout`, body, config);
      simpleNotification({
        level: 'success',
        title: 'Test Update',
        message: '任务自动结算设置成功',
      });
      // 刷新当前页面，重新更新 quest 数据
      updateQuest(quest.id, token);
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Test Update Failed',
        message: '任务自动结算设置失败',
      });
    }
  }

  handleTestEndTask = async () => {
    const { quest, updateQuest } = this.props;
    const { token } = this.props.auth;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let body = { questId: quest.id };
    try {
      await Axios.post(`/api/v1/user/test-update-quest-end`, body, config);
      simpleNotification({
        level: 'success',
        title: 'Test Update',
        message: '任务结束设置成功',
      });
      // 刷新当前页面，重新更新 quest 数据
      updateQuest(quest.id, token);
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Test Update Failed',
        message: '任务结束设置失败',
      });
    }
  }

  openApprovalModal = (split, rank, userQuestId) => {

    this.setState({
      showApprovalModal: true,
      splitAmount: split ? split : 0,//赋值到瓜分金额中去
      rankAmount: rank ? rank : 0,//赋值到排行榜金额中去
      currentUserQuestId: userQuestId,
    });
  }

  closeApprovalModal = () => {
    this.setState({
      showApprovalModal: false,
    });
  }

  /**
   * Updates the user's quest amount data including split amount and leaderboard amount.
   *
   * @async
   * @function updateUserquestAmountData
   * @param {number} userQuestId - The ID of the user quest to update.
   * @returns {Promise<void>} - A promise that resolves when the update is complete.
   * @throws Will display a notification if the update fails.
   *
   * @description
   * This function sends a POST request to update the user's quest amount data on the server.
   * It updates the local state with the new data if the request is successful and displays a success notification.
   * If the request fails, it displays an error notification.
   */
  async updateUserquestAmountData(userQuestId) {
    const { user, token } = this.props.auth;
    const { quest } = this.props;
    const { splitAmount, rankAmount, currentUserQuestData } = this.state;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let body = { leaderboardAmount: rankAmount, splitAmount: splitAmount, userId: user.id, questId: quest.id };
    try {
      const response = await Axios.post('/api/v1/quest/userquestAmount', body, config);
      // Find index of userquest to update
      const updateIndex = currentUserQuestData.findIndex(
        uq => uq.id === userQuestId
      );
      if (updateIndex !== -1) {
        // Create new array with updated data
        const newUserQuest = response.data.data[0];
        const updatedUserQuests = currentUserQuestData.map((uq, index) => {
          if (uq.id === userQuestId) {
            return {
              ...uq,
              leaderboardAmount: newUserQuest.leaderboardAmount,
              splitAmount: newUserQuest.splitAmount,
            };
          }
          return uq;
        });
        this.setState({ currentUserQuestData: updatedUserQuests });
      }
      simpleNotification({
        level: 'success',
        title: 'Amount Successfully Updated',
        message: 'Chagne success!',
      });
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Something Went Wrong',
        message: 'If problem persists, please contact dev team',
      });
    }
  }

  //获取失败用户列表
  getApprovalRows = () => {
    const { approvalData } = this.props;
    return approvalData.filter((item) => !item.status).map((item, index) => {
      return (
        <TableRow
          key={item.id || index}
          style={{
            backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'transparent',
            borderBottom: '1px solid #E0E0E0',
          }}>
          <TableCell align="center" style={{ color: '#424242', padding: '12px' }}>{item.username ? item.username : '-'}</TableCell>
          <TableCell align="left" style={{ color: '#424242', padding: '12px' }}>{item.message ? item.message + item.message + item.message + item.message : '-'}</TableCell>
        </TableRow>
      );
    });
  }

  /**
   * Handles saving changes by updating the user quest amount data and closing the approval modal.
   *
   * @async
   * @function handleSaveChanges
   * @returns {Promise<void>} - A promise that resolves when the save operation is complete.
   */
  handleSaveChanges = async () => {
    const { currentUserQuestId } = this.state;
    // Check if either amount is empty (or not a valid number if needed)
    const splitValue = this.splitInputRef.current.value;
    const rankValue = this.rankInputRef.current.value;
    if (splitValue === "" || rankValue === "") {
      simpleNotification({
        level: 'warning',
        title: 'Invalid Input',
        message: 'amount is not valid',
      });
      return;
    }

    // Optionally update the state before making the API call.
    this.setState({
      splitAmount: splitValue,
      rankAmount: rankValue,
    }, async () => { // This callback executes after state has been updated.
      await this.updateUserquestAmountData(currentUserQuestId);
      this.closeApprovalModal();
    });
  }

  renderShowApprovalModal() {
    const { splitAmount, rankAmount } = this.state
    return (
      <Dialog
        open={this.state.showApprovalModal}
        PaperProps={{
          style: {
            backgroundColor: '#2A2A2A', // dark background to match the style
            borderRadius: '10px', // rounded corners
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            color: '#4FD1C5'
          }
        }}
      >
        {/* Title */}
        <DialogTitle className="dialogTitle">
          Amount Editor
        </DialogTitle>

        {/* Content with input fields */}
        <DialogContent style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {/* Tier Amount Input */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px', color: '#FFF' }}>
              <div>Tier Amount</div>
              <div style={{ fontSize: '22px', color: '#4FD1C5' }}>${splitAmount}</div>
            </div>
            <div style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #4FD1C5',
              borderRadius: '8px',
              backgroundColor: '#212121',
            }}
            >
              <div style={{
                position: 'absolute',
                left: '10px',
                color: '#4FD1C5',
                fontSize: '18px',
              }}>$</div>
              <input
                ref={this.splitInputRef}
                type="text"
                placeholder="Enter new amount"
                style={{
                  padding: '12px 12px 12px 30px', // Add left padding for the $ symbol
                  fontSize: '18px',
                  border: 'none',
                  borderRadius: '8px',
                  backgroundColor: '#212121',
                  color: '#FFF',
                  width: '100%',
                }}
              />
            </div>

            {/* Rank Amount Input */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px', color: '#FFF' }}>
              <div>Rank Amount</div>
              <div style={{ fontSize: '22px', color: '#00BFAE' }}>${rankAmount}</div>
            </div>
            <div style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #4FD1C5',
              borderRadius: '8px',
              backgroundColor: '#212121',
            }}>
              <div style={{
                position: 'absolute',
                left: '10px',
                color: '#4FD1C5',
                fontSize: '18px',
              }}>$</div>
              <input
                ref={this.rankInputRef}
                type="text"
                placeholder="Enter new amount"
                style={{
                  padding: '12px 12px 12px 30px', // Add left padding for the $ symbol
                  fontSize: '18px',
                  border: 'none',
                  borderRadius: '8px',
                  backgroundColor: '#212121',
                  color: '#FFF',
                  width: '100%',
                }}
              />
            </div>
          </div>
        </DialogContent>

        {/* Confirm Button */}
        <DialogActions style={{ justifyContent: 'center', padding: '20px', gap: '20px' }}>
          <Button
            onClick={this.closeApprovalModal}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#f44336', // Material red
              color: '#FFF',
              borderRadius: '8px',
              padding: '10px 50px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleSaveChanges}
            style={{
              fontSize: '16px',
              fontWeight: '600',
              backgroundColor: '#4FD1C5',
              color: '#213231',
              borderRadius: '8px',
              padding: '10px 0',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.5)',
              textTransform: 'none',
              textAlign: 'center',
              width: '48%',
              height: '45px'
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      questType, quest
    } = this.props;
    const { twitchAccountRequired } = quest;
    const { isDialogOpen, totalAmount, currentUserQuestData } = this.state;

    let platform = 'twitch';
    if (twitchAccountRequired) {
      platform = 'twitch';
    }

    const currentTabUsers = currentUserQuestData

    const keyDistribute = quest.keyRequired && keyDistributionStepExists(quest);

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="ToggleTable">
        <React.Fragment>
          {this.renderFirstRow()}
          {this.renderSecondRow()}
          {currentTabUsers.length ?
            (<Table>
              <TableHead>
                <TableRow >
                  {this.getTableHeaders(questType, platform, keyDistribute).map(headCell => (
                    <TableHeaderCell
                      key={headCell.id}
                      align="center"
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection="asc">
                      <TableSortLabel
                        active={this.state.orderBy === headCell.id}
                        direction={this.state.order}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                        style={{ marginLeft: '30px' }}>
                        {headCell.label}
                        {this.state.orderBy === headCell.id ? (
                          <span style={{ display: 'none' }}>
                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableHeaderCell>))}
                </TableRow>
              </TableHead>
              <TableBody>{this.getUserRows(currentTabUsers)}</TableBody>
            </Table>
            ) : (<div className="NoQuestTiered">No data yet.</div>)}
        </React.Fragment>

        <Dialog open={isDialogOpen} PaperProps={{
          style: {
            backgroundColor: '#204E78',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            width: '400px',
            padding: '10px'
          }
        }}>
          <DialogTitle><p className="custom-dialog-title">Confirmation of payment</p></DialogTitle>
          <DialogContent>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px' }}>You will pay a total of </Typography>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px', color: '#bbeb3d' }}>${totalAmount}</Typography>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px' }}> to the selected user to access their Noiz account. This behavior is irreversible. Confirm the payment?</Typography>
            {/* <p style={{}}>$100</p> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}
              style={{ fontSize: '16px', fontWeight: '800', backgroundColor: '#f77929', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }}
            >Cancel</Button>
            <Button onClick={this.submitMoney}
              style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }}
            >Confirm</Button>
          </DialogActions>
        </Dialog>
        {this.state.showPaymentTimeModal && this.renderPaymentTimeModal()}
        {(this.state.showApprovalModal) && this.renderShowApprovalModal()}
        {this.state.showSuspendConfirm && this.renderSuspendConfirmModal()}
      </section>
    );
  }
}

QuestPaymentsTableNew.propTypes = {
};

export default QuestPaymentsTableNew;